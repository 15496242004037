import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
// import { Document } from 'react-pdf';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterComponen from '../../components/footer';
import { URL_AWS, URL_SERVICIO } from '../../constans/servidor';

import * as datajson from '../../constans/json';

import factura from '../../asses/img/FacturaPOS.png';

const AyudaFacturaElectronica = () => {
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState();

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Landing ayuda factura` });
    userData();
  }, []);

  const userData = async () => {
    setLoading(true);
    const rest = await axios('https://ipapi.co/json/');
    setDataUser(rest.data);
    setLoading(false);
  };
  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row
          className="justify-content-center mtop-40 mbottom-40"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo centrado">
              DATOS PARA DILIGENCIAR EL FORMULARIO DE SOLICITUD DE FACTURACIÓN
              ELECTRÓNICA
            </h3>
          </Col>
        </Row>
      </Container>
      <div className="banner-principal">
        {/* <Document
                    file={pdf}
                >
                    <Page pageNumber={2} />
                </Document> */}
        <img
          className="d-block w-100"
          src={factura}
          alt="Banner facturacion electronica"
        />
      </div>

      {/* <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <img className=" w-100" src={factura}></img>
        </Col>
        </Row> */}
      <FooterComponen />
    </>
  );
};
export default AyudaFacturaElectronica;
