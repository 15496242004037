import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import axios from 'axios';

import { URL_AWS } from '../../constans/servidor';

import iconcart from '../../asses/img/icon-cart.png';
import iconcart2x from '../../asses/img/icon-cart@2x.png';
import iconcart3x from '../../asses/img/icon-cart@3x.png';
import iconmoto from '../../asses/img/icon-moto.png';
import iconmoto2x from '../../asses/img/icon-moto@2x.png';
import iconmoto3x from '../../asses/img/icon-moto@3x.png';
import iconbici from '../../asses/img/icon-bici.png';
import iconbici2x from '../../asses/img/icon-bici@2x.png';
import iconbici3x from '../../asses/img/icon-bici@3x.png';
import pago_tarjeta from '../../asses/img/pago_tarjeta.png';
import pago_efectivo from '../../asses/img/Pago_efectivo.png';
import bitmap from '../../asses/img/bitmap.png';
import emobi from '../../asses/img/emobi.png';
import triangulo from '../../asses/img/triangulo.png';
import lluvia from '../../asses/img/lluvia.png';
import point from '../../asses/img/ponit.png';
import lupa from '../../asses/img/lupa.png';
import moment from 'moment';
import 'moment/locale/es'; // Opcional, para establecer el idioma en español

moment.locale('es');

const MapaComponent = (props) => {
  const [key, setKey] = useState('home');
  const [value, setValue] = useState(null);
  const [ubicacion, setUbicacion] = useState();
  const [horario, setHorario] = useState();
  const [detalleParqueadero, setDetalleParqueadero] = useState();
  const [estado,setEstado]=useState()

  const buscar = async (item) => {
    setValue(item);
    geocodeByAddress(item.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => setUbicacion({ lat, lng }));
  };

  const onMarkerClick = async (item) => {
    console.log(item.codigo);
    try {
      const result = await axios.get(
        `${URL_AWS}/serviciosPaginaWeb/rest/parqueadero/getDetalle/${item.codigo}`
      );
      console.log('parqueadero');
      console.log(result.data.data['horario']);
      setDetalleParqueadero(result.data.data);
      
      Horarios(result.data.data['horario'])
    } catch (error) {
      console.log(error);
    }
  };
  const Horarios = (horario) => {
    const horariosString = horario
    console.log(horariosString);

    // Expresión regular para encontrar los horarios
    const regex = /([a-z]{3}-[a-z]{3}|[a-z]{3}) (\d{2}[ap]m-\d{2}:\d{2}[ap]m|\d{2}[ap]m-\d{1}[ap]m|\d{2}[ap]m-\d{2}[ap]m|\d{1}[ap]m-\d{2}:\d{2}[ap]m)/gi;

    // Mapeo de los nombres de los días en español
    const nombresDias = {
      'lun-lun': 'lunes',
      'mar-mar': 'martes',
      'mie-mie': 'miércoles',
      'jue-jue': 'jueves',
      'vie-vie': 'viernes',
      'sab-sab': 'sábado',
      'sab': 'sábado',
      'dom': 'domingo'
    };

    // Array para almacenar los horarios de cada día
    const horarios = [];

    // Bucle para encontrar todos los horarios en el string
    let match;
    while ((match = regex.exec(horariosString)) !== null) {
      const dia = match[1];
      const horarioString = match[2];
      // Convertir la cadena de horario a objetos Date
      const horarioParts = horarioString.split("-");
      const horarioInicio = formatarHora(horarioParts[0]);
      const horarioFin = formatarHora(horarioParts[1]);

      // Formatear los horarios en el formato deseado
      const options = { hour: "numeric", minute: "2-digit" };
      const horarioFormateado = {
        inicio: horarioInicio,
        fin: horarioFin
      };

      horarios.push({ dia: nombresDias[dia], hora: horarioFormateado });
    }
    setEstado(determinarEstado(horarios))
    setHorario(horarios)
    // setHorario(horarios)
  }

  function formatarHora(horaString) {
    const horaParts = horaString.match(/(\d+)(:\d+)?([ap]m)?/i); // Utilizamos una expresión regular para extraer las partes de la hora
    if (horaParts) {
        let horas = parseInt(horaParts[1]); // Extraemos las horas y las convertimos en un número entero
        const minutos = parseInt(horaParts[2]?.slice(1) || '00'); 
        const amPm = horaParts[3]?.toLowerCase(); 
        if (amPm === 'pm' && horas !== 12) {
            horas += 12;
        }

        // Si la hora solo tiene una parte, agregamos "00" a los minutos
        const minutosFormateados = minutos < 10 ? '0' + minutos : minutos;
        if (horas > 12) {
            horas -= 12;
        }
        const horaFormateada = (horas < 10 ? '0' : '') + horas.toString() + ':' + minutosFormateados;
        return horaFormateada + (amPm ? amPm.toUpperCase() : ''); // Devolvemos la hora formateada con AM/PM si es necesario
    } else {
        return horaString; // Si no se puede analizar correctamente la hora, devolvemos la cadena original
    }
}
  
  function determinarEstado(horarios) {
    const diaActual = moment().format('dddd'); // Obtiene el nombre del día actual en formato texto
  const horaActual = moment().format('hh:mm A'); // Obtiene la hora actual en formato texto

  const horarioDiaActual = horarios.find((horario) => horario.dia === diaActual);
  console.log(horarioDiaActual);

  if (!horarioDiaActual) {
    return 'Día no válido';
  }

  const inicio = moment(horarioDiaActual.hora.inicio, 'hh:mm A');
  const fin = moment(horarioDiaActual.hora.fin, 'hh:mm A');
  const horaActualMoment = moment(horaActual, 'hh:mm A');

  if (horaActualMoment.isBetween(inicio, fin)) {
    return 'Abierto';
  } else {
    return 'Cerrado';
  }
  }

  return (
    <Row className="justify-content-center">
      <Col xs={12} sm={6} md={5} lg={3} xl={3} className="caja-filtro">
        <div className="contenerbuscador">
          <GooglePlacesAutocomplete
            selectProps={{
              value,
              placeholder: 'En donde buscas un parking',
              onChange: buscar,
            }}
            apiOptions={{ language: 'es', region: 'CO' }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['co'],
              },
            }}
          />
          <div className="lupa"></div>
        </div>

        {detalleParqueadero ? (
          <div className="parqueadero-detalle">
            <div className="parqueadero-header">
              <h4>
                {detalleParqueadero?.nombre}{' '}
                <small>{detalleParqueadero?.direccion}</small>
              </h4>
              <h4 className="verde">{estado}</h4>
            </div>
            {horario ? (
              horario.map((horarios) =>
                <ul className="parqueadero-lista">
                  <li>
                    <span>{horarios.dia}</span>
                    <span className="azul">{horarios.hora.inicio} - {horarios.hora.fin}</span>
                  </li>
                </ul>
              )
            ) : null}

            <table className="cupos">
              <tr className="bordeSeparador">
                <td className="td1">Cupos</td>
                <td className="td2 text-center">
                  <p>
                    {detalleParqueadero?.cuposDisponiblesCarro
                      ? detalleParqueadero.cuposDisponiblesCarro
                      : '--'}
                  </p>
                  <img
                    src={iconcart}
                    className="icon-tabla"
                    srcSet={`${iconcart2x} 2x, ${iconcart3x} 3x`}
                    alt="cupos carros"
                  />
                </td>
                <td className="td3 text-center">
                  <p>
                    {detalleParqueadero?.cuposDisponiblesMoto
                      ? detalleParqueadero.cuposDisponiblesMoto
                      : '--'}
                  </p>
                  <img
                    src={iconmoto}
                    className="icon-tabla"
                    srcSet={`${iconmoto2x} 2x, ${iconmoto3x} 3x`}
                    alt="cupos motos"
                  />
                </td>
                <td className="td4 text-center">
                  <p>
                    {detalleParqueadero?.cuposDisponiblesBici
                      ? detalleParqueadero.cuposDisponiblesBici
                      : '--'}
                  </p>
                  <img
                    src={iconbici}
                    className="icon-tabla"
                    srcSet={`${iconbici2x} 2x, ${iconbici3x} 3x`}
                    alt="cupos bici"
                  />
                </td>
              </tr>
              <tr className="bordeSeparador">
                <td className="td1" colSpan="4">
                  Tarifas
                </td>
              </tr>

              {detalleParqueadero?.tarifasOrdenadas.map((item, index) => {
                return (
                  <>
                    <tr
                      className={
                        index > 0
                          ? 'bordeSeparador vertical-center'
                          : 'vertical-center'
                      }
                    >
                      {item.tipoTarifa === 'TARIFA MINUTO' ? (
                        <td className="td1 text-tabla-pq">Minuto</td>
                      ) : item.tipoTarifa === 'TARIFA PLENA UNICA' ? (
                        <td className="td1 text-tabla-pq">
                          Plena
                          <br />({item.plena} horas)
                        </td>
                      ) : item.tipoTarifa === 'TARIFA HORA' ?  (
                        <td className="td1 text-tabla-pq">Hora</td>
                      ):   item.tipoTarifa === 'TARIFA PLENA REPETITIVA' ?  (
                        <td className="td1 text-tabla-pq">Plena  Repetitiva</td>
              ):   null}
                      <td
                        className={`td3 text-tabla-pq ${item.tipoTarifa === 'TARIFA MINUTO'
                          ? 'text-verde'
                          : 'text-azul'
                          }`}
                      >
                        {item?.valorTarifaCarro
                          ? `$${item.valorTarifaCarro}`
                          : '--'}
                      </td>
                      <td
                        className={`td3 text-tabla-pq ${item.tipoTarifa === 'TARIFA MINUTO'
                          ? 'text-verde'
                          : 'text-azul'
                          }`}
                      >
                        {item?.valorTarifaMoto
                          ? `$${item.valorTarifaMoto}`
                          : '--'}
                      </td>
                      <td
                        className={`td3 text-tabla-pq ${item.tipoTarifa === 'TARIFA MINUTO'
                          ? 'text-verde'
                          : 'text-azul'
                          }`}
                      >
                        {item?.valorTarifaBicicleta
                          ? `$${item.valorTarifaBicicleta}`
                          : '--'}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>

            <div className="mtop-20 mbottom-20 text-center d-block">
              {/* <button className="btn btn-primary btn-sm btn-redondo">Comprar mensualidad</button> */}
            </div>

            <table className="cupos">
              <tr>
                <td className="td1">Medios de pago</td>
                <td className="td2 text-center">
                  <img
                    src={pago_tarjeta}
                    className="icon-tabla icon-30"
                    alt="cupos carros"
                  />
                </td>
                <td className="td3 text-center">
                  <img
                    src={pago_efectivo}
                    className="icon-tabla icon-30"
                    alt="cupos motos"
                  />
                </td>
              </tr>
              {/* <tr>
                            <td className="td1">Alianzas</td>
                            <td className="td2 text-center"><img src={bitmap} className="icon-tabla icon-30" alt="cupos carros" /></td>
                        </tr>
                        <tr>
                            <td className="td1">Servicios adicionales</td>
                            <td className="td2 text-center"><img src={emobi} className="icon-tabla icon-10" alt="cupos carros" /></td>
                            <td className="td3 text-center"><img src={triangulo} className="icon-tabla icon-15" alt="cupos motos" /></td>
                            <td className="td3 text-center"><img src={lluvia} className="icon-tabla icon-20" alt="cupos motos" /></td>
                        </tr> */}
            </table>
          </div>
        ) : null}
      </Col>
      <Col xs={12} sm={6} md={7} lg={9} xl={9} className="pl-0 pr-0">
        <div className="mapa">
          <Map
            google={props.google}
            zoom={18}
            containerStyle={{ position: 'relative' }}
            initialCenter={{
              lat: 4.6733,
              lng: -74.0548083,
            }}
            center={ubicacion}
          >
            {props.data.map((item, index) => {
              return (
                <Marker
                  key={item.codigo}
                  onClick={() => onMarkerClick(item)}
                  name={item.nombre}
                  position={{ lat: item.latitud, lng: item.longitud }}
                  icon={{
                    url: point,
                    anchor: new props.google.maps.Point(32, 38),
                    scaledSize: new props.google.maps.Size(32, 38),
                  }}
                />
              );
            })}
          </Map>
        </div>
      </Col>
    </Row>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAJk-OEwHaUo8VMopXL2qWTDUvK1qEZ59M',
})(MapaComponent);
