import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { BsArrowLeft, BsPencil } from 'react-icons/bs';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic, db, stg, fun } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import CabezeraComponent from '../../components/header/cabezera';
import { URL_AWS } from '../../constans/servidor';

import iconAfiliacionVerde from '../../asses/img/icon-afiliacion-verde.png';
import iconAfiliacionVerde2x from '../../asses/img/icon-afiliacion-verde@2x.png';
import iconAfiliacionVerde3x from '../../asses/img/icon-afiliacion-verde@3x.png';
import iconAfiliacionNegro from '../../asses/img/icon-afiliacion-negro.png';
import iconAfiliacionNegro2x from '../../asses/img/icon-afiliacion-negro@2x.png';
import iconAfiliacionNegro3x from '../../asses/img/icon-afiliacion-negro@3x.png';
import iconAfiliacionGris from '../../asses/img/icon-afiliacion-gris.png';
import iconAfiliacionGris2x from '../../asses/img/icon-afiliacion-gris@2x.png';
import iconAfiliacionGris3x from '../../asses/img/icon-afiliacion-gris@3x.png';
import iconAfiliacion from '../../asses/img/icon-afiliacion.png';
import iconAfiliacion2x from '../../asses/img/icon-afiliacion@2x.png';
import iconAfiliacion3x from '../../asses/img/icon-afiliacion@3x.png';

import iconCumpleanoVerde from '../../asses/img/icon-cumpleanos-verde.png';
import iconCumpleanoVerde2x from '../../asses/img/icon-cumpleanos-verde@2x.png';
import iconCumpleanoVerde3x from '../../asses/img/icon-cumpleanos-verde@3x.png';
import iconCumpleanoNegro from '../../asses/img/icon-cumpleanos-negro.png';
import iconCumpleanoNegro2x from '../../asses/img/icon-cumpleanos-negro@2x.png';
import iconCumpleanoNegro3x from '../../asses/img/icon-cumpleanos-negro@3x.png';
import iconCumpleanoGris from '../../asses/img/icon-cumpleanos-gris.png';
import iconCumpleanoGris2x from '../../asses/img/icon-cumpleanos-gris@2x.png';
import iconCumpleanoGris3x from '../../asses/img/icon-cumpleanos-gris@3x.png';
import iconCumpleano from '../../asses/img/icon-cumpleanos.png';
import iconCumpleano2x from '../../asses/img/icon-cumpleanos@2x.png';
import iconCumpleano3x from '../../asses/img/icon-cumpleanos@3x.png';

import iconCheckVerde from '../../asses/img/icon-check-verde.png';
import iconCheckVerde2x from '../../asses/img/icon-check-verde@2x.png';
import iconCheckVerde3x from '../../asses/img/icon-check-verde@3x.png';
import iconCheckNegro from '../../asses/img/icon-check-negro.png';
import iconCheckNegro2x from '../../asses/img/icon-check-negro@2x.png';
import iconCheckNegro3x from '../../asses/img/icon-check-negro@3x.png';
import iconCheckGris from '../../asses/img/icon-check-gris.png';
import iconCheckGris2x from '../../asses/img/icon-check-gris@2x.png';
import iconCheckGris3x from '../../asses/img/icon-check-gris@3x.png';
import iconCheck from '../../asses/img/icon-check.png';
import iconCheck2x from '../../asses/img/icon-check@2x.png';
import iconCheck3x from '../../asses/img/icon-check@3x.png';

const BeBonosPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [identificacion, setIdentificacion] = useState(usuario.cedula);
  const [bonos, setBonos] = useState([]);
  const [bonos2, setBonos2] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const cargarbonos = async () => {
    setLoading(true);
    try {
      const respuesta = await axios.get(
        `${URL_AWS}/ServiciosApp/rest/bono/consultar/${identificacion}`
      );
      if(respuesta.data['data'].length === 0){
        toast.success("Pronto recibiras un Bono de Afilición...")
      }
      setLoading(false);
      setBonos(respuesta.data['data']);
      setBonos2(respuesta.data.message);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: 'Bonos Beparking' });
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (usuario) cargarbonos();
  }, [usuario]);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <CabezeraComponent />

        <div className="be-bono mtop-20">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className="texto-titulo mtop-20">Tus bonos.</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ul className="lista-etiquetas">
                <li>
                  <span className="activo">
                    <span class="activo-linea"></span>
                  </span>{' '}
                  Activo
                </li>
                <li>
                  <span className="vencido">
                    <span class="vencido-linea"></span>
                  </span>{' '}
                  Vencido
                </li>
                <li>
                  <span className="redimindo">
                    {' '}
                    <span class="redimido-linea"></span>
                  </span>{' '}
                  Redimido
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className="texto-titulo mtop-20">{bonos2}</h3>
            </Col>
          </Row>
          <Row className="mbottom-50">
            {bonos?.map((item) => {
              return (
                <Col xs={12} sm={6} md={4} lg={4} xl={4} className="mbottom-20">
                  {item.estado == 'S' ? (
                    <div className=" be-cajas-bonos activos">
                      <div className="be-cajas-top">
                        <div className="estado_activo">
                          <p>{item.nombre}</p>
                          <h4 className="tiempo">{item.tiempo}</h4>
                          <p className="codigo">Código <b>{item.codigo}</b></p>
                          <p className="vigencia">Vigencia</p>
                          <h6 className="fecha">{item.vigencia}</h6>
                        </div>
                        <div className="img-bono">
                          {(() => {
                            if (item.nombre.includes('cumpleaños')) {
                              return (
                                <img
                                  src={iconCumpleanoVerde}
                                  srcSet={iconCumpleanoVerde2x}
                                  alt="Icono cumpleano"
                                />
                              );
                            } else if (
                              item.nombre.includes('afiliacion') ||
                              item.nombre.includes('afiliación')
                            ) {
                              return (
                                <img
                                  src={iconAfiliacionVerde}
                                  srcSet={iconAfiliacionVerde2x}
                                  alt="Icono check"
                                />
                              );
                            } else {
                              return (
                                <img
                                  src={iconCheckVerde}
                                  srcSet={iconCheckVerde2x}
                                  alt="Icono afiliacion"
                                />
                              );
                            }
                          })()}
                        </div>
                      </div>
                      <div className="span"></div>
                    </div>
                  ) : null}
                  {item.estado == 'V' ? (
                    <div className=" be-cajas-bonos vencidos">
                      <div className="be-cajas-top">
                        <div className="estado_vencido">
                          <p>{item.nombre}</p>
                          <h4 className="tiempo">{item.tiempo}</h4>
                          <p className="codigo">Código {item.codigo}</p>
                          <p className="vigencia">Vigencia</p>
                          <h6 className="fecha">{item.vigencia}</h6>
                        </div>
                        <div className="img-bono">
                          {(() => {
                            if (item.nombre.includes('cumpleaños')) {
                              return (
                                <img
                                  src={iconCumpleano}
                                  srcSet={iconCumpleano2x}
                                  alt="Icono cumpleano"
                                />
                              );
                            } else if (
                              item.nombre.includes('afiliacion') ||
                              item.nombre.includes('afiliación')
                            ) {
                              return (
                                <img
                                  src={iconAfiliacion}
                                  srcSet={iconAfiliacion2x}
                                  alt="Icono check"
                                />
                              );
                            } else {
                              return (
                                <img
                                  src={iconCheck}
                                  srcSet={iconCheck2x}
                                  alt="Icono afiliacion"
                                />
                              );
                            }
                          })()}
                        </div>
                      </div>
                      <div className="span"></div>
                    </div>
                  ) : null}
                  {item.estado == 'R' ? (
                    <div className=" be-cajas-bonos redimidos">
                      <div className="be-cajas-top">
                        <div className="estado_redimido">
                          <p>{item.nombre}</p>
                          <h4 className="tiempo">{item.tiempo}</h4>
                          <p className="codigo">Código {item.codigo}</p>
                          <p className="vigencia">Vigencia</p>
                          <h6 className="fecha">{item.vigencia}</h6>
                        </div>
                        <div className="img-bono">
                          {(() => {
                            if (item.nombre.includes('cumpleaños')) {
                              return (
                                <img
                                  src={iconCumpleanoGris}
                                  srcSet={iconCumpleanoGris2x}
                                  alt="Icono cumpleano"
                                />
                              );
                            } else if (
                              item.nombre.includes('afiliacion') ||
                              item.nombre.includes('afiliación')
                            ) {
                              return (
                                <img
                                  src={iconAfiliacionGris}
                                  srcSet={iconAfiliacionGris2x}
                                  alt="Icono check"
                                />
                              );
                            } else {
                              return (
                                <img
                                  src={iconCheckGris}
                                  srcSet={iconCheckGris2x}
                                  alt="Icono afiliacion"
                                />
                              );
                            }
                          })()}
                        </div>
                      </div>
                      <div className="span"></div>
                    </div>
                  ) : null}
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>

      <FooterBeparking />
    </>
  );
};

export default BeBonosPage;
