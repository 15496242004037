import React, { useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { auth, db, analytic } from '../../server/firebase';
import authReducers from './authReducers';
import authContext from './authContex';
import tokenAuth from '../../config/tokenAuth';
import * as ROUTES from '../../constans/rutas';

import {
  REGISTRO_EXITOSO,
  REGISTRO_ERROR,
  OBTENER_USUARIO,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
  TYPE_TOKEN,
} from '../../types';
import axios from 'axios';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    autenticado: null,
    usuario: null,
    mensaje: null,
    cargando: true,
  };

  let history = useHistory();

  const [state, dispatch] = useReducer(authReducers, initialState);
  const [loginExitosoMessageShown, setLoginExitosoMessageShown] =
    useState(false);

  const registrarUsuario = async (datos) => {
    try {
      const respuesta = await auth.createUserWithEmailAndPassword(
        datos.email,
        datos.password
      );
      let user;
      if (respuesta.user) {
        user = await db.collection('Usuarios').doc(respuesta.user.uid).set({
          id: respuesta.user.uid,
          nombre: datos.nombre,
          email: datos.email,
        });
      }
      analytic.logEvent('registro_usuario', { page: 'Beparking' });
      dispatch({
        type: REGISTRO_EXITOSO,
        payload: respuesta.user.uid,
      });

      // obtener usuarios
      usuarioAutenticado();
    } catch (error) {
      const alerta = {
        msg: error.message,
        categoria: 'danger',
      };
      dispatch({
        type: REGISTRO_ERROR,
        payload: alerta,
      });
    }
  };

  const actualizarUsuario = async (cedula) => {
    const respuesta = await axios.get(
      `${URL_AWS}/beParking/consultaInfoUsuario/${cedula}`
    );
    dispatch({
      type: OBTENER_USUARIO,
      payload: { cms: false, ...respuesta.data.data },
    });
  };

  const usuarioAutenticado = async (usuario) => {
    let token = localStorage.getItem('token');
    let token_type = localStorage.getItem('access_token');
    let typeUser = localStorage.getItem('typeUser');

    console.log(token, token_type);

    if (token) {
      if (token && token_type === 'Bearer') {
        const [, payloadBase64] = token.split('.');
        const decodedPayload = atob(payloadBase64);
        const tokenData = JSON.parse(decodedPayload);
        console.log('xd', tokenData);

        const data = {
          nit: tokenData.payload.id,
        };

        const response = await axios.post(
          `${URL_AWS}/Suppliers/consultUser`,
          data,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );

        if (response.data.status === false) {
          cerrarSesion();
          history.push(ROUTES.CREATEUSER);
          return;
        }

        dispatch({
          type: OBTENER_USUARIO,
          payload: { cms: false, ...response.data.user },
        });

        console.log('res', response);
        if (typeUser === 'Normal') {
          history.push(ROUTES.PROFILEUSER);
          return;
        } else {
          history.push(ROUTES.UPLOADDOCS);
          return;
        }
      } else if (token === 'FIREBASE') {
        auth.onAuthStateChanged((userAuth) => {
          console.log("user",userAuth);
          if (userAuth) {
            db.collection('Usuarios')
              .doc(userAuth?.uid)
              .get()
              .then((doc) => {
                console.log(doc);
                if (doc.exists) {
                  const user = doc.data();
                  dispatch({
                    type: OBTENER_USUARIO,
                    payload: { cms: true, ...user },
                  });
                } else {
                  dispatch({
                    type: LOGIN_ERROR,
                  });
                }
              })
              .catch((error) => {
                dispatch({
                  type: LOGIN_ERROR,
                });
              });
            if (!loginExitosoMessageShown) {
              toast.success('Login exitoso');
              setLoginExitosoMessageShown(true);
            }
            // toast.success('Login exitoso');
          } else {
            dispatch({
              type: LOGIN_ERROR,
            });
          }
        });
      } else {
        const respuesta = await axios.get(
          `${URL_AWS}/beParking/consultaInfoUsuario/${token}`
        );

        dispatch({
          type: OBTENER_USUARIO,
          payload: { cms: false, ...respuesta.data.data },
        });

        history.push(ROUTES.BEPERFIL);
      }
    } else {
      dispatch({
        type: LOGIN_ERROR,
      });
    }

    // if(usuario){
    //     console.log('en usuario');
    //     localStorage.setItem('token', usuario.cedula)
    //     toast.success("Login exitoso");
    //     dispatch({
    //         type: OBTENER_USUARIO,
    //         payload: {cms: false, ...usuario}
    //     })
    // } else {
    //     console.log('en else');
    //     if(token){
    //         auth.onAuthStateChanged( (userAuth) => {
    //             if(userAuth){
    //                 db.collection('Usuarios').doc(userAuth?.uid).get()
    //                 .then( (doc) => {
    //                     if (doc.exists) {
    //                         const user = doc.data();
    //                         dispatch({
    //                             type: OBTENER_USUARIO,
    //                             payload: {cms: true, ...user}
    //                         })
    //                     } else {
    //                         dispatch({
    //                             type: LOGIN_ERROR
    //                         })
    //                     }
    //                 }).catch( (error) => {
    //                     dispatch({
    //                         type: LOGIN_ERROR
    //                     })
    //                 })
    //                 toast.success("Login exitoso");

    //             } else {
    //                 console.log('error')
    //                 dispatch({
    //                     type: LOGIN_ERROR
    //                 })
    //             }
    //         })
    //     }
    // }
  };

  const loginSuppliers = async (data) => {
    try {
      console.log(data);
      const response = await axios.post(`${URL_AWS}/Suppliers/loginUser`, data);
      if (response.data.status === true) {
        dispatch({
          type: LOGIN_EXITOSO,
          payload: response.data.access_token,
        });

        localStorage.setItem('access_token', response.data.token_type);
        localStorage.setItem('typeUser', data.typeUser);

        if (!loginExitosoMessageShown) {
          setLoginExitosoMessageShown(true);
        }
        // obtener usuarios
        usuarioAutenticado(response.data);
        toast.success('Login exitoso!!');
      } else {
        console.log('Response Login', response);
        return toast.error(response.data.message, 'danger');
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response ? error.response.data.mensaje : 'Algo salio mal'
      );
    }
  };

  const loginBeparking = async (datos) => {
    try {
      const respuesta = await axios.post(
        // `http://localhost:3000/dev/validateCodeLogin`,
        `${URL_AWS}/beParking/validateCodeLogin`,
        datos
      );
      console.log('response ', respuesta.data);
      if (!respuesta.data.status) {
        //toast.error(respuesta.data.message);
        toast.error(
          'Código incorrecto. Por favor, revisa el código e inténtalo de nuevo.'
        );
      } else {
        let data = {
          cedula: respuesta.data.data.cedula,
        };
        const verificaciónCode = await axios.post(
          // `http://localhost:3000/dev/validateCodeVerification`,
          `${URL_AWS}/beParking/validateCodeVerification`,
          data
        );
        if (verificaciónCode.data.status === false) {
          toast.error(verificaciónCode.data.message);
          return;
        }

        analytic.logEvent('inicio_sesion', { page: 'Beparking' });

        dispatch({
          type: LOGIN_EXITOSO,
          payload: respuesta.data.data.cedula,
        });

        if (!loginExitosoMessageShown) {
          toast.success('Login exitoso');
          setLoginExitosoMessageShown(true);
        }
        // obtener usuarios
        usuarioAutenticado(respuesta.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response ? error.response.data.mensaje : 'Algo salio mal'
      );
    }
  };

  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await auth.signInWithEmailAndPassword(
        datos.email,
        datos.password
      );
      dispatch({
        type: LOGIN_EXITOSO,
        payload: 'FIREBASE',
      });

      // obtener usuarios
      usuarioAutenticado();
    } catch (error) {
      const alerta = {
        msg: error.message,
        categoria: 'danger',
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
    }
  };

  const cerrarSesion = () => {
    auth
      .signOut()
      .then(() => {
        if (localStorage.getItem('access_token')) {
          localStorage.removeItem('access_token');
        }
        if (localStorage.getItem('typeUser')) {
          localStorage.removeItem('typeUser');
        }
        localStorage.setItem('access_token', '');
        localStorage.setItem('typeUser', '');
        dispatch({
          type: CERRAR_SESION,
        });
      })
      .catch((error) => {
        const alerta = {
          msg: error.message,
          categoria: 'danger',
        };
        dispatch({
          type: LOGIN_ERROR,
          payload: alerta,
        });
      });
  };

  return (
    <authContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cargando: state.cargando,
        usuarioAutenticado,
        registrarUsuario,
        iniciarSesion,
        loginSuppliers,
        cerrarSesion,
        loginBeparking,
        actualizarUsuario,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
