import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios';


import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import FooterComponen from '../../components/footer';

import { db, stg, analytic } from '../../server/firebase';

import nosotros from '../../asses/img/nosotros1.png';
import nosotros2 from '../../asses/img/nosotros2.png';
import nosotros3 from '../../asses/img/nosotros3.png';
import nosotros4 from '../../asses/img/nosotros4.png';
import nosotros5 from '../../asses/img/nosotros5-1.png';
import icono1 from '../../asses/img/nosotrosicono.png';
import icono2 from '../../asses/img/nosotrosicono2.png';
import icono3 from '../../asses/img/nosotrosicono3.png';
import apple from '../../asses/img/apple.png';
import android from '../../asses/img/android.png';

const NosotrosPage = () => {
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;

    useEffect(() => {
        analytic.logEvent('page_view', {page: `Nosotros Parking`});
    }, []);

    return ( 
        <>
            <Container className="nosotros" fluid>
                <Tab.Container id="left-tabs-example" defaultActiveKey="historia">
                    <Row className="justify-content-center">
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Tab.Content >
                                <Tab.Pane eventKey="historia">
                                    <img src={nosotros} className="anchocompleto"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="proposito">
                                    <img src={nosotros2} className="anchocompleto"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="politica">
                                    <img src={nosotros3} className="anchocompleto"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sgc">
                                    <img src={nosotros4} className="anchocompleto"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sostenibilidad">
                                    <img src={nosotros5} className="anchocompleto"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="responsabilidad">
                                    <img src={nosotros3} className="anchocompleto"/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                            <Nav variant="pills" className="tabs-navigation">
                                <Nav.Item>
                                    <Nav.Link eventKey="historia">Historia</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="proposito">Propósito</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="politica">Políticas de calidad</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sgc">SGC</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sostenibilidad">Sostenibilidad</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="responsabilidad">Responsabilidad social</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="historia">
                                    <div className="nosotros-info">
                                        <h2>Somos la compañía colombiana líder en la industria de estacionamientos desde 1978</h2>
                                        <p>Por más de 40 años hemos consolidado nuestro crecimiento en segmentos de la economía dentro de los cuales se encuentran Hospitales, Universidades, Clínicas, Grandes Superficies, Centros Comerciales y Centros Empresariales, ubicados en Bogotá, Medellín, Cartagena, Cúcuta, Santa Marta, Barranquilla, Villavicencio, Pereira y Cali.</p>
                                        <img src={icono1} alt="icono" />
                                        <p>Actualmente contribuimos a la movilidad de estas ciudades con una oferta que supera los 15.000 cupos de parqueo, dotados de moderna tecnología, para brindar un servicio seguro y de calidad en la red de parqueaderos más amplia del país, donde tenemos la oportunidad de ofrecer un portafolio de productos y servicios diseñados para suplir las necesidades de nuestros clientes.</p>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="proposito">
                                    <div className="nosotros-info">
                                        <h2>Parking cerca de tí, cuidando lo que valoras. <small>Tenemos una cobertura de calidad con una experiencia memorablemente positiva.</small></h2>
                                        
                                        <ul>
                                            <li><b>Respeto:</b> En Parking todos son bienvenidos</li>
                                            <li><b>Lealtad:</b> Estamos comprometidos con nuestros clientes</li>
                                            <li><b>Integridad:</b> Hacemos lo que prometemos y hacemos lo correcto</li>
                                            <li><b>Disciplina:</b> La constancia nos ha permitido entregar más de 40 años de servicio</li>
                                            <li><b>Compromiso:</b> Damos lo mejor de nosotros, ya contamos con más de 180 parqueaderos</li>
                                            <li><b>Confianza:</b> La tranquilidad de contar con un equipo de expertos</li>
                                        </ul>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="politica">
                                    <div className="nosotros-info">
                                        <h2>En Parking, contribuimos brindando espacios que faciliten<br/>la movilidad a traves de una red con alcance nacional<small>Estamos comprometidos en cuidar lo que valoras.</small></h2>
                                        <img src={icono2} alt="icono" />
                                        <p>Ofrecemos una experiencia en el servicio aplicando avances tecnológicos e innovadores al alcance de todos nuestros clientes, cumpliendo con todos los requisitos legales aplicables, orientados a la mejora continua del SGC y las expectativas de los grupos de interés.</p>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sgc">
                                    <div className="nosotros-info">
                                        <h2>Una empresa enfocada en la prestación de un servicio de estacionamiento diferencial. </h2>
                                        <img src={icono3} alt="icono" />
                                        <p className="text-center">Un Sistema de Gestión de Calidad que nos motiva constantemente a trabajar por la satisfacción de nuestros clientes.</p>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sostenibilidad">
                                    <div className="nosotros-info">
                                        <h2>Sabemos que nuestro éxito depende de gestionar nuestros<br/>parqueaderos de manera sostenible.</h2>
                                        <p>Hemos diseñado una aplicación para dispositivos móviles que facilita a nuestros usuarios la búsqueda de espacio disponible, contribuyendo así en la disminución de congestión, contaminación y accidentalidad.<br/>Además, hemos adecuado nuestras instalaciones para estacionar bicis y motos, brindando un espacio seguro para aquellos usuarios que prefieren transportarse en medios de transporte amigables con el medio ambiente.</p>
                                        <div className="descarga">
                                            <h2>Descarga la app<small>Descubre los beneficios que tenemos para tí</small></h2>
                                            <p className="text-center">Disponible en</p>
                                            <div className="d-flex flex-row justify-content-center align-items-center enlaces">
                                                <a target="_blank" href="https://apps.apple.com/co/app/parking-app/id694896866?ign-mpt=uo%3D4"><img src={apple} alt="icono" /></a>
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.parking.net"><img src={android} alt="icono" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="responsabilidad">
                                    <div className="nosotros-info">
                                        <h2>Estamos vinculados a dos iniciativas con las que buscamos contribuir<br/>de manera activa y voluntaria al mejoramiento social y económico del país</h2>
                                        
                                        <ul>
                                            <li><b>Programa 40 mil primeros empleos:</b> Vinculamos constantemente a nuestro equipo de trabajo personas adscritas a este programa, el cual está dirigido a personas entre 18 y 28 años de edad, sin experiencia laboral, recién egresados de algún programa de educación superior y bachilleres.</li>
                                            <li><b>Best Buddies: </b>En Parking nos preocupamos por la gente. Por esa razón también apoyamos la organización BEST BUDDIES. <a href="https://www.bestbuddies.com.co" target="_blanc">www.bestbuddies.com.co</a> </li>
                                        </ul>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>


            <FooterComponen />
        </>
     );
}
 
export default NosotrosPage;

