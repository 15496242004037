import React, { useState, useContext, useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useForm, Controller, Feedback, set } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import backAxios from '../../config/axiosServices';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import EditServicios from '../../components/modal/cmsEmpresas';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import { getCategorys } from '../../utils/getCategories';

const EmpresasPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [editServivios, setEditServicios] = useState(false);
  const [modalNoticia, setModalNoticia] = useState(false);
  const [editAction, setEditAction] = useState('');
  const [dataBaner, setDataBaner] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [alianzas, setAlianzas] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [parqueaderos, setParqueaderos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [updateBanner, setUpdateBanner] = useState(false);
  const [updateServicio, setUpdateservicios] = useState(false);
  const [updateAlianzas, setUpdateAlianzas] = useState(false);
  const [updateNoticias, setUpdateNoticias] = useState(false);
  const [noticia, setNoticia] = useState();
  const [nombre, setNombre] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [mensaje, setMensaje] = useState();
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [key, setKey] = useState('home');
  const [datos, setDatos] = useState([]);
  const [indexD, setIndexD] = useState();
  const [indexI, setIndexI] = useState();
  const [categoriasC, setCategorias] = useState();
  const [categoriasS, setCategoriasS] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    const fetchCategorys = async () => {
      try {
        const categorys = await getCategorys();
        if (categorys.length > 0) {
          setCategorias(categorys);
        } else {
          toast.error('No pudimos cargar las categorias...');
          setDatos([]);
          setCategorias([]);
        }
      } catch (error) {
        console.error('Algo salio mal cargando categorias:', error);
        toast.error('Algo salio mal cargando categorias...');
      }
    };

    fetchCategorys();
  }, []);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
  };

  const settingsmodal = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    consultarBanner();
  }, [updateBanner]);

  useEffect(() => {
    consultarServicios();
  }, [updateServicio]);

  useEffect(() => {
    consultarAlianzas();
  }, [updateAlianzas]);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Home Empresas Parking` });
  }, []);

  const consultarBanner = async () => {
    setLoading(true);
    try {
      const resultado = await db.collection('BannerEmp').orderBy('orden').get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setDataBaner(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando el banner...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarServicios = async () => {
    setLoading(true);
    try {
      const resultado = await db
        .collection('ServiciosEmp')
        .orderBy('orden')
        .get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setServicios(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando los servicios...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarAlianzas = async () => {
    setLoading(true);
    try {
      const resultado = await db
        .collection('AlianzasEmp')
        .orderBy('orden')
        .get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setAlianzas(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando las alianzas...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const abrirNoticia = (item) => {
    setModalNoticia(true);
    setNoticia(item);
  };

  const enviarEmail = async (event) => {
    event.preventDefault();
    console.log(categoriasS);
    if (
      nombre &&
      telefono &&
      email &&
      mensaje &&
      acepto &&
      categoriasS != undefined
    ) {
      if(telefono.length === 10) {
        const body = {
          message: mensaje,
          name: nombre,
          phone: telefono,
          sendBy: email,
          category: categoriasS,
        };
        console.log(body);
        try {
          const resultado = await axios.post(
            `https://2uj5iipka9.execute-api.us-east-1.amazonaws.com/dev/ContactCategory/sendMail`,
            body
          );
          console.log(resultado);
          setNombre();
          setTelefono();
          setEmail();
          setMensaje();
          setAcepto(false);
          setCategoriasS();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          toast.success(
            'Contacto exitoso, pronto nos contactaremos con usted...'
          );
        } catch (error) {
          toast.error('Algo salio mal, intentalo mas tarde...');
        }
      }else {
        return toast.error('El numero de telefono no es valido!');
      }
    } else {
      toast.error('Algo salio mal, intentalo mas tarde...');

      setError(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <div className="banner-principal">
        <Carousel>
          {dataBaner.length > 0 &&
            dataBaner.map((item, index) => {
              return (
                <Carousel.Item key={item._id}>
                  <a href={item.nombre} target="_blank">
                    {windowWidth <= 575 ? (
                      <img
                        className="d-block w-100"
                        src={item.imagenM}
                        alt={item.nombre_originalM}
                      />
                    ) : (
                      <img
                        className="d-block w-100"
                        src={item.imagen}
                        alt={item.nombre_original}
                      />
                    )}
                  </a>
                </Carousel.Item>
              );
            })}
        </Carousel>

        {usuario?.cms ? (
          <Button
            onClick={() => {
              setEditAction('banner');
              setEditServicios(true);
            }}
            variant="outline-success"
            className="mtop-20 btn_banner"
          >
            <AiFillEdit /> Editar
          </Button>
        ) : null}
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <h3 className="texto-slogan">
              Tus empleados y clientes son importantes para la estabilidad y el
              crecimiento de tu negocio.
            </h3>
            <p className="text-center mbottom-30">
              Conoce los planes y beneficios que tenemos para empresas
            </p>
          </Col>
        </Row>
      </Container>

      <BannerComponent />

      <Container>
        <Row className="justify-content-center mtop-50">
          <Col xs={12} sm={10} md={9} lg={10} xl={10}>
            <h3 className="texto-titulo-e text-center">
              Lo que tenemos para tus clientes y empleados
            </h3>
          </Col>
          <Col
            xs={12}
            sm={2}
            md={3}
            lg={2}
            xl={2}
            className="d-flex flex-row-reverse"
          >
            {usuario && usuario.cms ? (
              <Button
                onClick={() => {
                  setEditAction('servicios');
                  setEditServicios(true);
                }}
                variant="outline-success"
                className="mtop-20"
              >
                <AiFillEdit /> Editar
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          {servicios.map((item, index) => {
            return (
              <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                key={item._id}
                className="mbottom-20"
              >
                <div className="cajas-blancas">
                  <img src={item.imagen} alt={item.nombre} />
                  <h4>{item.nombre}</h4>
                  <p>
                    {item.descripcion.length > 210
                      ? item.descripcion.substring(0, 210 - 3) + '...'
                      : item.descripcion}
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center mtop-50">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo-e text-center">
              Te ayudamos en lo que necesites
            </h3>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex flex-row-reverse"
          >
            {usuario && usuario.cms ? (
              <Button
                onClick={() => {
                  setEditAction('alianzas');
                  setEditServicios(true);
                }}
                variant="outline-success"
                className="mtop-20"
              >
                <AiFillEdit /> Editar
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          {alianzas.map((item, index) => {
            return (
              <Col key={item._id} xs={12} sm={4} md={4} lg={4} xl={4}>
                <div className="caja-alianza empresa">
                  <div className="alianza-imagen d-flex justify-content-center align-items-center">
                    <img src={item.imagen} alt={item.nombre} />
                  </div>
                  <h4>{item.nombre}</h4>
                  <p>
                    {item.descripcion.length > 120
                      ? item.descripcion.substring(0, 120 - 3) + '...'
                      : item.descripcion}
                  </p>
                  <a onClick={() => abrirNoticia(item)}>Quiero saber más</a>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <div className="fondo-blanco mtop-40">
        <Container>
          <Row className="justify-content-center mtop-40" id="contacto">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className="texto-titulo">¿Necesitas algo?</h3>
            </Col>
          </Row>
          <Row className="pbottom-40">
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
              <p className="text-left">
                Mantengamos el contacto.
                <br />
                Escríbenos y te responderemos tan pronto como sea posible.
              </p>

              <img
                src={contacto}
                className="img-fluid mtop-30"
                srcSet={`${contacto2x} 2x, ${contacto3x} 3x`}
                alt="contactate con parkin"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Form onSubmit={enviarEmail}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Escribe tu nombre"
                    onChange={(text) => setNombre(text.target.value)}
                    rules={{
                      required: {
                        value: true,
                        message: 'El campo es obligatorio',
                      },
                    }}
                  />
                  {error && !nombre ? (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El nombre es obligatorio
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Escribe tu teléfono"
                    onChange={(text) => setTelefono(text.target.value)}
                    rules={{
                      required: {
                        value: true,
                        message: 'El campo es obligatorio',
                      },
                    }}
                  />
                  {error && !telefono ? (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El teléfono es obligatorio
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Escribe tu correo electrónico"
                    onChange={(text) => setEmail(text.target.value)}
                    rules={{
                      required: {
                        value: true,
                        message: 'El campo es obligatorio',
                      },
                    }}
                  />
                  {error && !email ? (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El email es obligatorio
                    </Form.Text>
                  ) : null}
                </Form.Group>
                {categoriasC ? (
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="texto-label">Categoria</Form.Label>
                    <Controller
                      name="categoria"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                      // rules={{ required: true }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          onChange={(categoriasC) => {
                            setCategoriasS(categoriasC.value);
                            onChange(categoriasC);
                          }}
                          value={value}
                          // placeholder="Elige una opción"
                          options={categoriasC}
                        />
                      )}
                    />
                    {error && !categoriasS ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El campo es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                ) : null}
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Tu mensaje</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Escribe tu mensaje"
                    onChange={(text) => setMensaje(text.target.value)}
                    rules={{
                      required: {
                        value: true,
                        message: 'El campo es obligatorio',
                      },
                    }}
                  />
                  {error && !mensaje ? (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El mensaje es obligatorio
                    </Form.Text>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Acepta nuestras"
                    onChange={(evt) => setAcepto(evt.target.checked)}
                  />
                  <Link className="enlace-check" to="/terminosycondiciones">
                    politicas de tratamiento de datos
                  </Link>
                  {error && !acepto ? (
                    <Form.Text id="passwordHelpBlock" className="error">
                      Es necesario aceptar nuestras politicas de tratamientos de
                      datos.
                    </Form.Text>
                  ) : null}
                </Form.Group>
                <Button variant="primary" size="sm" type="submit">
                  Enviar mensaje
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={modalNoticia} onHide={() => setModalNoticia(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alianza</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {noticia ? (
            <Row className="align-items-center">
              {/* <Col xs={12} sm={6} md={4} lg={4} xl={4} >
                                <img className="w100" src={noticia.imagen} alt={noticia.nombre} />
                            </Col> */}
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className="texto-subtitulo">{noticia.nombre}</h1>
                <p>{noticia.descripcion}</p>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
      </Modal>

      <FooterComponen />

      {/* componentes CMS */}

      {usuario?.cms ? (
        <>
          <EditServicios
            show={editServivios}
            title={
              editAction === 'servicios'
                ? 'Editar servicios'
                : editAction === 'alianzas'
                ? 'Editar alianzas'
                : editAction === 'noticias'
                ? 'Editar noticias'
                : editAction === 'banner'
                ? 'Editar banner'
                : null
            }
            text={
              editAction === 'servicios'
                ? 'Agrega, edita o elimina servicios.'
                : editAction === 'alianzas'
                ? 'Agrega, edita o elimina alianzas.'
                : editAction === 'noticias'
                ? 'Agrega, edita o elimina noticias.'
                : editAction === 'banner'
                ? 'Agrega, edita o elimina banner'
                : null
            }
            textPrimary="Finalizar"
            textSecundary="Cancelar"
            onSecondary={() => setEditServicios(false)}
            onPrimary={() =>
              editAction === 'servicios'
                ? setUpdateservicios(!updateServicio)
                : editAction === 'alianzas'
                ? setUpdateAlianzas(!updateAlianzas)
                : editAction === 'noticias'
                ? setUpdateNoticias(!updateNoticias)
                : editAction === 'banner'
                ? setUpdateBanner(!updateBanner)
                : null
            }
            action={editAction}
            data={
              editAction === 'servicios'
                ? servicios
                : editAction === 'alianzas'
                ? alianzas
                : editAction === 'noticias'
                ? noticias
                : editAction === 'banner'
                ? dataBaner
                : []
            }
            firebase={{ db, stg }}
          />
        </>
      ) : null}
    </>
  );
};

export default EmpresasPage;
