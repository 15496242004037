import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { BsArrowLeft, BsPencil } from 'react-icons/bs';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import moment from 'moment';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import CabezeraComponent from '../../components/header/cabezera';
import PaginationComponent from '../../components/pagination';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import iconReloj from '../../asses/img/icon-reloj.png';
import iconReloj2x from '../../asses/img/icon-reloj@2x.png';
import iconReloj3x from '../../asses/img/icon-reloj@3x.png';
import iconRelojAzul from '../../asses/img/icon-reloj-azul.png';
import iconRelojAzul2x from '../../asses/img/icon-reloj-azul@2x.png';
import iconRelojAzul3x from '../../asses/img/icon-reloj-azul@3x.png';

const BeHistorialPage = () => {
  const authContex = useContext(AuthContex);
  const history = useHistory();
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [historial, setHistorial] = useState([]);
  const [historialFiltro, setHistorialFiltro] = useState([]);
  const [dataPagination, setDataPagination] = useState([]);
  const [buscadorVal, setBuscadorVal] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const cargarHistorial = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${URL_AWS}/beParking/transacciones/${usuario?.cedula}`
      );
      setHistorial(result.data.data);
      setHistorialFiltro(result.data.data);
      console.log(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const calcularDiasAusencia = (fechaIni, fechaFin) => {
    const startTime = moment(fechaIni, 'YYYY-MM-DD HH:mm:ss');
    const endTime = moment(fechaFin, 'YYYY-MM-DD HH:mm:ss');
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    return `TIEMPO ${hours} Horas y ${minutes} Minutos`;
  };

  const convertirMoneda = (valor) => {
    const formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
    });

    return formatter.format(valor);
  };

  const filtrarStart = (date) => {
    let array = historial;
    console.log(moment(date).format('YYYY-MM-DD'));
    const arrayfilter = array.filter(
      (item) =>
        moment(item.fechaInicial).format('YYYY-MM-DD') >=
        moment(date).format('YYYY-MM-DD')
    );
    console.log(arrayfilter);
  };

  const buscar = () => {
    let array = historial;
    let filterArray = [];
    let filterBuscar = [];
    if (startDate & endDate) {
      console.log('startDate y endDate');
      filterArray = array.filter(
        (item) =>
          moment(item.fechaInicial).format('YYYY-MM-DD') >=
            moment(startDate).format('YYYY-MM-DD') &&
          moment(item.fechaInicial).format('YYYY-MM-DD') <=
            moment(endDate).format('YYYY-MM-DD')
      );
    } else if (startDate) {
      console.log('startDate');
      filterArray = array.filter(
        (item) =>
          moment(item.fechaInicial).format('YYYY-MM-DD') >=
          moment(startDate).format('YYYY-MM-DD')
      );
    } else if (endDate) {
      console.log('endDate');
      filterArray = array.filter(
        (item) =>
          moment(item.fechaInicial).format('YYYY-MM-DD') <=
          moment(endDate).format('YYYY-MM-DD')
      );
    }
    if (buscadorVal) {
      if (filterArray.length) {
        filterArray.map((item) => {
          if (
            item.nombrePark &&
            item.nombrePark.toLowerCase().search(buscadorVal.toLowerCase()) > -1
          ) {
            filterBuscar.push(item);
          }
        });
        setHistorialFiltro(filterBuscar);
      } else {
        array.map((item) => {
          if (
            item.nombrePark &&
            item.nombrePark.toLowerCase().search(buscadorVal.toLowerCase()) > -1
          ) {
            filterBuscar.push(item);
          }
        });
        setHistorialFiltro(filterBuscar);
      }
    } else {
      setHistorialFiltro(filterArray);
    }
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (usuario) cargarHistorial();
  }, [usuario]);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Historial Beparking` });
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <CabezeraComponent />

        <div
          className={
            usuario?.extracto?.categoria === 'GREEN'
              ? 'be-info mtop-20'
              : 'be-info mtop-20 azul'
          }
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                className={
                  usuario?.extracto?.categoria === 'GREEN'
                    ? 'btn-primary'
                    : 'btn-primary azul'
                }
              >
                <BsArrowLeft /> Regresa a tus datos
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="header-historial">
                <h3 className="texto-titulo mtop-20">
                  Tu historial de parqueo.
                </h3>
                <div className="icon-content">
                  {usuario?.extracto?.categoria === 'GREEN' ? (
                    <img
                      src={iconReloj}
                      className="icon-icon"
                      srcSet={`${iconReloj2x} 2x, ${iconReloj3x} 3x`}
                      alt="Informacion del usuario"
                    />
                  ) : (
                    <img
                      src={iconRelojAzul}
                      className="icon-icon"
                      srcSet={`${iconRelojAzul2x} 2x, ${iconRelojAzul3x} 3x`}
                      alt="Informacion del usuario"
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label>Buscador</Form.Label>
              <div className="buscador">
                <Row>
                  <Col>
                    {/* <DatePicker 
                                            className="form-control"
                                            selected={fechaInicio}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(date) => setFechaInicio(date)} 
                                        /> */}
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Filtrar desde"
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="Filtrar hasta"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Nombre del parqueadero"
                      value={buscadorVal}
                      onChange={(e) => setBuscadorVal(e.target.value)}
                    />
                  </Col>
                  <Button variant="link" onClick={buscar}>
                    Buscar
                  </Button>
                </Row>
              </div>

              <ul className="list-group-be">
                {dataPagination?.map((item, index) => {
                  item['fecha'] = moment(item.fechaInicial).format(
                    'DD/MM/YYYY'
                  );

                  return (
                    <li key={index}>
                      <div className="list-first">
                        <p className="text-verde">{item.fecha}</p>
                        <p>{item.nombrePark}</p>
                        <p>{item.direccion}</p>
                        <p className="m-top10">
                          {item.tipo_vehiculo} {item.placa}
                        </p>
                      </div>
                      <div className="list-second">
                        <p>
                          {calcularDiasAusencia(
                            item.fechaInicial,
                            item.fechaFinal
                          )}
                        </p>
                        <p className="m-top10">PAGADO</p>
                        <p>
                          <b>{convertirMoneda(item.total)}</b>
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div className="float-right">
                <PaginationComponent
                  data={historialFiltro}
                  setDataPagination={setDataPagination}
                  title="Posts"
                  pageLimit={5}
                  dataLimit={5}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <FooterBeparking />
    </>
  );
};

export default BeHistorialPage;
