import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { BsArrowLeft, BsPencil } from 'react-icons/bs';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as ROUTES from '../../constans/rutas';

import { db, stg, fun, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import CabezeraComponent from '../../components/header/cabezera';
import PaginationComponent from '../../components/pagination';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

const TubeparkingPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('first');
  const [historial, setHistorial] = useState([]);
  const [dataPagination, setDataPagination] = useState([]);
  const [beneficios, setBeneficios] = useState([]);
  const [updateBeneficios, setUpdateBeneficios] = useState(false);

  const [windowWidth, setWindowWidth] = useState(0);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const cargarHistorial = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${URL_AWS}/beParking/transacciones/${usuario?.cedula}`
      );
      if(result.data.data.length === 0) {
        setLoading(false);
        toast.error('No tiene historial de transacciones...');
        return;
      }
      setHistorial(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const calcularDiasAusencia = (fechaIni, fechaFin) => {
    const startTime = moment(fechaIni, 'YYYY-MM-DD HH:mm:ss');
    const endTime = moment(fechaFin, 'YYYY-MM-DD HH:mm:ss');
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    return `TIEMPO ${hours} Horas y ${minutes} Minutos`;
  };

  const consultarBeneficios = async () => {
    setLoading(true);
    try {
      const resultado = await db
        .collection('Beneficios')
        .orderBy('orden')
        .get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setBeneficios(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando las noticias...');
      console.log(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (usuario) cargarHistorial();
  }, [usuario]);

  useEffect(() => {
    consultarBeneficios();
  }, [updateBeneficios]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Tu Beparking` });
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <CabezeraComponent />

        <div
          className={
            usuario?.extracto?.categoria === 'GREEN'
              ? 'be-tubeparking mtop-20'
              : 'be-tubeparking mtop-20 azul'
          }
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className="texto-titulo mtop-20">
                {key === 'first'
                  ? 'Tu Be Parking.'
                  : key === 'second'
                  ? 'Tus transacciones'
                  : 'Tus beneficios.'}
              </h3>
            </Col>
          </Row>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Nav variant="pills" className="tabs-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      Tus puntos ({usuario?.extracto?.puntosAcumulados})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Tus transacciones</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="trhee">Tus beneficios</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="midata">
                      <div className="midata-item">
                        <p>Tu documento</p>
                        <h4>{usuario?.cedula}</h4>
                      </div>
                      <div className="midata-item">
                        <p>Puntos restantes para llegar a la hora</p>
                        <h4>{usuario?.extracto?.puntosFaltantesParaBono}</h4>
                      </div>
                      <div className="midata-item">
                        <p>Tu categoría</p>
                        <h4>{usuario?.extracto?.categoria}</h4>
                      </div>
                    </div>
                    <p className="mtop-50 text-center">
                      Redime una hora de parqueo cada 800 puntos
                    </p>

                    <div
                      className={
                        usuario?.extracto?.categoria === 'GREEN'
                          ? 'barra-nivel'
                          : 'barra-nivel mbottom-50'
                      }
                    >
                      <span className="nivel-1">300</span>
                      <span className="nivel-2">600</span>
                      <span className="nivel-3">900</span>
                      <span className="nivel-4">1.100</span>
                      <div
                        className={
                          usuario?.extracto?.puntosAcumulados === 0
                            ? 'barra-total cero'
                            : 'barra-total'
                        }
                        style={{
                          width: `${
                            (usuario?.extracto?.puntosAcumulados * 100) / 800
                          }%`,
                        }}
                      >
                        <div className="bandera">
                          {usuario?.extracto?.puntosAcumulados}
                        </div>
                        <p className="level">{usuario?.extracto?.categoria}</p>
                      </div>
                    </div>
                    {usuario?.extracto?.categoria === 'GREEN' && (
                      <>
                        <p className="texto-nivel">Blue</p>
                        <p className="texto-reglas">
                          Acumula <b>1.100 puntos</b> y asciende a{' '}
                          <span>Blue</span>
                        </p>
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Table bordered className="tabla">
                      <thead>
                        <tr>
                          <th>Punto de Servicio</th>
                          <th>Terminal-Factura</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>Tiempo</th>
                          <th>Placa</th>
                          <th>Puntos acumulados</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataPagination?.map((item, index) => {
                          item['fechaini'] = moment(item.fechaInicial).format(
                            'DD/MM/YYYY'
                          );
                          item['fechafin'] = moment(item.fechaFinal).format(
                            'DD/MM/YYYY'
                          );
                          return (
                            <tr key={index}>
                              <td>{item.nombrePark}</td>
                              <td>
                                {item.codTerminal} - {item.numFactura}
                              </td>
                              <td>{item.fechaini}</td>
                              <td>{item.fechafin}</td>
                              <td>
                                {calcularDiasAusencia(
                                  item.fechaInicial,
                                  item.fechaFinal
                                )}
                              </td>
                              <td>{item.placa}</td>
                              <td>{item.puntos}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="float-right">
                      <PaginationComponent
                        data={historial}
                        setDataPagination={setDataPagination}
                        title="Posts"
                        pageLimit={5}
                        dataLimit={10}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="trhee">
                    <Row>
                      {beneficios?.map((item) => {
                        if (
                          item.tipo.toUpperCase() ===
                          usuario?.extracto?.categoria.toUpperCase()
                        ) {
                          return (
                            <Col
                              key={item._id}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                              xl={4}
                              className="mbottom-20"
                            >
                              <div
                                className={
                                  usuario?.extracto?.categoria === 'GREEN'
                                    ? 'be-cajas-blancas'
                                    : 'be-cajas-blancas azul'
                                }
                              >
                                <img src={item.imagen} alt={item.nombre} />
                                <Link
                                  className="btn-mas"
                                  to={{
                                    pathname: `${ROUTES.BEDETALLES}/${item._id}`,
                                    item,
                                  }}
                                >
                                  Conoce más aquí
                                </Link>
                              </div>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>

      <FooterBeparking />
    </>
  );
};

export default TubeparkingPage;
