import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Tab } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import iconUser from '../../asses/img/beparking_inicia sesión.jpg';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContex from '../../context/autenticacion/authContex';
import { URL_AWS } from '../../constans/servidor';
import { toast } from 'react-toastify';
import axios from 'axios';
import LoginComponent from '../../components/login';
import OtpInput from 'react-otp-input';

const LoginBeParking = () => {
  const [tab, setTab] = useState(1);
  const alertaContext = useContext(AlertaContext);
  const { alerta } = alertaContext;
  const authContext = useContext(AuthContex);
  const { loginBeparking } = authContext;
  const [countdown, setCountdown] = useState(30); // 30 seg
  const [showStartBtn, setShowStartBtn] = useState(true);
  const [focus, setFocus] = useState(false);
  const [code, setCode] = useState('');
  const [id, setId] = useState('');
  const [codeVerification, setCodeVerification] = useState('');
  const [dataLogin, setDataLogin] = useState('');
  const [buttonClicked, setButtonClicked] = useState(true);
  const [tryLater, settryLater] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let intervalId;
    // Inicia el temporizador
    if (!showStartBtn) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    // Detiene el temporizador
    if (countdown === 0) {
      clearInterval(intervalId);
      setShowStartBtn(true);
    }
    // Limpia el temporizador
    return () => clearInterval(intervalId);
  }, [countdown, showStartBtn]);

  const handleClick = () => {
    setButtonClicked(false);
    settryLater(true);
  };
  const handleStart = () => {
    setShowStartBtn(false);
    setCountdown(30);
  };

  const formatTime = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const onSubmit = async (e) => {
    try {
      setShowLoading(true);
      if (!dataLogin) {
        toast.error('Por favor ingresar la información solicitada');
        setShowLoading(false);
        return;
      }
      let data = {};
      if (dataLogin !== undefined) {
        if (dataLogin.charAt(0) === '3') {
          data.phone = dataLogin;
          const result = await axios.post(
            // `http://localhost:3000/dev/loginClienteWeb`,
            `${URL_AWS}/beParking/loginClienteWeb`,
            data
          );
          if (result.data.estado === true) {
            if (result.data.codeValidation === true) {
              toast.success(result.data.mensaje);
              setId(result.data.id);
              setFocus(true);
              setShowLoading(false);
              setTab(3);
            } else {
              toast.success(result.data.mensaje);
              setFocus(false);
              setShowLoading(false);
              setTab(2);
            }
          } else {
            setShowLoading(false);
            toast.error(
              'Hubo un error validando tus datos por favor comunicate con servicio al cliente.'
            );
            return;
          }
        } else {
          data.email = dataLogin;
          const result = await axios.post(
            // `http://localhost:3000/dev/loginClienteWeb`,
            `${URL_AWS}/beParking/loginClienteWeb`,
            data
          );
          if (result.data.estado === true) {
            if (result.data.codeValidation === true) {
              toast.success(result.data.message);
              setFocus(true);
              setId(result.data.id);
              setShowLoading(false);
              setTab(3);
            } else {
              toast.success(result.data.mensaje);
              setTab(2);
              setFocus(false);
              setShowLoading(false);
              return;
            }
          } else {
            setShowLoading(false);
            toast.error(result.data.mensaje);
            return;
          }
        }
      } else {
        setShowLoading(false);
        toast.error('El dato ingresado no es valido');
        return;
      }
    } catch (error) {
      setShowLoading(false);
      console.log('ERROR:', error);
      toast.error('Ocurrió un error, vuelva a intentar');
      return;
    }
  };

  const loginBe = async () => {
    try {
      setFocus(false);
      let data = {};
      if (!dataLogin) {
        toast.error('El dato ingresado no es valido');
      }
      data.code = code;
      if (dataLogin.charAt(0) === '3') {
        data.phone = dataLogin;
        loginBeparking(data);
      } else {
        data.email = dataLogin;
        loginBeparking(data);
      }
    } catch (error) {
      setShowLoading(false);
      console.log('Error', error);
      toast.error('Hubo un problema al iniciar sesión');
      return;
    }
  };

  const validationCode = async () => {
    try {
      if (!codeVerification || !id) {
        toast.error('Por favor ingrese el código enviado.');
        return;
      }
      setShowLoading(true);
      let data = {
        code: codeVerification,
        cedula: id,
      };
      const respuesta = await axios.post(
        `${URL_AWS}/beParking/validationCode`,
        data
      );
      if (respuesta.data.status) {
        toast.success('Bienvenido a be parking, ya puedes iniciar sesión');
        setTab(1);
        setFocus(false);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        toast.error(respuesta.data.message);
        return;
      }
    } catch (error) {
      setShowLoading(false);
      console.log('ERROR', error);
      toast.error(
        'Hubo un error al enviarle el código. Por favor, inténtelo de nuevo más tarde'
      );
    }
  };

  return (
    <Container>
      {alerta ? (
        <Alert variant={alerta.categoria} transition={true}>
          {alerta.msg}
        </Alert>
      ) : null}
      <Row className="justify-content-center content-login-be mtop-40">
        <Col
          className="justify-content-center mtop-40"
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          <img className="img-beEnLinea" src={iconUser}></img>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
          <Jumbotron className="content-form content-be">
            <div className="form-usuario">
              {focus ? (
                <h1 className="texto-titulo-legal text-title-login  mbottom-20">
                  Finalizar registro
                </h1>
              ) : (
                <h1 className="texto-titulo-legal text-title-login  mbottom-20">
                  Iniciar sesión
                </h1>
              )}

              <Form>
                <Tab.Container defaultActiveKey={1} activeKey={tab}>
                  <Tab.Content className="iniciar-sesionbe">
                    <Tab.Pane eventKey={1}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Correo electrónico o número de celular
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={dataLogin}
                          placeholder="Ingrese su correo electrónico o número de celular"
                          onChange={(e) => setDataLogin(e.target.value)}
                        />
                      </Form.Group>
                      <div className="text-center btn-cont-be">
                        {showLoading ? (
                          <div className="container-sniper"></div>
                        ) : (
                          <div className="text-center btn-cont-be">
                            <Button
                              variant="primary"
                              onClick={() => {
                                handleStart();
                                onSubmit();
                              }}
                            >
                              Continuar
                            </Button>
                          </div>
                        )}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey={2}>
                      {/**content de 200 */}
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="text-code">
                          Ingrese el código que le fue enviado a través de su
                          correo electrónico y número de celular
                        </Form.Label>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <OtpInput
                            inputType="tel"
                            value={code}
                            onChange={setCode}
                            numInputs={6}
                            inputStyle={{
                              width: '2em',
                              marginTop: '5px',
                              border: '1px solid rgba(0, 0, 0, 0.4)',
                              borderRadius: '4px',
                              fontWeight: '600',
                            }}
                            renderSeparator={
                              <span style={{ color: 'transparent' }}>-</span>
                            }
                            renderInput={(props) => (
                              <input style={{ placeholder: '-' }} {...props} />
                            )}
                          />
                        </div>
                      </Form.Group>

                      {showStartBtn ? (
                        <Col className="text-center p-0 mtop-40">
                          <span className="text-gris">
                            ¿No recibiste el código?
                          </span>
                          {buttonClicked && (
                            <Button
                              variant="primary"
                              className="btn-borde-verde ml-3"
                              size="sm"
                              onClick={() => {
                                handleClick();
                                handleStart();
                                onSubmit();
                              }}
                            >
                              Reenviar código
                            </Button>
                          )}
                          {tryLater && (
                            <Col className="text-center">
                              <p className="text-info-upload">
                                Clic{' '}
                                <a
                                  className="Clic"
                                  onClick={() => setShow(true)}
                                >
                                  {' '}
                                  aquí{' '}
                                </a>{' '}
                                si necesitas ayuda
                              </p>
                            </Col>
                          )}
                        </Col>
                      ) : (
                        <Col className="text-center p-0 mtop-40">
                          <span className="text-gris-code">
                            Espera {formatTime()} segundos para que te llegue tu
                            código.
                          </span>
                        </Col>
                      )}
                      {showLoading ? (
                        <div className="container-sniper"></div>
                      ) : (
                        <div className="text-center btn-cont-be">
                          <div className="text-center  btn-iniciarbe">
                            <Button onClick={() => loginBe()}>
                              Iniciar Sesión
                            </Button>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={3}>
                      {/**content de 200 */}
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="text-code">
                          Ingrese el código que le fue enviado a través de su
                          correo electrónico y número de celular
                        </Form.Label>
                        {/* <Form.Control
                          type="text"
                          value={codeVerification}
                          maxLength={6}
                          placeholder="Ingresa el código de verificación."
                          onChange={(e) =>
                            setCodeVerification(e.target.value.slice(0, 6))
                          }
                        /> */}
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <OtpInput
                            inputType="tel"
                            value={codeVerification}
                            onChange={setCodeVerification}
                            numInputs={6}
                            inputStyle={{
                              width: '2em',
                              marginTop: '5px',
                              border: '1px solid rgba(0, 0, 0, 0.4)',
                              borderRadius: '4px',
                              fontWeight: '600',
                            }}
                            renderSeparator={
                              <span style={{ color: 'transparent' }}>-</span>
                            }
                            renderInput={(props) => (
                              <input style={{ placeholder: '-' }} {...props} />
                            )}
                          />
                        </div>
                      </Form.Group>

                      {showStartBtn ? (
                        <Col className="text-center p-0 mtop-40">
                          <span className="text-gris">
                            ¿No recibiste el código?
                          </span>
                          {buttonClicked && (
                            <Button
                              variant="primary"
                              className="btn-borde-verde-code "
                              size="sm"
                              onClick={() => {
                                handleClick();
                                handleStart();
                                onSubmit();
                              }}
                            >
                              Reenviar código
                            </Button>
                          )}
                          {tryLater && (
                            <Col className="text-center">
                              <p className="text-info-upload">
                                Clic{' '}
                                <a
                                  className="Clic"
                                  onClick={() => setShow(true)}
                                >
                                  {' '}
                                  aquí{' '}
                                </a>{' '}
                                si necesitas ayuda
                              </p>
                            </Col>
                          )}
                        </Col>
                      ) : (
                        <Col className="text-center p-0 mtop-40">
                          <span className="text-gris-code">
                            Espera {formatTime()} segundos para que te llegue tu
                            código.
                          </span>
                        </Col>
                      )}
                      <div className="text-center  btn-iniciarbe">
                        <Button onClick={() => validationCode()}>
                          Finalizar registro
                        </Button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Form>
            </div>
            <LoginComponent open={show} onOpen={(estado) => setShow(estado)} />
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginBeParking;
