import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import logo from '../../asses/img/logo.png';
import logo2x from '../../asses/img/logo@2x.png';
import logo3x from '../../asses/img/logo@3x.png';
import iconLocation from '../../asses/img/icon-location.svg';
import iconphone from '../../asses/img/icon-phone.svg';
import iconmail from '../../asses/img/icon-email.svg';
import iconDoc from '../../asses/img/icon-doc.svg';
import facebook from '../../asses/img/facebook.png';
import instagram from '../../asses/img/instagram.png';
import playstore from '../../asses/img/playstore.png';
import appstore from '../../asses/img/appstore.png';

import * as ROUTES from '../../constans/rutas';

const FooterBeparking = props => {
    return (
        <footer className="footer be-footer">
            <Container>
                <Row>
                    <Col>
                        <ul className="footer-menu">
                            <li>
                                <NavLink  
                                    to={ROUTES.BETERMINOS} 
                                    className="navbar-brand">
                                    Términos y condiciones
                                </NavLink>
                            </li>
                            <li>
                                <NavLink  
                                    to={ROUTES.BEPPRIVACIDAD} 
                                    className="navbar-brand">
                                    Política de privacidad
                                </NavLink>
                            </li>
                            <li>
                                <NavLink  
                                    to={ROUTES.BEPTRATAMIENTO} 
                                    className="navbar-brand">
                                    política de tratamiento de datos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink  
                                    to={ROUTES.BEPUNTOSEX} 
                                    className="navbar-brand">
                                    Puntos de servicio be parking

                                </NavLink>
                            </li>
                            <li>
                                <a  
                                    href="https://www.sic.gov.co/" 
                                    target="_blank" 
                                    className="navbar-brand">
                                    Superintendencia de Industria y Comercio
                                </a>
                            </li>
                        </ul>
                        
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <div className="dividir-footer"></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="copi">©2021 Parking - Todos los derechos reservados</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default FooterBeparking;