import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { BsArrowLeft, BsPlusCircle, BsXCircle } from 'react-icons/bs';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

import { db, stg, fun, analytic } from '../../server/firebase';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import AuthContex from '../../context/autenticacion/authContex';
import FooterBeparking from '../../components/footer/beparking';
import CabezeraComponent from '../../components/header/cabezera';
import marcas from '../../constans/marcas.json';
import modelos from '../../constans/modelos.json';
import motosMarcas from '../../constans/motosmarcas.json';
import motosModelos from '../../constans/motosmodelos.json';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import iconVehiculo from '../../asses/img/icon-vehiculo.png';
import iconVehiculo2x from '../../asses/img/icon-vehiculo@2x.png';
import iconVehiculo3x from '../../asses/img/icon-vehiculo@3x.png';
import iconVehiculoAzul from '../../asses/img/icon-vehiculo-azul.png';
import iconVehiculoAzul2x from '../../asses/img/icon-vehiculo-azul@2x.png';
import iconVehiculoAzul3x from '../../asses/img/icon-vehiculo-azul@3x.png';
import iconCart from '../../asses/img/icon-cart.png';
import iconCart2x from '../../asses/img/icon-cart@2x.png';
import iconCart3x from '../../asses/img/icon-cart@3x.png';
import iconCartGris from '../../asses/img/icon-cart-gris.png';
import iconCartGris2x from '../../asses/img/icon-cart-gris@2x.png';
import iconCartGris3x from '../../asses/img/icon-cart-gris@3x.png';
import iconCartAzul from '../../asses/img/icon-cart-azul.png';
import iconCartAzul2x from '../../asses/img/icon-cart-azul@2x.png';
import iconCartAzul3x from '../../asses/img/icon-cart-azul@3x.png';
import iconMoto from '../../asses/img/icon-moto.png';
import iconMoto2x from '../../asses/img/icon-moto@2x.png';
import iconMoto3x from '../../asses/img/icon-moto@3x.png';
import iconMotoGris from '../../asses/img/icon-moto-gris.png';
import iconMotoGris2x from '../../asses/img/icon-moto-gris@2x.png';
import iconMotoGris3x from '../../asses/img/icon-moto-gris@3x.png';
import iconMotoAzul from '../../asses/img/icon-moto-azul.png';
import iconMotoAzul2x from '../../asses/img/icon-moto-azul@2x.png';
import iconMotoAzul3x from '../../asses/img/icon-moto-azul@3x.png';

const BeVehiculosPage = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const authContex = useContext(AuthContex);
  const { usuario, actualizarUsuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState();

  const [tipoVehiculo, setTipoVehiculo] = useState('M');
  const [marcaSelect, setMarcaSelect] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const [vehiculos, setVehiculos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isValid  },
  } = useForm({ mode: "onChange" });

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const onSubmit = async (data) => {
    reset();
    try {
      setIsLoading(true); 
  
      const datos = {
        ...data,
        cedula: usuario.cedula,
        tipoVehiculo: tipoVehiculo === 'M' ? '2' : '1',
      };
  
      const resultado = await axios.post(`${URL_AWS}/beParking/vehiculo`, datos);
  
      console.log(datos);
      actualizarUsuario(usuario.cedula);
  
      toast.success("Vehículo agregado exitosamente");
      setTimeout(() => {
        setIsLoading(false); 
      }, 2000);
    } catch (error) {
      toast.error("Error, inténtelo de nuevo");
      //toast.error(error.response.data.mensaje);
    }
  };

  const eliminarVehiculo = async (placa) => {
    console.log(placa);
    try {
      const datos = {
        cedula: usuario.cedula,
        placa: placa,
      };
      const resultado = await axios.post(
        `${URL_AWS}/beParking/inactivarVehiculo`,
        datos
      );
      actualizarUsuario(usuario.cedula);
      toast.success(resultado.data.mensaje);
    } catch (error) {
      toast.error(error.response.data.mensaje);
    }
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
  }, [vehiculos]); 
  
  useEffect(() => {
    analytic.logEvent('page_view', { page: `Vehiculos Beparking` });
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <CabezeraComponent />

        <div
          className={
            usuario?.extracto?.categoria === 'GREEN'
              ? 'be-info mtop-20'
              : 'be-info mtop-20 azul'
          }
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                className={
                  usuario?.extracto?.categoria === 'GREEN'
                    ? 'btn-primary'
                    : 'btn-primary azul'
                }
              >
                <BsArrowLeft /> Regresa a tus datos
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className="texto-titulo mtop-20">Tus datos.</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="caja-perfil">
                <div className="caja-perfil-header">
                  <p>Tus vehículos</p>
                  <div className="icon-content">
                    {usuario?.extracto?.categoria === 'GREEN' ? (
                      <img
                        src={iconVehiculo}
                        className="icon-icon"
                        srcSet={`${iconVehiculo2x} 2x, ${iconVehiculo3x} 3x`}
                        alt="Informacion del usuario"
                      />
                    ) : (
                      <img
                        src={iconVehiculoAzul}
                        className="icon-icon"
                        srcSet={`${iconVehiculoAzul2x} 2x, ${iconVehiculoAzul3x} 3x`}
                        alt="Informacion del usuario"
                      />
                    )}
                  </div>
                </div>
                <div className="caja-perfil-body">
                  <div className="linea-separadora"></div>

                  <div className="contenedor-agregar">
  <p>
    {usuario?.vehiculo.length === 1
      ? 'Tienes 1 vehículo agregado'
      : usuario?.vehiculo.length > 1
      ? `Tienes ${usuario?.vehiculo.length} vehículos agregados`
      : 'Aún no tienes vehículos agregados'}
  </p>
  {usuario?.vehiculo.length < 3 && (
    <Button variant="outline-success">
      <BsPlusCircle /> Agregar un vehículo
    </Button>
  )}
</div>


                  <ul className="lista-vehiculos">
                    {usuario?.vehiculo.map((item) => (
                      <li key={item.vehiculoId}>
                        <Row>
                          <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Form.Label className="text-center w-100">
                              Tipo de vehículo
                            </Form.Label>
                            <ul className="tipo-vehiculo">
                              <li>
                                {item.tipoVehiculo === '2' ? (
                                  usuario?.extracto?.categoria === 'GREEN' ? (
                                    <img
                                      src={iconMoto}
                                      className="icon-icon"
                                      srcSet={`${iconMoto2x} 2x, ${iconMoto3x} 3x`}
                                      alt="Icono car"
                                    />
                                  ) : (
                                    <img
                                      src={iconMotoAzul}
                                      className="icon-icon"
                                      srcSet={`${iconMotoAzul2x} 2x, ${iconMotoAzul3x} 3x`}
                                      alt="Icono car"
                                    />
                                  )
                                ) : usuario?.extracto?.categoria === 'GREEN' ? (
                                  <img
                                    src={iconCart}
                                    className="icon-icon"
                                    srcSet={`${iconCart2x} 2x, ${iconCart3x} 3x`}
                                    alt="Icono car"
                                  />
                                ) : (
                                  <img
                                    src={iconCartAzul}
                                    className="icon-icon"
                                    srcSet={`${iconCartAzul2x} 2x, ${iconCartAzul3x} 3x`}
                                    alt="Icono car"
                                  />
                                )}
                              </li>
                            </ul>
                          </Col>
                          <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                            <Form.Label className="text-center w-100">
                              Marca
                            </Form.Label>
                            <p className="text-center">{item.marca}</p>
                          </Col>
                          <Col xs={12} sm={3} md={2} lg={2} xl={2}>
                            <Form.Label className="text-center w-100">
                              Modelo
                            </Form.Label>
                            <p className="text-center">{item.modelo}</p>
                          </Col>
                          <Col xs={12} sm={3} md={2} lg={2} xl={2}>
                            <Form.Label className="text-center w-100">
                                Línea
                            </Form.Label>
                            <p className="text-center">{item.linea}</p>
                          </Col>
                          <Col xs={12} sm={3} md={2} lg={2} xl={2}>
                            <Form.Label className="text-center w-100">
                              Placa
                            </Form.Label>
                            <p className="text-center">{item.placa}</p>
                          </Col>
                          <Button
                            variant="link"
                            onClick={() => eliminarVehiculo(item.placa)}
                          >
                            {' '}
                            <BsXCircle />{' '}
                          </Button>
                        </Row>
                      </li>
                    ))}
                    {usuario?.vehiculo.length < 3 && (
                      <li>
                        <Form>
                          <Row>
                            <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label className="text-center w-100">
                                  Tipo de vehículo
                                </Form.Label>
                                <ul className="tipo-vehiculo">
                                  <li onClick={() => setTipoVehiculo('M')}>
                                    {tipoVehiculo === 'M' ? (
                                      usuario?.extracto?.categoria ===
                                      'GREEN' ? (
                                        <img
                                          src={iconMoto}
                                          className="icon-icon"
                                          srcSet={`${iconMoto2x} 2x, ${iconMoto3x} 3x`}
                                          alt="Icono car"
                                        />
                                      ) : (
                                        <img
                                          src={iconMotoAzul}
                                          className="icon-icon"
                                          srcSet={`${iconMotoAzul2x} 2x, ${iconMotoAzul3x} 3x`}
                                          alt="Icono car"
                                        />
                                      )
                                    ) : (
                                      <img
                                        src={iconMotoGris}
                                        className="icon-icon"
                                        srcSet={`${iconMotoGris2x} 2x, ${iconMotoGris3x} 3x`}
                                        alt="Icono car"
                                      />
                                    )}
                                  </li>
                                  <li onClick={() => setTipoVehiculo('C')}>
                                    {tipoVehiculo === 'C' ? (
                                      usuario?.extracto?.categoria ===
                                      'GREEN' ? (
                                        <img
                                          src={iconCart}
                                          className="icon-icon"
                                          srcSet={`${iconCart2x} 2x, ${iconCart3x} 3x`}
                                          alt="Icono car"
                                        />
                                      ) : (
                                        <img
                                          src={iconCartAzul}
                                          className="icon-icon"
                                          srcSet={`${iconCartAzul2x} 2x, ${iconCartAzul3x} 3x`}
                                          alt="Icono car"
                                        />
                                      )
                                    ) : (
                                      <img
                                        src={iconCartGris}
                                        className="icon-icon"
                                        srcSet={`${iconCartGris2x} 2x, ${iconCartGris3x} 3x`}
                                        alt="Icono car"
                                      />
                                    )}
                                  </li>
                                </ul>
                              </Form.Group>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label className="text-center w-100">
                                  Marca
                                </Form.Label>
                                <Controller
                                  name="marca"
                                  control={control}
                                  rules={{ required: "La marca es obligatoria" }}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <Form.Control
                                      as="select"
                                      onChange={(value, index) => {
                                        onChange(value.target.value);
                                        setMarcaSelect(
                                          value.target.options[
                                            value.target.options.selectedIndex
                                          ].id
                                        );
                                      }}
                                      value={value}
                                      placeholder="Ingresa la marca de tu vehículo"
                                    >
                                      <option>
                                        Selecciona la marca de tu vehículo
                                      </option>
                                      {tipoVehiculo === 'C'
                                        ? marcas.map((item, index) => {
                                            return (
                                              <option
                                                value={item.Nombre}
                                                id={item.id}
                                              >
                                                {item.Nombre}
                                              </option>
                                            );
                                          })
                                        : motosMarcas.map((item, index) => {
                                            return (
                                              <option
                                                value={item.Nombre}
                                                id={item.id}
                                              >
                                                {item.Nombre}
                                              </option>
                                            );
                                          })}
                                      <option value="Otra">Otra</option>
                                    </Form.Control>
                                  )}
                                />
                                {errors.marca && (
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="error"
                                  >
                                    La marca es obligatorio
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            <Col xs={12} sm={3} md={2} lg={2} xl={2}>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Modelo</Form.Label>
    <Controller
      name="modelo"
      control={control}
      rules={{
        required: "El modelo es obligatorio (Número de 4 dígitos)",
        validate: {
          modeloValido: (value) => {
            const regexModelo = /^[0-9]{4}$/; 
            if (!regexModelo.test(value)) {
              return "El modelo debe ser un número de 4 dígitos";
            }
            return true;
          },
        },
      }}
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => (
        <Form.Control
          type="text"
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          onInput={(e) => {
            
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
          }}
          value={value}
          placeholder="Ingresa el modelo"
          maxLength={4} 
        />
      )}
    />
    {errors.modelo && (
      <Form.Text id="passwordHelpBlock" className="error">
        {errors.modelo.message}
      </Form.Text>
    )}
  </Form.Group>
</Col>                       
<Col xs={12} sm={3} md={2} lg={2} xl={2}>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Línea</Form.Label>
    <Controller
      name="linea"
      control={control}
      rules={{ required: "La línea es obligatoria" }}
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => (
        <Form.Control
          type="text"
          onBlur={onBlur}
          onChange={(e) => {
            const regexLinea = /^[A-Za-z0-9\- ]*$/;  // Permite letras, números, guiones y espacios
            if (regexLinea.test(e.target.value)) {
              onChange(e.target.value);
            }
          }}
          value={value}
          placeholder="Ingresa la línea de tu vehículo"
        />
      )}
    />
    {errors.linea && (
      <Form.Text id="passwordHelpBlock" className="error">
        {errors.linea.message}
      </Form.Text>
    )}
  </Form.Group>
</Col>

<Col xs={12} sm={3} md={2} lg={2} xl={2}>
  <Form.Group controlId="formBasicEmail">
    <Form.Label className="text-center w-100">
      Placa
    </Form.Label>
    <Controller
      name="placa"
      control={control}
      rules={{
        required: "La placa es obligatoria",
        validate: {
          placaValida: (value) => {
            if (tipoVehiculo === 'C') {
              const regexCarro = /^[A-Za-z]{3}[0-9]{3}$/; // Solo 3 letras seguidas de 3 números
              if (!regexCarro.test(value)) {
                return "Formato inválido para carro. EJEMPLO: ABC123";
              }
            } else if (tipoVehiculo === 'M') {
              const regexMoto = /^[A-Za-z]{3}[0-9]{2}[A-Za-z]{1}$/; // Solo 3 letras, 2 números y 1 letra
              if (!regexMoto.test(value)) {
                return "Formato inválido para moto. EJEMPLO: ABC12E";
              }
            }
            return true; 
          },
        },
      }}
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => (
        <Form.Control
          type="text"
          onBlur={onBlur}
          onChange={(e) => {
            const regex = /^[A-Za-z0-9]*$/; // Solo letras y números
            const valueToSet = e.target.value.toUpperCase();
            if (regex.test(valueToSet)) {
              onChange(valueToSet); // Asegura que solo letras y números sean aceptados
            }
          }}
          value={value}
          placeholder="Ingresa la placa de tu vehículo"
          maxLength={6} // Limita la longitud a 6 caracteres
        />
      )}
    />
    {errors.placa && (
      <Form.Text id="passwordHelpBlock" className="error">
        {errors.placa.message}
      </Form.Text>
    )}
  </Form.Group>
</Col>
        
                            <Button variant="link">
                              {' '}
                              <BsXCircle />{' '}
                            </Button>
                          </Row>
                        </Form>
                      </li>
                    )}
                  </ul>

                  <div className="contenedor-agregar-limite">
                    <p>Puedes agregar hasta 3 vehículos</p>
                    {usuario?.vehiculo.length < 3 && (
                     <Button
                     style={{
                       backgroundColor: isValid ? "#90D400" : "transparent",
                       borderColor: isValid ? "#90D400" : "#90D400",
                       color: isValid ? "white" : "#90D400",
                     }}
                     onClick={async () => {
                       const isValidForm = await trigger(); // Valida todos los campos
                       if (isValidForm) {
                         handleSubmit(onSubmit)(); // Si todo está válido, llama a onSubmit
                       }
                     }}
                   >
                     <BsPlusCircle /> Guardar
                   </Button>
                   )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <FooterBeparking />
    </>
  );
};

export default BeVehiculosPage;