import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaEdit, FaTrash, FaSave, FaTimes, FaPlus } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { animateScroll as scroll } from 'react-scroll';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import EditServicios from '../../components/modal/modalServicios';
import ModalDatos from '../../components/modal/modalDatos';
import ModalCategorias from '../../components/modal/modalCategorias';
import { useForm, Controller, Feedback, set } from 'react-hook-form';

import GoogleMaps from '../../components/mapa';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import * as ROUTES from '../../constans/rutas';

const Noticias = () => {
  const [loading, setLoading] = useState(false);
  const [noticias, setNoticias] = useState([]);

  const consultarNoticias = async () => {
    setLoading(true);
    try {
      const resultado = await db
        .collection('Noticias')
        .orderBy('creado_en', 'desc')
        .get();
      //const resultado = await db.collection('Noticias').orderBy('orden').get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setNoticias(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando las noticias...');
      console.log(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    consultarNoticias();
  }, []);
  const userData = async () => {
    setLoading(true);
    setLoading(false);
  };
  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container className="container-Noticias">
        {noticias.map((item, index) => {
          return (
            <>
              {item.estado == 1 ? (
                <>
                  <div className="card">
                    <Link
                      className="card-title"
                      to={{
                        pathname: `/noticias/${item._id}/${encodeURIComponent(
                          item.nombre.trim().replace(/\s+/g, '_')
                        )}`,
                        // pathname: `/noticias/${item._id}/${item.nombre.replace( /\s+/g, '_' )}`,
                        item,
                      }}
                    >
                      <img src={item.imagen} alt={item._id} />
                    </Link>
                    <div className="card-content">
                      <h2 className="card-date">{item.creado}</h2>
                      <Link
                        className="card-title"
                        to={{
                          pathname: `/noticias/${item._id}/${encodeURIComponent(
                            item.nombre.trim().replace(/\s+/g, '_')
                          )}`,
                          item,
                        }}
                      >
                        {item.nombre}
                      </Link>
                      <p className="card-text">
                        {item.descripcion.length > 120
                          ? item.descripcion.substring(0, 120 - 3) + '...'
                          : item.descripcion}
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          );
        })}
      </Container>

      <FooterComponen />
    </>
  );
};
export default Noticias;
