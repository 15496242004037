import React, { useReducer } from "react";
import alertaReducers from "./alertaReducers";
import alertaContext from "./alertaContext";

import { MOSTRAR_ALERTA, OCULTAR_ALERTA } from "../../types";

const AlertaState = props => {
    const initialState = {
        alerta: null
    }
    const [state, dispatch] = useReducer(alertaReducers, initialState);

    const mostrarAlerta = (msg, categoria) => {
        dispatch({
            type: MOSTRAR_ALERTA,
            payload: {
                msg,
                categoria
            }
        })

        setTimeout( ()=> {
            dispatch({
                type: OCULTAR_ALERTA
            })
        }, 5000)
    }
    return(
        <alertaContext.Provider 
            value={{ 
                alerta: state.alerta, 
                mostrarAlerta
            }}
        >
            {props.children}
        </alertaContext.Provider>
    )
}

export default AlertaState;