export const HOME = '/';
export const EMPRESA = '/empresas';
export const NOSOTROS = '/nosotros';
export const NOTICIA = `/noticias/:id/:nombre`;
export const LOGIN = '/admin';
export const LOGIN_LEGAL = '/admin_legal';
export const REGISTRO = '/crearusuario';
export const PERFIL = '/perfil';
export const ANIVERSARIO = '/aniversario';
export const TERMINOS = '/terminosycondiciones';
export const PTEE = '/politica-PTEE';
export const PTEES = '/politica-PTEE/:id/:nombre';
export const CONTACTO = '/contacto';
export const AYUDAPUNTOS = '/Ayuda-AcumulacionPuntos';
export const BEPARKING = '/beparking';
export const BEINICIARSESION = '/beparking/iniciar_sesion';
export const BEREGISTROESPECIAL = '/beparking/registro-especial';
export const BECONTACTO = '/beparking/contacto';
export const BEREGISTRO = '/beparking/registro';
export const BEAYUDA = '/beparking/ayuda';
export const BECONTRASENAG = '/beparking/contrasena_Guia';
export const BECONTRASENA = '/beparking/contrasena';
export const BEPERFIL = '/beparking/perfil';
export const BEUSUARIOS = '/usuarios';
export const NOTICIAS = '/noticias';
export const BEINFORMACION = '/beparking/datos/userinfo';
export const BEHISTORIAL = '/beparking/datos/historial';
export const BEVEHICULOS = '/beparking/datos/vehiculos';
export const BEDETALLES = '/beparking/detalle';
export const BEDETALLE = '/beparking/detalle/:id';
export const BETUBEPARKING = '/beparking/tubeparking';
export const BETUSBONOS = '/beparking/tusbonos';
export const BESOPORTE = '/beparking/soporte';
export const BETERMINOS = '/beparking/terminos';
export const BEPPRIVACIDAD = '/beparking/politicas-privacidad';
export const BEPTRATAMIENTO = '/beparking/politicas-tratamiento';
export const BEPUNTOSEX = '/beparking/puntos-servicios-beparking';
export const BEPROMOCIONES = '/beparking/promociones';
export const LANDINGMENSUALIDADES =
  '/landing/mensualidades/:identificacio/:phone/:tipo/:email';
export const LANDINGFACTURACION =
  '/landing/facturacion-electronica/creacion-clientes';
export const LANDINGFACTURACIONVALIDACION =
  '/landing/facturacion-electronica/validacion';
export const LANDINGAYUDAFACTURACIONELECTRONICA =
  '/landing/facturacion-electronica/ayuda';
export const ProofPaymentLandingPage =
  '/landing/facturacion-electronica/data:dataSend';
export const LANDINGPORTAL = '/landing/enlaces-web';
export const ALIANZASREGISTRO = '/alianzas/registro';
export const ALIANZASREGISTROTERPEL = '/alianza-terpel/registro';
export const CREATEUSER = '/retentionCertificates/createUser';
export const PROFILEUSER = '/retentionCertificates/profile';
export const LOGINRETENTION = '/retentionCertificates/admin_retention';
export const UPLOADDOCS = '/retentionCertificates/upload_doc';
export const CANALETICA = '/canalEtica';
export const ParkingMedia = '/parkingMedia';
