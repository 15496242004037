import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import axios from 'axios';
import AuthContex from '../../context/autenticacion/authContex';
import { URL_EMPLOYEE } from '../../constans/servidor';
import ModalReplace from './modalReplace';

const ModalBuscar = ({ open, onOpen }) => {
  const authContex = useContext(AuthContex);
  const { usuario, cerrarSesion } = authContex;
  const [showPass, setShowPass] = useState(false);
  const [nit, setNit] = useState(''); //800675511
  const [type, setType] = useState('');
  const [year, setYear] = useState('');
  const [user, setUser] = useState('');
  const [replace, setReplace] = useState(false);

  const [loginLoading] = useState(false);

  const searchNit = async (e) => {
    e.preventDefault();

    if (nit && type && year) {
      let token = localStorage.getItem('token');
      let typeUser = localStorage.getItem('typeUser');

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const data = {
        Nit: nit,
        type: type,
        year: year,
        typeUser: typeUser,
      };
      try {
        const result = await axios.post(
          URL_EMPLOYEE + `/Suppliers/consutlClient`,
          data,
          {
            headers: headers,
          }
        );
        console.log(result);
        if (result.data.status === true) {
          toast.success('Cliente encontrado con exito!');
          setUser(result.data.data);
          setReplace(true);
          onOpen(false);
        } else {
          if (
            result.data.message ===
            'Hubo un error al consultar el cliente, jwt expired'
          ) {
            toast.error(
              'Porfavor inicie sesion nuevamente su token expiro',
              'danger'
            );
            cerrarSesion();
          } else {
            toast.error(result.data.message);
          }
        }
      } catch (error) {
        console.log('Error', error.message);
        toast.error(
          error.response ? error.response.data.mensaje : 'Algo salio mal'
        );
      }
    } else {
      toast.error('Se requiere el tipo de documento y el nit del proveedor');
      return;
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    setShowPass(false);
    // history.push(ROUTES.BEPARKING);
    setNit(usuario?.cedula);
  }, [usuario]);

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={() => onOpen(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Busca por NIT</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form className="conten-login" onSubmit={searchNit}>
            <Form.Group controlId="formBasicEmail" className="control-numero">
              <Form.Label>NIT</Form.Label>
              <Form.Control
                type="text"
                value={nit}
                placeholder="Ingresa el NIT"
                onChange={(e) => setNit(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Tipo de certificado</Form.Label>
              <Form.Control
                as="select"
                type="string"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option>Elige una opción</option>
                <option>Certificado de retención en la fuente</option>
                <option>Certificado de retención ICA</option>
                <option>Certificado de retención IVA</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Año</Form.Label>
              <Form.Control
                className="form-retention"
                as="select"
                type="number"
                //  value={identificacion}
                onChange={(e) => setYear(e.target.value)}
              >
                <option>Elige un año</option>
                <option>2022</option>
                <option>2023</option>
                <option>2024</option>
              </Form.Control>
            </Form.Group>
            <div className="text-center">
              <Button className="btn-borde-verde" size="sm" type="submit">
                {loginLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Buscar'
                )}
              </Button>
            </div>

            <div className="text-center">
              <Button
                onClick={() => onOpen(false)}
                variant="link"
                className="btn-enlace-gris"
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ModalReplace
        replace={replace}
        onreplace={(state) => setReplace(state)}
        user={user}
      />
    </>
  );
};

export default ModalBuscar;
