import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { URL_AWS } from '../../constans/servidor';
import AuthContex from '../../context/autenticacion/authContex';
import { useForm, Controller } from 'react-hook-form';
import marcas from '../../constans/marcas.json';
import ciudadOptions from '../../constans/ciudades.json';
import motosMarcas from '../../constans/motosmarcas.json';

import iconVehiculo from '../../asses/img/icon-vehiculo.png';
import iconVehiculo2x from '../../asses/img/icon-vehiculo@2x.png';
import iconVehiculo3x from '../../asses/img/icon-vehiculo@3x.png';
import iconVehiculoAzul from '../../asses/img/icon-vehiculo-azul.png';
import iconVehiculoAzul2x from '../../asses/img/icon-vehiculo-azul@2x.png';
import iconVehiculoAzul3x from '../../asses/img/icon-vehiculo-azul@3x.png';
import iconCart from '../../asses/img/icon-cart.png';
import iconCart2x from '../../asses/img/icon-cart@2x.png';
import iconCart3x from '../../asses/img/icon-cart@3x.png';
import iconCartGris from '../../asses/img/icon-cart-gris.png';
import iconCartGris2x from '../../asses/img/icon-cart-gris@2x.png';
import iconCartGris3x from '../../asses/img/icon-cart-gris@3x.png';
import iconCartAzul from '../../asses/img/icon-cart-azul.png';
import iconCartAzul2x from '../../asses/img/icon-cart-azul@2x.png';
import iconCartAzul3x from '../../asses/img/icon-cart-azul@3x.png';
import iconMoto from '../../asses/img/icon-moto.png';
import iconMoto2x from '../../asses/img/icon-moto@2x.png';
import iconMoto3x from '../../asses/img/icon-moto@3x.png';
import iconMotoGris from '../../asses/img/icon-moto-gris.png';
import iconMotoGris2x from '../../asses/img/icon-moto-gris@2x.png';
import iconMotoGris3x from '../../asses/img/icon-moto-gris@3x.png';
import iconMotoAzul from '../../asses/img/icon-moto-azul.png';
import iconMotoAzul2x from '../../asses/img/icon-moto-azul@2x.png';
import iconMotoAzul3x from '../../asses/img/icon-moto-azul@3x.png';
const CompleteData = ({ open, onOpen }) => {
  const [tab, setTab] = useState(1);
  const authContex = useContext(AuthContex);
  let { usuario } = authContex;
  //console.log(usuario.direccion)
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [marcaSelect, setMarcaSelect] = useState();
  const [tipoVehiculo, setTipoVehiculo] = useState('M');
  const añosDisponibles = [];
  const añoActual = new Date().getFullYear();
  const [correo, setCorreo] = useState('');
  const [celular, setCelular] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [codeCity, setCodeCity] = useState('');
  const [genero, setGenero] = useState('');
  const [direccion, setDireccion] = useState('');
  const [placa, setPlaca] = useState('');
  const [modelo, setModelo] = useState('');
  const [marca, setMarca] = useState('');
  const [linea, setLinea] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  //const placaRegex = /^[A-Za-z0-9]{3}-?[A-Za-z0-9]{3}$/;
  const placaRegex = /^[A-Za-z]{3}[0-9]{2}[A-Za-z]?$|^[A-Za-z]{3}[0-9]{3}$|^[A-Za-z]{3}[0-9]{2}[A-Za-z]$/; //condicion para placas
  const noVehiculos = !usuario.vehiculo || usuario.vehiculo.length === 0;
  //console.log("datos vehiculo", usuario.vehiculo[0].marca)

  for (let año = añoActual + 1; año >= añoActual - 80; año--) {
    añosDisponibles.push(año);
  }

  const reloadPage = () => {
    window.location.reload();
  };
  //actualiza la información
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      setShowLoading(true);
      const updatedVehicles = usuario.vehiculo.map((vehiculo, index) => {
        return {
          brand: data[`marca_${index}`] || usuario.vehiculo[index].marca,
          model: data[`modelo_${index}`] || usuario.vehiculo[index].modelo,
          plate: vehiculo.placa,
          line: data[`linea_${index}`] || usuario.vehiculo[index].linea,
        };
      });
      if (noVehiculos) {
        updatedVehicles.push({
          typeVehicle: tipoVehiculo === 'M' ? '2' : '1',
          brand: data.marca || '',
          model: data.modelo || '',
          plate: data.placa || '',
          line: data.linea || '',
        });
      }
      console.log("Datos Vehículo", updatedVehicles);

      const dataUserAndVehicule = updatedVehicles.map((vehicle) => ({
        typeVehicle: tipoVehiculo === 'M' ? '2' : '1',
        brand: vehicle.brand,
        model: vehicle.model,
        plate: vehicle.plate,
        line: vehicle.line,
        address: data.direccion || usuario.direccion,
        gender: data.genero || usuario.genero,
        email: data.correo || usuario.email,
        city: data.ciudad || usuario.ciudad,
        phone: data.celular || usuario.celular,
        cedula: usuario.cedula,
      }));

      console.log("Datos enviados", dataUserAndVehicule);

      const answer = await axios.post(
        `${URL_AWS}/beParking/updateClient`,
        // `http://localhost:3000/dev/updateClient`,
        dataUserAndVehicule
      );

      if (answer.data.statusCode === 200) {
        toast.success('Actualización exitosa');
        // Cierre del modal solo si la actualización fue exitosa
        onOpen(false);
        setTimeout(() => {
          reloadPage();
        }, 3000);
      } else {
        setShowLoading(false);
        toast.error('Error al actualizar');
      }
    } catch (error) {
      toast.error('Error al actualizar');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={() => onOpen(false)}
        className="be-modal"
        backdrop="static" // hay que descomentar estas dos lineas para que el modal no se deje cerrar hasta completar la información
        keyboard={false} // Para desactivar el cierre del modal usando la tecla Esc
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {usuario?.nombre}
            {''} completa tu información
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {!usuario?.celular ||
              usuario.celular.trim() === '' ||
              !/^[3]\d{9}$/.test(usuario.celular) ? (
              <Form.Group controlId="formGenero">
                <Form.Label>Celular</Form.Label>
                <Controller
                  name="celular"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[3]\d{9}$/, // Expresión regular para validar que empiece con 3 y tenga 9 dígitos adicionales
                  }}
                  defaultValue={usuario?.celular}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="control-icon">
                      <Form.Control
                        type="tel"
                        onBlur={onBlur}
                        onChange={(e) => {
                          const inputValue = e.target.value.replace(/\D/g, ''); // Eliminar caracteres no numéricos
                          onChange(inputValue) && setCelular(inputValue);
                        }}
                        value={value}
                        maxLength={10} 
                        placeholder="Ingresa tu número de celular"
                      />
                    </div>
                  )}
                />
                {errors.celular && errors.celular.type === 'pattern' && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El número de celular debe comenzar con 3 y tener 10 dígitos
                  </Form.Text>
                )}
                {errors.celular && errors.celular.type !== 'pattern' && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El número de celular es obligatorio
                  </Form.Text>
                )}
              </Form.Group>
            ) : null}

            {!usuario?.email ||
              !/^[^\s@]+@[^\s@]+\w+(\.[^\s@]+)+$/.test(usuario.email) ? (
              /* Si usuario.email no tiene información o no es un correo válido, muestra el Form.Group */
              <Form.Group controlId="">
                <Form.Label>Correo</Form.Label>
                <Controller
                  name="correo"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[^\s@]+@[^\s@]+\w+(\.[^\s@]+)+$/,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="control-icon">
                      <Form.Control
                        type="text"
                        onBlur={onBlur}
                        onChange={(e) =>
                          onChange(e.target.value) && setCorreo(e.target.value)
                        }
                        value={value}
                        placeholder="Ingresa tu correo"
                      />
                    </div>
                  )}
                />
                {errors.correo && errors.correo.type === 'pattern' && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    Ingresa un correo válido
                  </Form.Text>
                )}
                {errors.correo && errors.correo.type !== 'pattern' && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El correo es obligatorio
                  </Form.Text>
                )}
              </Form.Group>
            ) : null}

            {!usuario?.ciudad || usuario.ciudad.trim() === '' ? (
              <Form.Group controlId="formGenero">
                <Form.Label>Ciudad</Form.Label>
                <Controller
                  name="ciudad"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={usuario?.ciudad}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="control-icon">
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          // Obtener el código de ciudad correspondiente al nombre seleccionado
                          const selectedCiudad = e.target.value;
                          const codeCiudad = ciudadOptions.find(
                            (ciudad) => ciudad.ciudad === selectedCiudad
                          )?.id;

                          // Actualizar el estado con el código de ciudad
                          setSelectedCity(selectedCiudad);
                          onChange(codeCiudad.toString());
                          setCodeCity(codeCiudad);
                        }}
                        value={selectedCity}
                        placeholder="Elige una opción"
                      >
                        <option disabled value="">
                          Ciudad
                        </option>
                        {ciudadOptions
                          .filter((ciudad) =>
                            [
                              'CALI',
                              'BOGOTÁ',
                              'MEDELLÍN',
                              'PEREIRA',
                              'BARRANQUILLA',
                            ].includes(ciudad.ciudad)
                          )
                          .map((ciudad) => (
                            <option key={ciudad.id} value={ciudad.ciudad}>
                              {ciudad.ciudad}
                            </option>
                          ))}
                      </Form.Control>
                    </div>
                  )}
                />
                {errors.ciudad && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    La ciudad es obligatoria
                  </Form.Text>
                )}
              </Form.Group>
            ) : null}

            {usuario?.direccion !== 'null' &&
              usuario?.direccion.trim() !==
              '' /* Si usuario.direccion tiene información (y no es 'null' o una cadena vacía), no muestra el Form.Group */ ? null : (
              <Form.Group controlId="">
                <Form.Label>Dirección</Form.Label>
                <Controller
                  name="direccion"
                  control={control}
                  rules={{ required: true }}
                  //defaultValue={usuario?.direccion}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="control-icon">
                      <Form.Control
                        type="text"
                        onBlur={onBlur}
                        onChange={(e) =>
                          onChange(e.target.value) &&
                          setDireccion(e.target.value)
                        }
                        value={value}
                        placeholder="Ingresa tu dirección"
                      />
                    </div>
                  )}
                />
                {errors.direccion && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    La dirección es obligatoria
                  </Form.Text>
                )}
              </Form.Group>
            )}

            {!usuario?.genero || usuario.genero.trim() === '' ? (
              /* Si usuario.genero no tiene información (incluyendo cadena vacía), muestra el Form.Group para genero */
              <Form.Group controlId="formGenero">
                <Form.Label>Género</Form.Label>
                <Controller
                  name="genero"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={usuario?.genero}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="control-icon">
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          // Permitir el ingreso de texto
                          onChange(e.target.value);
                          setGenero(e.target.value);
                        }}
                        value={value}
                        placeholder="Elige una opción"
                      >
                        <option>Elige una opción</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                      </Form.Control>
                    </div>
                  )}
                />
                {errors.genero && (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El género es obligatorio
                  </Form.Text>
                )}
              </Form.Group>
            ) : null}
            {noVehiculos && (
              <>
                <Form.Group >
                  <Form.Label>
                    Selecciona tu tipo de vehículo
                  </Form.Label>
                  <ul className="tipo-vehiculo list-unstyled d-flex mb-0">
                    <li onClick={() => setTipoVehiculo('M')} style={{ marginRight: '15px' }}>
                      {tipoVehiculo === 'M' ? (
                        usuario?.extracto?.categoria ===
                          'GREEN' ? (
                          <img
                            src={iconMoto}
                            className="acting-icons"
                            srcSet={`${iconMoto2x} 2x, ${iconMoto3x} 3x`}
                            alt="Icono car"
                            style={{ width: '36px', height: '30px' }}
                          />
                        ) : (
                          <img
                            src={iconMotoAzul}
                            className="acting-icons"
                            srcSet={`${iconMotoAzul2x} 2x, ${iconMotoAzul3x} 3x`}
                            alt="Icono car"
                            style={{ width: '36px', height: '30px' }}
                          />
                        )
                      ) : (
                        <img
                          src={iconMotoGris}
                          className="acting-icons"
                          srcSet={`${iconMotoGris2x} 2x, ${iconMotoGris3x} 3x`}
                          alt="Icono car"
                          style={{ width: '36px', height: '30px' }}
                        />
                      )}
                    </li>
                    <li onClick={() => setTipoVehiculo('C')}>
                      {tipoVehiculo === 'C' ? (
                        usuario?.extracto?.categoria ===
                          'GREEN' ? (
                          <img
                            src={iconCart}
                            className="acting-icons"
                            srcSet={`${iconCart2x} 2x, ${iconCart3x} 3x`}
                            alt="Icono car"
                            style={{ width: '36px', height: '30px' }}
                          />
                        ) : (
                          <img
                            src={iconCartAzul}
                            className="acting-icons"
                            srcSet={`${iconCartAzul2x} 2x, ${iconCartAzul3x} 3x`}
                            alt="Icono car"
                            style={{ width: '36px', height: '30px' }}
                          />
                        )
                      ) : (
                        <img
                          src={iconCartGris}
                          className="acting-icons"
                          srcSet={`${iconCartGris2x} 2x, ${iconCartGris3x} 3x`}
                          alt="Icono car"
                          style={{ width: '36px', height: '30px' }}
                        />
                      )}
                    </li>
                  </ul>
                </Form.Group>

                <Form.Group>
  <Form.Label>Placa</Form.Label>
  <Controller
    name={'placa'}
    control={control}
    rules={{
      required: true,
      pattern: tipoVehiculo === 'C' 
        ? /^[A-Za-z]{3}\d{3}$/ // Formato para carro (ABC123)
        : /^[A-Za-z]{3}\d{2}[A-Za-z]{1}$/ // Formato para moto (ABC12E)
    }}
    defaultValue=""
    render={({ field: { onChange, onBlur, value } }) => (
      <Form.Control
        type="text"
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value.toUpperCase().replace(/[^A-Za-z0-9]/g, ''))} // Eliminar caracteres no válidos
        value={value}
        maxLength={6}
        placeholder="Ingrese la placa del vehículo"
      />
    )}
  />
  {/* Solo mostrar el error si hay un error en el campo de placa */}
  {errors['placa'] && errors['placa'].type === 'required' && (
    <Form.Text id="passwordHelpBlock" className="error">
      La placa es obligatoria
    </Form.Text>
  )}
  {errors['placa'] && errors['placa'].type === 'pattern' && tipoVehiculo === 'C' && (
    <Form.Text id="passwordHelpBlock" className="error">
      Formato inválido para carro. Ejemplo: ABC123
    </Form.Text>
  )}
  {errors['placa'] && errors['placa'].type === 'pattern' && tipoVehiculo !== 'C' && (
    <Form.Text id="passwordHelpBlock" className="error">
      Formato inválido para moto. Ejemplo: ABC12E
    </Form.Text>
  )}
</Form.Group>

                <Form.Group >
                  <Form.Label>Marca</Form.Label>
                  <Controller
                    name="marca"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          onChange(e.target.value);
                          setMarcaSelect(
                            e.target.options[e.target.options.selectedIndex].id
                          );
                        }}
                        value={value}
                        placeholder="Ingresa la marca de tu vehículo"
                      >
                        <option>Selecciona la marca de tu vehículo</option>
                        {tipoVehiculo === 'C'
                          ? marcas.map((item) => (
                            <option key={item.id} value={item.Nombre} id={item.id}>
                              {item.Nombre}
                            </option>
                          ))
                          : motosMarcas.map((item) => (
                            <option key={item.id} value={item.Nombre} id={item.id}>
                              {item.Nombre}
                            </option>
                          ))}
                        <option value="Otra">Otra</option>
                      </Form.Control>
                    )}
                  />
                  {errors.marca && (
                    <Form.Text id="passwordHelpBlock" className="error">
                      La marca es obligatoria
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group >
                  <Form.Label>Modelo</Form.Label>
                  <Controller
                    name="modelo"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Form.Control
                        as="select"
                        onChange={onChange}
                        value={value}
                        placeholder="Elige una opción"
                      >
                        <option>Elige un año</option>
                        {añosDisponibles.map((año) => (
                          <option key={año} value={año}>
                            {año}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  />
                  {errors.modelo && (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El modelo es obligatorio
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group >
                  <Form.Label>Línea</Form.Label>
                  <Controller
                    name="linea"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Form.Control
                        type="text"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Ingresa la línea o referencia"
                      />
                    )}
                  />
                  {errors.linea && (
                    <Form.Text id="passwordHelpBlock" className="error">
                      La línea es obligatoria
                    </Form.Text>
                  )}
                </Form.Group>
              </>
            )}


            {usuario?.vehiculo.map((item, index) => (
              <div key={item.index}>
                {(!item.modelo ||
                  item.modelo.length === 0 ||
                  !item.marca ||
                  item.marca.length === 0 ||
                  !item.linea ||
                  item.linea.length === 0) && (
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Placa del vehiculo</Form.Label>
                      <div className="control-icon">
                        <Form.Control
                          type="text"
                          value={usuario.vehiculo[index].placa}
                          disabled={true}
                        />
                      </div>
                    </Form.Group>

                  )}

                {item.marca !== null &&
                  item.marca !== 'NULL' &&
                  item.marca.trim() !==
                  '' ? null : (
                  <Form.Group controlId="">
                    <Form.Label>Marca</Form.Label>
                    <Controller
                      name={`marca_${index}`}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={usuario.vehiculo[index].marca}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          as="select"
                          onChange={(value, index) => {
                            onChange(value.target.value);
                            setMarcaSelect(
                              value.target.options[
                                value.target.options.selectedIndex
                              ].id
                            );
                          }}
                          value={value}
                          placeholder="Ingresa la marca de tu vehículo"
                        >
                          <option>Selecciona la marca de tu vehículo</option>
                          {/* Opciones de marca según el tipo de vehículo */}
                          {item.tipoVehiculo === 'C' ||
                            item.tipoVehiculo === '1'
                            ? marcas.map((item, index) => {
                              return (
                                <option value={item.Nombre} id={item.id}>
                                  {item.Nombre}
                                </option>
                              );
                            })
                            : motosMarcas.map((item, subIndex) => {
                              return (
                                <option
                                  key={subIndex}
                                  value={item.Nombre}
                                  id={item.id}
                                >
                                  {item.Nombre}
                                </option>
                              );
                            })}
                          <option value="Otra">Otra</option>
                        </Form.Control>
                      )}
                    />
                    {errors[`marca_${index}`] && (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El campo es obligatorio
                      </Form.Text>
                    )}
                  </Form.Group>
                )}

                {item.modelo &&
                  item.modelo.length >
                  0 /* Si tiene información entonces no muestre nada */ ? null : (
                  <Form.Group controlId="">
                    <Form.Label>Modelo </Form.Label>
                    <Controller
                      name={`modelo_${index}`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div className="control-icon">
                          <Form.Control
                            as="select"
                            onChange={onChange}
                            value={value}
                            placeholder="Elige una opción"
                          >
                            <option>Elige un año</option>
                            {añosDisponibles.map((año) => (
                              <option key={año} value={año}>
                                {año}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      )}
                    />
                    {errors[`modelo_${index}`] && (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El campo es obligatorio
                      </Form.Text>
                    )}
                  </Form.Group>
                )}

                {item.linea &&
                  item.linea.length >
                  0 /* Si tiene información entonces no muestre nada */ ? null : (
                  <Form.Group controlId="">
                    <Form.Label>Línea</Form.Label>
                    <Controller
                      name={`linea_${index}`}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="text"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Ingrese la línea o referencia"
                        />
                      )}
                    />
                    {errors[`linea_${index}`] && (
                      <Form.Text id="passwordHelpBlock" className="error">
                        La línea es obligatoria
                      </Form.Text>
                    )}
                  </Form.Group>
                )}
              </div>
            ))}
            <div className="d-flex justify-content-center">
              {showLoading ? (
                <div className="container-sniper"></div>
              ) : (
                <Button type="submit" variant="outline-success">
                  Guardar
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompleteData;