const generateNumberCase = async () => {
  let code = "";
  const numbers = "12345678909876543210";

  for (let i = 0; i < 8; i++) {
    if (i % 3 === 0) {
      let char = Math.floor(Math.random() * numbers.length);
      code += numbers.charAt(char);
    } else if (i % 3 === 1) {
      let char = Math.floor(Math.random() * numbers.length);
      code += numbers.charAt(char);
    } else {
      let char = Math.floor(Math.random() * numbers.length);
      code += numbers.charAt(char);
    }
  }

  return code;
};

export default generateNumberCase;
