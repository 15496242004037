import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { BsEye } from 'react-icons/bs';
import Files from './files';
import { URL_EMPLOYEE } from '../../constans/servidor';
import AuthContex from '../../context/autenticacion/authContex';

const ModalReplace = ({ replace, onreplace, user }) => {
  const authContext = useContext(AuthContex);
  const { cerrarSesion } = authContext;
  const [showPass, setShowPass] = useState(false);
  const [archivos, setArchivos] = useState();
  const [Key, setKey] = useState('');
  const [name, setName] = useState('');

  const editPdf = async (e) => {
    e.preventDefault();
    const part = Key.split('/');
    let fileName = '';

    if (part.length > 2) {
      const index = Math.floor(part.length / 2);
      fileName = part[index];
    }

    let typeUser = localStorage.getItem('typeUser');
    let token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const body = {
      userType: typeUser,
      type: fileName,
      Key: Key,
    };

    if(name){
      try {
        const result = await axios.post(
          URL_EMPLOYEE + `/Suppliers/editPdf`,
          body,
          {
            headers: headers,
          }
        );
        if (result.data.status === true) {
          const url = result.data.url;
  
          const fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsArrayBuffer(name[0]);
          });
  
          let blobData = new Blob([fileContent], {
            type: 'application/pdf',
          });
  
          await axios.put(url, blobData, {
            headers: {
              'Content-Type': 'application/pdf',
            }
          });
          toast.success(`Archivo actualizado con éxito!`, 'success');
          onreplace(false);
        } else {
          if(result.data.message === 'Hubo un error al consultar el cliente, jwt expired'){
            toast.error("Porfavor inicie sesion nuevamente su token expiro", "danger");
            cerrarSesion();
          }else {
            toast.error(result.data.message);
          }
        }
      } catch (error) {
        console.log('Hubo un error al editar el archivo');
        toast.error('Error al editar el archivo', 'danger');
      }
    } else {
      toast.error('Se requiere el archivo a reemplazar');
    }
  };

  return (
    <>
      <Modal
        size="md"
        show={replace}
        onHide={() => onreplace(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Reemplaza documentos aquí</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {user && (
            <Form className="conten-login" onSubmit={editPdf}>
              <>
                <p className="text-replace  mbottom-20">
                  Empresa: {user.user.Nombre}{' '}
                </p>
                <p className="text-replace  mbottom-20">
                  Nit: {user.user.NIT}{' '}
                </p>
              </>
              {user?.dataFiles?.map((file) => {
                return (
                  <Tab.Pane eventKey="second" key={file.Key}>
                    <Table className="tabla-retention">
                      <thead>
                        <tr>
                          <th>Documento</th>
                          <th>Ver</th>
                          <th>Reemplazar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{file.nameFile}</td>
                          <td>
                            {' '}
                            <a href={file.url} target="_blank">
                              {' '}
                              <BsEye size={22} />{' '}
                            </a>{' '}
                          </td>
                          <td>
                            <Form.Group>
                              <Files
                                className="container-file"
                                archivo={archivos}
                                value={name}
                                onChange={(e) => setName(e)}
                                setName={setName}
                                isNameEmpty={name}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                      <div className="text-center">
                      <Button
                        variant="primary"
                        className="btn-borde-verde ml-3"
                        size="sm"
                        type="submit"
                        onClick={() => setKey(file.Key)}
                      >
                        Guardar
                      </Button>
                    </div>
                    </Table>
                   
                  </Tab.Pane>
                );
              })}
              
              <div className="text-center">
                <Button
                  onClick={() => onreplace(false)}
                  variant="link"
                  className="btn-enlace-gris"
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showPass}
        onHide={() => setShowPass(false)}
        className="be-modal"
      ></Modal>
    </>
  );
};

export default ModalReplace;