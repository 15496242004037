import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import TagManager from 'react-gtm-module';

import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
// import { Document } from 'react-pdf';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterComponen from '../../components/footer';
import { URL_AWS, URL_SERVICIO } from '../../constans/servidor';

import * as datajson from '../../constans/json';
import * as ROUTES from '../../constans/rutas';

import banner from '../../asses/img/aniversario.png';
import bannerm from '../../asses/img/aniversariom.png';

import logoAniversario from '../../asses/img/logoAniversario.png';
import facebook from '../../asses/img/face.png';
import instagram from '../../asses/img/inst.png';
import linkedin from '../../asses/img/linkedin.png';
import beparking from '../../asses/img/beparking.png';

const Aniversario = () => {
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-PZ3RKWZ',
    });
    analytic.logEvent('page_view', { page: `Landing ayuda anyversario` });
    userData();
  }, []);
  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const userData = async () => {
    setLoading(true);
    const rest = await axios('https://ipapi.co/json/');
    setDataUser(rest.data);
    setLoading(false);
  };
  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      {windowWidth <= 575 ? (
        <>
          <img className="d-block w-100 mbottom-40" src={bannerm} />
        </>
      ) : (
        <img className="d-block w-100 mbottom-40" src={banner} />
      )}

      <Container className=" mtop-40 ">
        <Row className="justify-content-center  mtop-40 ">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="centrado texto-black">#Parking45años</h3>
            <h1 className="texto-aniversario">
              <b>
                Estamos de aniversario y <br /> por eso queremos darte <br />{' '}
                <span
                  style={{
                    fontSize: '38px',
                    color: '#90d400',
                    fontFamily: 'Conv_Gotham-Black',
                  }}
                >
                  6 meses gratis*{' '}
                </span>{' '}
                de parqueadero
              </b>
            </h1>
          </Col>
        </Row>
      </Container>
      {windowWidth <= 575 ? (
        <div className=" mtop-40  division-aniversario_m">
          <h1 className="parrafo-aniversario_m">
            45 años cerca de ti, cuidando lo que valoras
          </h1>
        </div>
      ) : (
        <div className=" mtop-40  division-aniversario">
          <h1 className="parrafo-aniversario">
            45 años cerca de ti, cuidando lo que valoras
          </h1>
        </div>
      )}
      <Container className="mtop-20 mbottom-40">
        <Row
          className="justify-content-center  "
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {windowWidth <= 575 ? (
            <>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="mbottom-20 colum"
              >
                <div className="circle_verde_m">
                  <h2>Bogotá</h2>
                  <h1>10</h1>
                  <h4 style={{ fontSize: '15px' }}>Tarjetas VIP</h4>
                  <h6 style={{ fontSize: '15px' }}>
                    (Parquedero gratis* por 6 meses)
                  </h6>
                  <a
                    href="http://www.parking.net.co/#/beparking/registro"
                    target="_blanck"
                  >
                    Registro a <img src={beparking} alt="facebok parking" />
                  </a>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="mbottom-20 colum"
              >
                <div className="circle_azul_m">
                  <h2>Otras Ciudades</h2>
                  <h1>5</h1>
                  <h4 style={{ fontSize: '15px' }}>Tarjetas VIP</h4>
                  <h6 style={{ fontSize: '15px' }}>
                    (Parquedero gratis* por 6 meses)
                  </h6>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc5FRzQgwpdMMYg13GZvAYj9Kk9CX5KBpualObBA8FLxShlYA/viewform"
                    target="_blanck"
                  >
                    Registra tus Datos
                  </a>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="mbottom-40 colum"
              >
                <div className="circle_verde">
                  <h2>Bogotá</h2>
                  <h1>10</h1>
                  <h4 style={{ fontSize: '20px' }}>Tarjetas VIP</h4>
                  <h6 style={{ fontSize: '20px' }}>
                    (Parquedero gratis* por 6 meses)
                  </h6>
                  <a
                    href="http://www.parking.net.co/#/beparking/registro"
                    target="_blanck"
                  >
                    Registro <img src={beparking} alt="facebok parking" />
                  </a>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="mbottom-40 colum"
              >
                <div className="circle_azul">
                  <h2>Otras Ciudades</h2>
                  <h1>5</h1>
                  <h4 style={{ fontSize: '20px' }}>Tarjetas VIP</h4>
                  <h6 style={{ fontSize: '20px' }}>
                    (Parquedero gratis* por 6 meses)
                  </h6>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc5FRzQgwpdMMYg13GZvAYj9Kk9CX5KBpualObBA8FLxShlYA/viewform"
                    target="_blanck"
                  >
                    Registra tus Datos
                  </a>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row className="justify-content-center mtop-40 ">
          <Col xs={12} sm={12} md={12} lg={11} xl={11}>
            <h1 className="texto-aniversario-puntos">
              Adicionalmente podrás
              <br /> acumular
              <span
                style={{
                  fontSize: '35px',
                  color: '#008b9',
                  fontFamily: 'Conv_Gotham-Black',
                }}
              >
                {' '}
                doble y triple{' '}
              </span>{' '}
              puntaje <br />{' '}
              <span
                style={{
                  fontSize: '35px',
                  color: '#90d400',
                  fontFamily: 'Conv_Gotham-Black',
                }}
              >
                be parking
              </span>{' '}
              según tu categoría!
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center mtop-40 ">
          <Col xs={10} sm={10} md={4} lg={4} xl={4} className="puntos">
            <h2>Green</h2>
          </Col>
        </Row>
        <Row className="justify-content-center  ">
          <Col xs={10} sm={12} md={4} lg={4} xl={4} className="puntos_verde">
            <h2>$100 = 2 puntos</h2>
          </Col>
        </Row>
        <Row className="justify-content-center mtop-40 ">
          <Col xs={10} sm={10} md={4} lg={4} xl={4} className="puntos">
            <h2>Blue</h2>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col xs={10} sm={12} md={4} lg={4} xl={4} className="puntos_azul">
            <h2>$100 = 3 puntos</h2>
          </Col>
        </Row>
        <Row
          className="justify-content-center mtop-50  mbottom-40"
          style={{ marginTop: '50px' }}
        >
          <Col
            xs={10}
            sm={10}
            md={12}
            lg={6}
            xl={6}
            className=" colum2"
            style={{ marginTop: '50px' }}
          >
            <h1>Terminos y Condiciones</h1>
          </Col>
        </Row>
        <Row className="justify-content-center mtop-40 ">
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={12}
            xl={12}
            className=" mbottom-40 colum3"
          >
            <div>
              <h5>Válido del 15 de Junio al 30 de Julio de 2023</h5>
              <p>
                Los 15 primeros clientes que cumplan con estos requisitos podrán
                llevarse las tarjetas. <br />( 10 premios en Bogotá, 5 en el
                resto de ciudades)
              </p>
              <h6>¿Cómo participar?</h6>
              <ul>
                <li>
                  Debes seguir nuestras redes sociales en (Instagram y
                  Facebook).
                </li>
                <li>
                  Debes estar registrado en el programa de puntos{' '}
                  <a
                    className="enlace-instagram"
                    target="_blank"
                    href="http://www.parking.net.co/#/beparking/registro"
                  >
                    be parking.
                  </a>
                </li>
                <li>
                  Comentar este post :{' '}
                  <a
                    className="enlace-instagram"
                    target="_blank"
                    href="https://instagram.com/p/Ct9n2YUP7tw/"
                  >
                    https://instagram.com/p/Ct9n2YUP7tw/
                  </a>{' '}
                  con una experiencia en Parking con el hashtag #Parking45Años
                </li>
                <li>
                  Parquear un mínimo de 5 veces hasta el 30 julio en cualquiera
                  de nuestros puntos de estacionamiento.
                </li>
              </ul>
              <h6>Términos y condiciones:</h6>
              <ul>
                <li>
                  Para la ciudad de Bogotá debes estar afiliado a be parking.
                </li>
                <li>
                  Para las demás ciudades, debes parquear un minimo de 4 veces
                  hasta el 30 de julio.
                </li>
                <li>No acumulable con otras promociones o campañas.</li>
                <li>
                  No aplica para el producto mensualidades en ninguna ciudad.
                </li>
                <li>
                  No aplica en los puntos de servicio Mederi Universitario
                  Mayor, Mederi Barrios Unidos, Nuevo Mederi, Wework 8111,
                  Salvio 93, Clinica Santa Fe, Santafe Urgencias Skidata, Hotel
                  Hyatt y Piscilago.
                </li>
                <li>
                  Para utilizar la tarjeta Vip en los puntos Fundación Santa fe
                  y Urgencias, debe primero validar la tarjeta pasando siempre
                  por la caja en el momento de la salida, de esta manera podrá
                  hacer uso de la misma.
                </li>
              </ul>
              <p>
                Los ganadores se darán a conocer por nuestras redes sociales y
                página web el día 10 de Agosto del 2023, y a quienes
                contactaremos para la entrega de las tarjetas VIP.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mtop-40 ">
          <Col xs={10} sm={10} md={4} lg={4} xl={4} className=" colum4 mtop-40">
            <a
              target="_blank"
              href="https://www.facebook.com/Parking-110519723977682/"
            >
              <img src={facebook} alt="facebok parking" />
            </a>
            <h8>Parking</h8>
          </Col>
          <Col xs={10} sm={10} md={4} lg={4} xl={4} className="colum4 mtop-40">
            <a
              target="_blank"
              href="https://www.instagram.com/parking_oficial/?hl=es-la"
            >
              <img src={instagram} alt="instagram parking" />
            </a>
            <h8>Parking_oficial</h8>
          </Col>
          <Col xs={10} sm={10} md={4} lg={4} xl={4} className="colum4 mtop-40">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/parking-international/"
            >
              <img src={linkedin} alt="instagram parking" />
            </a>
            <h8>Parking International S.A.S</h8>
          </Col>
        </Row>
      </Container>
      <FooterComponen />
    </>
  );
};
export default Aniversario;
