import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

import BannerParkingMedia from '../../asses/img/Banner Parking Media.png';
import icono1 from '../../asses/img/Icono 01.png';
import icono2 from '../../asses/img/Icono 02.png';
import icono3 from '../../asses/img/Icono 03.png';
import BannerFooter from '../../asses/img/Banner Parking al Volante-02.png';

import FooterComponen from '../../components/footer';

export default function ParkingMedia() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="row-media">
          <img
            style={{ width: '100%' }}
            src={BannerParkingMedia}
            alt="Banner Parking Media"
          />
          <h2 style={{ color: '#90d400', fontWeight: 'bold' }}>
            ¿Qué es Parking Media?
          </h2>
          <p style={{ fontSize: '19px', fontWeight: 700, color: '#929292' }}>
            Es la unidad de alianzas y publicidad de Parking International, la
            cual cocrea con marcas anunciantes, agencias BTL, agencias de medios
            y productoras, diferentes estrategias de visibilidad y contenido
            ¡Dando un uso creativo a los parqueaderos!
          </p>
          <p style={{ fontSize: '19px', fontWeight: 700, color: '#929292' }}>
            Más de{' '}
            <strong
              style={{ fontSize: '19px', fontWeight: 'bold', color: '#929292' }}
            >
              125 mil personas
            </strong>{' '}
            en ecosistema digital y más de{' '}
            <strong
              style={{ fontSize: '19px', fontWeight: 'bold', color: '#929292' }}
            >
              3.4 millones de personas al mes
            </strong>{' '}
            en puntos de servicio
          </p>
          <div className="container-parking-media">
  <div className="content-media">
    <a href="https://image.notification.parking.net.co/lib/fe3011747364047f731478/m/1/af5ace93-d38d-4a24-8c9e-e45ed5032d9c.pdf" target="_blank" rel="noopener noreferrer">
      <img src={icono1} 
        style={{
          width: '215px',
          height: 'auto',
          transition: 'transform 0.3s ease, opacity 0.3s ease',
        }}    
        onMouseEnter={e => e.target.style.transform = 'scale(1.1)'}
        onMouseLeave={e => e.target.style.transform = 'scale(1)'}
      />
    </a>
    <strong
      style={{
        fontSize: '19px',
        fontWeight: 'bold',
        color: '#90d400',
        marginTop: '6px',
        cursor: 'pointer'
      }}
      onClick={() => window.open('https://image.notification.parking.net.co/lib/fe3011747364047f731478/m/1/af5ace93-d38d-4a24-8c9e-e45ed5032d9c.pdf', '_blank')}
    >
      Interacciones digitales
    </strong>
  </div>
  <div className="content-media">
    <a href="https://image.notification.parking.net.co/lib/fe3011747364047f731478/m/1/274b235c-61c6-4a0f-b46f-ad20a430ae8e.pdf" target="_blank" rel="noopener noreferrer">
      <img src={icono2} 
        style={{
          width: '395px',
          height: 'auto',
          transition: 'transform 0.3s ease, opacity 0.3s ease',
        }} 
        onMouseEnter={e => e.target.style.transform = 'scale(1.1)'}
        onMouseLeave={e => e.target.style.transform = 'scale(1)'}
      />
    </a>
    <strong
      style={{
        fontSize: '19px',
        fontWeight: 'bold',
        color: '#90d400',
        cursor: 'pointer',
      }}
      onClick={() => window.open('https://image.notification.parking.net.co/lib/fe3011747364047f731478/m/1/274b235c-61c6-4a0f-b46f-ad20a430ae8e.pdf', '_blank')}
    >
      Puntos de servicio
    </strong>
  </div>
  <div className="content-media1">
    <a href="https://image.notification.parking.net.co/lib/fe3011747364047f731478/m/1/f072a6e4-83a1-4a7a-902b-e57be339bc03.pdf" target="_blank" rel="noopener noreferrer">
      <img src={icono3} 
        style={{
          width: '200px',
          height: 'auto',
          marginLeft: '-40px',
          transition: 'transform 0.3s ease, opacity 0.3s ease',
        }} 
        onMouseEnter={e => e.target.style.transform = 'scale(1.1)'}
        onMouseLeave={e => e.target.style.transform = 'scale(1)'}
      />
    </a>
    <strong
      style={{
        fontSize: '19px',
        fontWeight: 'bold',
        color: '#90d400',
        marginTop: '-5px',
        marginLeft: '-55px', 
        cursor: 'pointer'
      }}
      onClick={() => window.open('https://image.notification.parking.net.co/lib/fe3011747364047f731478/m/1/f072a6e4-83a1-4a7a-902b-e57be339bc03.pdf', '_blank')}
    >
      Alianzas y descuentos especiales
    </strong>
  </div>
</div>


          <div className="container-parking-media-2">
            <p
              style={{
                fontSize: '19px',
                fontWeight: 'bold',
                color: '#929292',
                textAlign: 'center',
                marginTop: '15px',
              }}
            >
              Sabemos{' '}
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#90d400',
                }}
              >
                cómo, cuándo y dónde
              </strong>{' '}
              se mueve tu audiencia{' '}
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#929292',
                }}
              >
                ¡Segmenta como necesites!
              </strong>
            </p>
            <a href="https://youtu.be/lxQjudb10VQ?si=G9M6s7PQWoWlQjme" target="_blank" rel="noopener noreferrer">
  <img
    style={{
      width: '100%',
      marginTop: '-12px',
      transition: 'transform 0.3s ease, opacity 0.3s ease',
    }}
    onMouseEnter={e => e.target.style.transform = 'scale(1.03)'}
    onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    src={BannerFooter}
  />
</a>

          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              paddingTop: '2rem',
              marginTop: '-18px',
            }}
          >
            <p
              style={{
                fontSize: '19px',
                color: '#90d400',
              }}
            >
              Conecta{' '}
              <strong
                style={{
                  fontWeight: 'bold',
                  fontSize: '19px',
                  color: '#90d400',
                }}
              >
                generando emociones
              </strong>
            </p>
            <p
  style={{
    fontSize: '19px',
    color: '#80c200',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '10px',  
  }}
>
<div
  style={{
    marginBottom: '-5px',
  }}
>
  <a style={{ color: '#80c200', fontWeight: '900', fontSize: '20px' }} href="tel:312 4507513">
    Cel:
  </a>{' '}
  <a
    style={{
      color: '#80c200',
      fontWeight: '900',
      fontSize: '20px',
    }}
    href="tel:312 4507513"
  >
    312 4507513
  </a>
</div>
<div
  style={{
    borderBottom: '2px solid #80c200', 
  }}
>
  <a
    style={{
      color: '#80c200',
      fontWeight: '900',
      fontSize: '20px',
    }}
    href="mailto:liz.cervera@parking.net.co"
  >
    <span>liz.cervera@parking.net.co</span>
  </a>
</div>

</p>

          </div>
        </Row>
      </Container>
      <FooterComponen />
    </>
  );
}
