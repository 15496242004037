import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import * as ROUTES from "../../constans/rutas";


import { toast } from 'react-toastify';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import AuthContex from '../../context/autenticacion/authContex';

const ForgotPass = ({ show, onOpen }) => {
  const authContex = useContext(AuthContex);
  const { usuario, loginBeparking } = authContex;
  const [showPass, setShowPass] = useState(false);
  const [identificacion, setIdentificacion] = useState(''); //800675511
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!identificacion || !password || identificacion.trim() === '' || password.trim() === '') {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    if (password.length < 3) {
      toast.error('La contraseña debe tener minimo 3 caracteres');
      return;
    }

    loginBeparking({ identificacion, password });
  };


  const olvidoPass = async (e, data) => {
    e.preventDefault();
    try {
      if(!identificacion){
        return toast.error("Ingrese su número de identificación");
      }
      const result = await axios.get(
        `${URL_AWS}/beParking/olvidoPasswordWEB/${identificacion}`
      );
      console.log(result.data.mensaje);
      toast.success(result.data.mensaje);
      setIdentificacion('');
      setShowPass(false);
      onOpen(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.mensaje);
      setIdentificacion('');
    }
  };

  useEffect(() => {
    setShowPass(false)
    // history.push(ROUTES.BEPARKING);
    setIdentificacion(usuario?.cedula);
  }, [usuario]);

  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={() => onOpen(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-azul">
            ¿Olvidaste tu contraseña?
            <small>¡Te ayudaremos a recuperar tu cuenta!</small>
            <Link
              target="_blank"
              className="enlace-ayudaC"
              to={ROUTES.BECONTRASENAG}
            >
              Guia para recuperar contraseña
            </Link>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="conten-login" onSubmit={olvidoPass}>
            <p className="text-center">
              Ingresa el número de tu identificación para ayudarte con la
              recuperación de tu cuenta
            </p>
            <Form.Group controlId="formBasicEmail" className="control-numero">
              <Form.Control
                type="number"
                value={identificacion}
                placeholder="Ingresa tu número de identificación"
                onChange={(e) => setIdentificacion(e.target.value)}
              />
            </Form.Group>

            <div className="text-center">
              <Button className="btn-borde-verde azul" size="sm" type="submit">
                Recupera tu contraseña
              </Button>
            </div>
            <div className="text-center">
              <Button
                onClick={() => {
                  setShowPass(false);
                  onOpen(false);
                }}
                variant="link"
                className="btn-enlace-gris"
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPass;