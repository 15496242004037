import React from 'react';

function PDFViewer({imagen, title}) {
  return (
    <div className='pdf-view'>
      <iframe
        title={title}
        placeholder={title}
        className='pdf'
        src={imagen + "#toolbar=0"}
        width="100%"
        height="100px"
      >
        El visor de PDF no está disponible en tu navegador. Puedes <a href={imagen}>descargar el PDF</a> en su lugar.
      </iframe>
    </div>
  );
}

export default PDFViewer;
