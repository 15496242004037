import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import * as ROUTES from "../../constans/rutas";
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';
import AuthContex from '../../context/autenticacion/authContex';
import { Tab } from 'react-bootstrap';
const LoginComponent = ({ open, onOpen }) => {
  const [tab, setTab] = useState(1);
  const authContex = useContext(AuthContex);
  const { usuario, loginBeparking } = authContex;


  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={() => onOpen(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>¿Necesitas ayuda?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>Asegúrate de que el número de teléfono o el email proporcionado sea correcto.</li>
            <li>Intenta solicitar un nuevo código de verificación.</li>
            <li>Si el problema persiste, contacta a nuestro equipo para recibir asistencia.</li>
            <li>
              Si sigue experimentando problemas, una opción es recargar la página;
              sin embargo, para una solución más completa, se recomienda borrar la caché
              y los datos de navegación en su dispositivo.
            </li>
          </ol>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default LoginComponent;
