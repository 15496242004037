import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { IoMdDownload } from 'react-icons/io';
 
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContex from '../../context/autenticacion/authContex';
import { URL_EMPLOYEE } from '../../constans/servidor';
 
import { AiFillEdit } from 'react-icons/ai';
import { decodeToken } from '../../utils/decodeToken';
const ProfileUser = (props, onOpen) => {
  const yearRef = useRef(null);
  const typeFileRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;
  const authContext = useContext(AuthContex);
  const { mensaje, autenticado, ProfileUser, usuario, cerrarSesion } =
    authContext;
  const [identificacion, setIdentificacion] = useState('');
  const [typeFile, setTypeFile] = useState('');
  const [year, setYear] = useState('');
  const [data, setData] = useState('');
 
  useEffect(() => {
    if (autenticado === null) {
      return;
    }
 
    if (autenticado) {
      if (usuario) {
        setTimeout(() => {
          setLoading(false);
          setIdentificacion(usuario?.NIT);
        }, 2000);
      }
      props.history.push(`/retentionCertificates/profile`);
    } else {
      props.history.push(`/retentionCertificates/createUser`);
    }
    if (mensaje) {
      console.log(mensaje);
      mostrarAlerta(mensaje.msg, 'danger');
    }
  }, [mensaje, autenticado, usuario, props.history]);
 
  useEffect(() => {
    setTimeout(() => {
      setIdentificacion(usuario?.NIT);
      setLoading(false);
    }, 2000);
  }, [usuario]);
 
  const onSubmit = async (e) => {
    e.preventDefault();
 
    // validar que no hayan campos vacios
    if (year.trim() === '' || typeFile.trim() === '') {
      mostrarAlerta('Todos los campos son obligatorios', 'danger');
      return;
    }
 
    try {
      if (year && typeFile) {
        let token = localStorage.getItem('token');
        let decodedToken = await decodeToken(token);
        const headers = {
          Authorization: `Bearer ${token}`,
        };
 
        const data = {
          Nit: decodedToken.payload.id,
          year: year,
          type: typeFile,
        };
 
        console.log(data);
        const response = await axios.post(
          URL_EMPLOYEE + `/Suppliers/downloadPdf`,
          data,
          { headers: headers }
        );
 
        console.log(response.data);
 
        if (response.data.statusCode === 200) {
          setYear('');
          setTypeFile('')
          yearRef.current.value = 'Elige un año';
          typeFileRef.current.value = 'Elige una opción';
 
          toast.success(response.data.message, 'success');
          setData(response.data.data);
        } else {
          yearRef.current.value = 'Elige un año';
          typeFileRef.current.value = 'Elige una opción';
          setData('');
          if (
            response.data.message ===
            'Hubo un error al consultar el cliente, jwt expired'
          ) {
            toast.error(
              'Porfavor inicie sesion nuevamente su token expiro',
              'danger'
            );
            cerrarSesion();
          } else {
            toast.error(response.data.message);
          }
        }
      } else {
        toast.error(
          'Se requiere el año y el tipo de documento a descargar!',
          'danger'
        );
      }
    } catch (error) {
      console.log(error);
      toast.error('Hubo un error al traer los documentos', 'danger');
    }
  };
 
  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        {alerta ? (
          <Alert variant={alerta.categoria} transition={true}>
            {alerta.msg}
          </Alert>
        ) : null}
 
        <Row className="justify-content-center mtop-40">
          {usuario && (
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
              <Jumbotron className="retention">
                <div className="form-usuario">
                  <h3 className="text-retention  mbottom-20">
                    Bienvenid@ {usuario.Nombre}, al portal de certificados
                    tributarios{' '}
                  </h3>
 
                  <Form onSubmit={onSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Tipo de certificado</Form.Label>
                      <Form.Control
                        className="form-retention"
                        as="select"
                        type="text"
                        ref={typeFileRef}
                        onChange={(e) => setTypeFile(e.target.value)}
                      >
                        <option>Elige una opción</option>
                        <option>Certificado de retención en la fuente</option>
                        <option>Certificado de retención ICA</option>
                        <option>Certificado de retención IVA</option>
                      </Form.Control>
                    </Form.Group>
 
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Año</Form.Label>
                      <Form.Control
                        className="form-retention"
                        as="select"
                        type="number"
                        ref={yearRef}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        <option>Elige un año</option>
                        <option>2022</option>
                        <option>2023</option>
                        <option>2024</option>
                      </Form.Control>
                    </Form.Group>
 
                    <Col className="buscador ">
                      <Button className="btn-buscar" size="sm" type="submit">
                        Buscar
                      </Button>
                    </Col>
                    <br />
                    {data ? (
                      <Tab.Pane eventKey="second">
                        {data?.map((file) => {
                          return (
                            <Table className="tabla-retention">
                              <tbody>
                                <tr>
                                  <td> {file.nameFile} </td>
                                  <td>
                                    {' '}
                                    <a href={file.url} download>
                                      {' '}
                                      <IoMdDownload size={22} />{' '}
                                    </a>{' '}
                                  </td>
 
                                  <td>
                                    {usuario?.cms ? (
                                      <Button
                                        onClick={() => {
                                          // setEditAction('comunicado');
                                          //setEditServicios(true);
                                        }}
                                        variant="outline-success"
                                        className="mtop-20 btn_banner"
                                      >
                                        <AiFillEdit /> Reemplazar
                                      </Button>
                                    ) : null}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          );
                        })}
                      </Tab.Pane>
                    ) : null}
                  </Form>
                  <br />
                  <br />
                  <br />
                  <br />
                  <p className="text-info-retention">
                    Si encuentra alguna inconformidad o diferencia o requiere
                    certificado de años anteriores a 2021 por favor contáctenos
                    al correo
                    <a href="mailto:contabilidad.impuestos@parking.net.co">
                      {' '}
                      contabilidad.impuestos@parking.net.co{' '}
                    </a>
                  </p>
                </div>
              </Jumbotron>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
 
export default ProfileUser;