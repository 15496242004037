import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterComponen from '../../components/footer';
import facturacionBanner from '../../asses/img/factura-electronica-banner.png';
import { URL_AWS, URL_SERVICIO } from '../../constans/servidor';
import politicasFE from '../../asses/pdfs/GM-L-01 Política Facturación electrónica.pdf';
import iconDownload from '../../asses/img/Vector.png';

import * as datajson from '../../constans/json';
import * as ROUTES from '../../constans/rutas';

const CreateClientInvoiceElectronic = () => {
  const { phone, identificacio, tipo } = useParams();
  const reloadPage = () => {
    window.location.reload();
  };

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [acepto, setAcepto] = useState(false);
  const [dataUser, setDataUser] = useState();
  const [dataTipo, setDataTipo] = useState('');
  const [regimenValue, setRegimenValue] = useState('1');
  const [direccion, setDireccion] = useState();
  const [showDownload, setShowDownload] = useState(false);

  const [vertipo, setVertipo] = useState(0);
  const [optionsIdentificacion, setOptionsIdentificacion] = useState([]);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const selectionPersona = (val) => {
    setShowDownload(true);
    setVertipo(parseInt(val.value));
    if (val.value === '1') {
      setOptionsIdentificacion([{ value: '5', label: 'NIT' }]);
    }
    if (val.value === '2') {
      setOptionsIdentificacion([
        { value: '1', label: 'Cedula Ciudadanía - CC' },
        { value: '2', label: 'Cedula Extranjería - CE' },
      ]);
    }
  };
  const tipdireccion = (val) => {
    setDireccion(parseInt(val.value));
  };
  const onSubmit = async (data) => {
    let parametros;
    setLoadingButton(true);
    if (vertipo === 2) {
      if (direccion == '1') {
        parametros = {
          tipoIdentificacion: data.tipo.value,
          identificacion: data.numero_id,
          nombres: data.nombres,
          apellidos: data.apellidos,
          email: data.email,
          celular: data.celuar ? data.celuar : '5190077',
          ciudad: data.ciudad.label ? data.ciudad : '11001',
          codMunicipio: data.ciudad.value,
          direccion: `${data.calle.value} ${data.callenum}${
            data.calleletra ? data.calleletra.value + ' ' : ''
          }${
            data.calleorientation ? data.calleorientation.value + ' ' : ''
          } # ${data.numnun}${data.numletra ? data.numletra.value : ''} - ${
            data.numnundos
          } ${data.numubicacion ? data.numubicacion.value : ''} ${
            data.dirotros ? data.dirotros : ''
          }`,
          regimen: data.regimen,
          actividadEconomica: data.actividade1,
          aceptaTerminos: dataUser.ip ? dataUser.ip : ' ',
          responsabilidadFiscal: data.responsabilidad,
        };
      } else {
        parametros = {
          tipoIdentificacion: data.tipo.value,
          identificacion: data.numero_id,
          nombres: data.nombres,
          apellidos: data.apellidos,
          email: data.email,
          celular: data.celuar ? data.celuar : '5190077',
          ciudad: data.ciudad.label ? data.ciudad.label : '11001',
          codMunicipio: data.ciudad.value,
          direccion: data.dirrural ? data.dirrural : 'CR 14 89 48 OF 202',
          regimen: data.regimen,
          actividadEconomica: data.actividade1,
          aceptaTerminos: dataUser.ip ? dataUser.ip : ' ',
          responsabilidadFiscal: data.responsabilidad,
        };
      }
      console.log('parametronatural', parametros);
    }
    if (vertipo === 1) {
      const nit = data.nit;
      const digito = data.digito;
      if (nit.length >= 6 && nit.length <= 10 && digito.length < 1) {
        setLoadingButton(false);
        return toast.error('Número de NIT inválido');
      } else {
        if (direccion == '1') {
          parametros = {
            tipoIdentificacion: data.tipo.value,
            numeroNit: `${data.nit}-${data.digito}`,
            razonSocial: data.razonsocial,
            email: data.email,
            celular: data.celuar ? data.celuar : '5190077',
            ciudad: data.ciudad.label ? data.ciudad : '11001',
            codMunicipio: data.ciudad.value,
            direccion: `${data.calle.value} ${data.callenum}${
              data.calleletra ? data.calleletra.value + ' ' : ''
            }${
              data.calleorientation ? data.calleorientation.value + ' ' : ''
            } # ${data.numnun}${data.numletra ? data.numletra.value : ''} - ${
              data.numnundos
            } ${data.numubicacion ? data.numubicacion.value : ''} ${
              data.dirotros ? data.dirotros : ''
            }`,
            regimen: data.regimen,
            actividadEconomica: data.actividade,
            aceptaTerminos: dataUser.ip ? dataUser.ip : ' ',
            responsabilidadFiscal: data.responsabilidad,
          };
        } else {
          parametros = {
            tipoIdentificacion: data.tipo.value,
            numeroNit: `${data.nit}-${data.digito}`,
            razonSocial: data.razonsocial,
            email: data.email,
            celular: data.celuar ? data.celuar : '5190077',
            ciudad: data.ciudad.label ? data.ciudad.label : '11001',
            codMunicipio: data.ciudad.value,
            direccion: data.dirrural ? data.dirrural : 'CR 14 89 48 OF 202',
            regimen: data.regimen,
            actividadEconomica: data.actividade,
            aceptaTerminos: dataUser.ip ? dataUser.ip : ' ',
            responsabilidadFiscal: data.responsabilidad,
          };
        }
      }
      console.log('parametrojuridico', parametros);
    }

    try {
      const resultado = await axios.post(
        vertipo === 1
          ? `${URL_AWS}/proyectoFacturacionElectronica/rest/cliente/crearClienteJuridico`
          : vertipo === 2
          ? `${URL_AWS}/proyectoFacturacionElectronica/rest/cliente/crearClienteNatural`
          : null,
        parametros
      );
      if (resultado.data.estado) {
        toast.success(resultado.data.mensaje);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.error(resultado.data.mensaje);
      }
      reset({
        tipopersona: '',
        tipo: '',
        nit: '',
        digito: '',
        razonsocial: '',
        email: '',
        celuar: '',
        ciudad: '',
        calle: '',
        callenum: '',
        calleletra: '',
        calleorientation: '',
        numnun: '',
        numletra: '',
        numnundos: '',
        numubicacion: '',
        regimen: '',
        actividade: '',
        responsabilidad: '',
        terminos: false,
        nombres: '',
        apellidos: '',
        numero_id: '',
      });
      setLoadingButton(false);
    } catch (error) {
      // console.log(error.response);
      // toast.error(error.response.data.mensaje);
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    setVertipo(0);
    setAcepto(false);
    analytic.logEvent('page_view', { page: `Landing Mensualidades` });

    console.log();
  }, []);

  const userData = async () => {
    const rest = await axios('https://ipapi.co/json/');
    setDataUser(rest.data);
  };

  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <div className="banner-principal">
        <img
          className="d-block w-100"
          src={facturacionBanner}
          alt="Banner facturacion electronica"
        />
      </div>

      <Container>
        <Row
          className="justify-content-center mtop-40 mbottom-40"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <Row className=" mtop-20 mbottom-40">
              <Col
                className="justify-content-center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <p className="text-left  texto-slogan">
                  Si ya cuentas con una factura física - POS y quieres solicitar
                  tú factura electrónica, Ingresa a:
                </p>
                <Link
                  className="btn btn-primary btn-sm"
                  to={ROUTES.LANDINGFACTURACIONVALIDACION}
                >
                  Solicitar Factura Electronica
                </Link>
              </Col>
            </Row> */}

            <h3 className="texto-titulo">
              CREACIÓN DE CLIENTES PARA GENERACIÓN FACTURACIÓN ELECTRÓNICA
            </h3>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mtop-40">
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <div className="container-dowload-pdf">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Tipo de persona</Form.Label>
                      <Controller
                        name="tipopersona"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            options={[
                              { value: '1', label: 'Persona Jurídica' },
                              { value: '2', label: 'Persona Natural' },
                            ]}
                            className="custon-select"
                            classNamePrefix="custon-select"
                            value={value}
                            onChange={(data) => {
                              onChange(data);
                              selectionPersona(data);
                            }}
                          />
                        )}
                      />
                      {errors.tipopersona && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El tipo de documento es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    {showDownload === false ? (
                      <div className="dowload-pdf-politices">
                        <a
                          href={politicasFE}
                          download="GM-L-01 Política Facturación electrónica"
                        >
                          Descarga nuestras políticas{' '}
                          <img src={iconDownload} alt="donwload" />
                        </a>
                      </div>
                    ) : null}
                  </div>
                </Col>

                {vertipo !== 0 ? (
                  <>
                    <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Tipo de identificación</Form.Label>
                        <Controller
                          name="tipo"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              options={optionsIdentificacion}
                              className="custon-select"
                              classNamePrefix="custon-select"
                              onChange={(data) => {
                                onChange(data);
                                setDataTipo(data.value);
                              }}
                              value={value}
                            />
                          )}
                        />
                        {errors.tipo && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            El campo es obligatorio
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    {vertipo === 1 ? (
                      <>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                          <Row>
                            <Col
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              className="pr-1"
                            >
                              <Form.Group
                                controlId="formBasicEmail"
                                className="control-numero"
                              >
                                <Form.Label>Nit número</Form.Label>
                                <Controller
                                  name="nit"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: 'El campo es obligatorio',
                                    },
                                    validate: {
                                      isZero: (value) =>
                                        !/^0+(?:\.0+)?$/.test(value) ||
                                        'Ingrese una idenificación valida',
                                    },
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: 'Caracteres inválidos',
                                    },
                                    minLength: {
                                      value: 7,
                                      message: 'Complete el campo',
                                    },
                                    maxLength: {
                                      value: 10,
                                      message: '10 caracteres máximo',
                                    },
                                  }}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <Form.Control
                                      type="text"
                                      onBlur={onBlur}
                                      onChange={(e) =>
                                        onChange(
                                          e.target.value.replace(/[^\d]/g, '')
                                        )
                                      }
                                      value={value}
                                      maxLength={10}
                                    />
                                  )}
                                />
                                {errors.nit && (
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="error"
                                  >
                                    {errors.nit.message}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              className="pl-0 pr-0 text-center pt-4"
                            >
                              -
                            </Col>
                            <Col
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              className="pl-1"
                            >
                              <Form.Group
                                controlId="formBasicEmail"
                                className="control-numero"
                              >
                                <Form.Label className="nverificacion">
                                  N°. verificación
                                </Form.Label>
                                <Controller
                                  name="digito"
                                  control={control}
                                  rules={{
                                    required: true,
                                    minLength: {
                                      value: 1,
                                      message: 'Complete el campo',
                                    },
                                    maxLength: {
                                      value: 1,
                                      message: '1 caracteres máximo',
                                    },
                                  }}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <Form.Control
                                      type="number"
                                      onBlur={onBlur}
                                      onChange={(value) => onChange(value)}
                                      value={value.slice(0, 1)}
                                      maxLength={1}
                                    />
                                  )}
                                />
                                {errors.digito && (
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="error"
                                  >
                                    El campo es obligatorio
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Razón Social</Form.Label>
                            <Controller
                              name="razonsocial"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'El campo es obligatorio',
                                },
                                pattern: {
                                  value: /^[ÑA-Zña-z0-9,.-\s]+$/,
                                  message: 'Caracteres inválidos',
                                },
                              }}
                              defaultValue=""
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="text"
                                  onBlur={onBlur}
                                  onChange={(value) => onChange(value)}
                                  value={value}
                                />
                              )}
                            />
                            {errors.razonsocial && (
                              <Form.Text
                                id="passwordHelpBlock"
                                className="error"
                              >
                                {errors.razonsocial.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                          <Form.Group
                            controlId="formBasicEmail"
                            className="control-numero"
                          >
                            <Form.Label>Número de Identificación</Form.Label>
                            <Controller
                              name="numero_id"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'El campo es obligatorio',
                                },
                                validate: {
                                  isZero: (value) =>
                                    !/^0+(?:\.0+)?$/.test(value) ||
                                    'Ingrese una idenificación valida',
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: 'Caracteres inválidos',
                                },
                                minLength: {
                                  value: 7,
                                  message: 'Complete el campo',
                                },
                                maxLength: {
                                  value: 10,
                                  message: '10 caracteres máximo',
                                },
                              }}
                              defaultValue=""
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="text"
                                  onBlur={onBlur}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value.replace(/[^\d]/g, '')
                                    )
                                  }
                                  value={value}
                                  maxLength={10}
                                />
                              )}
                            />
                            {errors.numero_id && (
                              <Form.Text
                                id="passwordHelpBlock"
                                className="error"
                              >
                                {errors.numero_id.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Nombres</Form.Label>
                            <Controller
                              name="nombres"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'El campo es obligatorio',
                                },
                                minLength: {
                                  value: 1,
                                  message: 'Complete el campo',
                                },
                                maxLength: {
                                  value: 40,
                                  message: '30 caracteres máximo',
                                },
                                pattern: {
                                  value: /^[A-Za-z\s]+$/,
                                  message: 'Caracteres inválidos',
                                },
                              }}
                              defaultValue=""
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="text"
                                  onBlur={onBlur}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value.replace(/[^a-zA-Z\s]/g, '')
                                    )
                                  }
                                  value={value}
                                  maxLength={30}
                                />
                              )}
                            />
                            {errors.nombres && (
                              <Form.Text
                                id="passwordHelpBlock"
                                className="error"
                              >
                                {errors.nombres.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Apellidos</Form.Label>
                            <Controller
                              name="apellidos"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'El campo es obligatorio',
                                },
                                minLength: {
                                  value: 1,
                                  message: 'Complete el campo',
                                },
                                maxLength: {
                                  value: 40,
                                  message: '40 caracteres máximo',
                                },
                                pattern: {
                                  value: /^[ÑA-Zña-z0-9\s]+$/,
                                  message: 'Caracteres inválidos',
                                },
                              }}
                              defaultValue=""
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="text"
                                  onBlur={onBlur}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value.replace(/[^a-zA-Z\s]/g, '')
                                    )
                                  }
                                  value={value}
                                  maxLength={30}
                                />
                              )}
                            />
                            {errors.apellidos && (
                              <Form.Text
                                id="passwordHelpBlock"
                                className="error"
                              >
                                {errors.apellidos.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Correo electrónico</Form.Label>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'El campo es obligatorio',
                            },
                            pattern: {
                              value:
                                /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                              message: 'Formato incorrecto',
                            },
                            maxLength: {
                              value: 50,
                              message: '50 caracteres máximo',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="text"
                              onBlur={onBlur}
                              placeholder="ejemplo@hotmail.com"
                              onChange={(value) => onChange(value)}
                              value={value}
                            />
                          )}
                        />
                        {errors.email && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            {errors.email.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Celular (opcional)</Form.Label>
                        <Controller
                          name="celuar"
                          control={control}
                          rules={{
                            minLength: {
                              value: 7,
                              message: 'Complete el campo',
                            },
                            maxLength: {
                              value: 10,
                              message: '10 caracteres máximo',
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: 'Caracteres inválidos',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="text"
                              onBlur={onBlur}
                              onChange={(e) =>
                                onChange(e.target.value.replace(/[^\d]/g, ''))
                              }
                              value={value}
                              maxLength={10}
                            />
                          )}
                        />
                        {errors.celuar && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            {errors.celuar.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Ciudad (opcional)</Form.Label>
                        <Controller
                          name="ciudad"
                          control={control}
                          defaultValue={{ value: '11001', label: 'BOGOTÁ D.C' }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              options={datajson.ciudaddes}
                              className="custon-select"
                              classNamePrefix="custon-select"
                              onChange={(data) => onChange(data)}
                              value={value}
                              defaultValue={{
                                value: '11001',
                                label: 'BOGOTÁ D.C',
                              }}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>

                    {vertipo === 2 ? (
                      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Régimen (opcional)</Form.Label>
                          <Controller
                            name="regimen"
                            control={control}
                            // // rules={{ required: true }}
                            defaultValue="1"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                as="select"
                                onChange={(data) => onChange(data)}
                                value={value}
                                placeholder="Elige una opción"
                              >
                                <option value="1">
                                  Régimen simplificado - RS
                                </option>
                                <option value="2">
                                  Gran contribuyente - GC
                                </option>
                                <option value="3">Régimen común - RC</option>
                              </Form.Control>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    ) : null}

                    <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Form.Group>
                        <Form.Label>Dirección (opcional)</Form.Label>
                        <Controller
                          name="dirrural"
                          control={control}
                          rules={
                            direccion == '2'
                              ? {
                                  required: {
                                    value: false,
                                    message: 'El campo es obligatorio',
                                  },
                                }
                              : {
                                  required: {
                                    value: false,
                                    message: 'El campo es obligatorio',
                                  },
                                }
                          }
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="text"
                              onBlur={onBlur}
                              onChange={(value) => onChange(value)}
                              value={value}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>

                    {vertipo === 1 ? (
                      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Régimen (opcional)</Form.Label>
                          <Controller
                            name="regimen"
                            control={control}
                            // // rules={{ required: true }}
                            defaultValue="1"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                as="select"
                                onChange={(data) => onChange(data)}
                                value={value}
                                placeholder="Elige una opción"
                              >
                                <option value="1">
                                  Régimen simplificado - RS
                                </option>
                                <option value="2">
                                  Gran contribuyente - GC
                                </option>
                                <option value="3">Régimen común - RC</option>
                              </Form.Control>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    ) : null}

                    {vertipo === 2 ? (
                      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Actividad Económica (opcional)
                          </Form.Label>
                          <Controller
                            name="actividade1"
                            control={control}
                            defaultValue="0010"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                as="select"
                                onChange={(data) => onChange(data)}
                                value={value}
                                placeholder="Elige una opción"
                              >
                                <option>Elige una opción</option>
                                {datajson.ActividadEconomica.map(
                                  (option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Form.Control>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    ) : null}

                    {vertipo === 1 ? (
                      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Actividad Económica (opcional)
                          </Form.Label>
                          <Controller
                            name="actividade"
                            control={control}
                            defaultValue="9609"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                as="select"
                                onChange={(data) => onChange(data)}
                                value={value}
                                placeholder="Elige una opción"
                              >
                                <option>Elige una opción</option>
                                {datajson.ActividadEconomica.map(
                                  (option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </Form.Control>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    ) : null}

                    <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          Responsabilidad Fiscal (opcional)
                        </Form.Label>
                        <Controller
                          name="responsabilidad"
                          control={control}
                          // rules={{ required: true }}
                          defaultValue="5"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              as="select"
                              onChange={(data) => onChange(data)}
                              value={value}
                              placeholder="Elige una opción"
                            >
                              <option value="1">Gran contribuyente</option>
                              <option value="2">Autorretenedor</option>
                              <option value="3">Agente de retencion IVA</option>
                              <option value="4">
                                Regimen simple de tributacion
                              </option>
                              <option value="5">No responsable</option>
                            </Form.Control>
                          )}
                        />
                        {/* <Controller
                          name="responsabilidad"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              options={[
                                { value: '1', label: 'Gran contribuyente' },
                                { value: '2', label: 'Autorretenedor' },
                                {
                                  value: '3',
                                  label: 'Agente de retencion IVA',
                                },
                                {
                                  value: '4',
                                  label: 'Regimen simple de tributacion',
                                },
                                { value: '5', label: 'No responsable' },
                              ]}
                              className="custon-select"
                              classNamePrefix="custon-select"
                              onChange={(data) => onChange(data)}
                              value={value}
                            />
                          )}
                        /> */}
                        {errors.responsabilidad && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            El campo es obligatorio
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </>
                ) : null}
              </Row>

              {vertipo !== 0 ? (
                <>
                  <Form.Group controlId="formBasicCheckbox">
                    <Controller
                      name="terminos"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={false}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <>
                          <Form.Check
                            type="checkbox"
                            checked={value}
                            onChange={(value) => {
                              onChange(value);
                              userData();
                            }}
                            label="Autorizo a Parking International S.A.S. a realizar el tratamiento de mis datos personales de acuerdo con su"
                          />{' '}
                          <strong>
                            <a
                              href="http://parking.net.co/FacturacionElectronica/POLIITICA_PARA_EL_TRATAMIENTO_DE_LA_INFORMACIOON_PARKING_INTERNATIONAL.pdf"
                              target="_blank"
                              rel="noopener"
                            >
                              Política de Tratamiento de Datos.
                            </a>
                          </strong>
                        </>
                      )}
                    />
                    {errors.terminos && (
                      <Form.Text id="passwordHelpBlock" className="error">
                        Debes aceptar los términos y condiciones.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Row className="justify-content-end">
                    <Button
                      type="submit"
                      variant="primary"
                      className="mtop-20"
                      disabled={loading}
                    >
                      {loadingButton ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Aceptar'
                      )}
                    </Button>
                  </Row>
                </>
              ) : null}
            </form>
          </Col>
        </Row>
      </Container>

      <FooterComponen />
    </>
  );
};

export default CreateClientInvoiceElectronic;
