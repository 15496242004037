import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import CabezeraComponent from '../../components/header/cabezera';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import contactoAzul from '../../asses/img/contacto-azul.png';
import contactoAzul2x from '../../asses/img/contacto-azul@2x.png';
import contactoAzul3x from '../../asses/img/contacto-azul@3x.png';

const PrivacidadbePage = () => {
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;
    const [loading, setLoading] = useState(false);
    const [editBanner, setEditBanner] = useState(false);
    const [editServivios, setEditServicios] = useState(false);
    const [modalNoticia, setModalNoticia] = useState(false);
    const [editAction, setEditAction] = useState('');
    const [dataBaner, setDataBaner] = useState({id: null, url: null, nombre: '', nombre_original: ''});
    const [servicios, setServicios] = useState([]);
    const [alianzas, setAlianzas] = useState([]);
    const [noticias, setNoticias] = useState([]);
    const [parqueaderos, setParqueaderos] = useState([]);
    const [zonas, setZonas] = useState([]);
    const [updateBanner, setUpdateBanner] = useState(false);
    const [updateServicio, setUpdateservicios] = useState(false);
    const [updateAlianzas, setUpdateAlianzas] = useState(false);
    const [updateNoticias, setUpdateNoticias] = useState(false);
    const [noticia, setNoticia] = useState();
    const [nombre, setNombre] = useState();
    const [telefono, setTelefono] = useState();
    const [email, setEmail] = useState();
    const [mensaje, setMensaje] = useState();
    const [acepto, setAcepto] = useState(false);
    const [error, setError] = useState(false);
    const [key, setKey] = useState('home');
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };


    const enviarEmail = async (event) => {
        event.preventDefault();
        if(nombre && telefono && email && mensaje && acepto){
            try {
                const resultado = await axios.get(`https://us-central1-parkingpaginawebproduccion.cloudfunctions.net/sendMail?nombre=${nombre}&telefono=${telefono}&correo=${email}&mensaje=${mensaje}`,);
                setNombre();
                setTelefono();
                setEmail();
                setMensaje();
                setAcepto(false);
                toast.success("Contacto exitoso, pronto nos contactaremos con usted...")
            } catch (error) {
                toast.error("Algo salio mal contactactando a parking...")
            }
        } else {
            setError(true)
        }  
    }
    
    useEffect(() => {
        analytic.logEvent('page_view', {page: `Politicas de privacidad Beparking`});
    }, []);


    return ( 
        loading ? 
            <div className="cargando">
                <div class="loader" id="loader">Loading...</div>
            </div>
        :
        <>
            
            <Container>
                    
                <Row className="justify-content-center mtop-50 mbottom-50" id="contacto">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className="texto-titulo">Política de Privacidad</h3>
                    </Col>
                </Row>
                <Row className="justify-content-center mtop-50 mbottom-50" id="contacto">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h4>AVISO DE PRIVACIDAD</h4>
                        <p>La vinculación al programa de lealtad Be Parking requiere del tratamiento de sus datos personales. PARKING INTERNATIONAL S.A.S (en adelante PARKING) es responsable de dicho tratamiento.<br/><br/>Los datos personales que se suministran para la inscripción del programa (identificación, dirección, correo electrónico y números telefónicos) son utilizados para 1) operar el programa “Be Parking” a través del ofrecimiento de beneficios de las empresas vinculadas al programa, 2) mantenerlo enterado de su detalle y ventajas y 3) permitir a nuestros aliados en capacidad de ofrecer bienes y servicios de su interés la posibilidad de entrar en contacto con usted.<br/><br/>Le recordamos la posibilidad que tiene de acceder en cualquier momento a los datos suministrados, así como de solicitar su corrección, actualización o supresión en los términos establecidos por la Ley 1581 de 2012. Para conocer dichos procedimientos, requisitos y plazos, y para ejercer sus derechos se puede poner en contacto con servicioalcliente@parking.net.co tel 312 450 7484  o visitar nuestra página de Internet www.parking.net.co en el link correspondiente a la Política de Tratamiento de Datos Personales de PARKING, donde en todo momento se incluirá la lista de los aliados arriba referidos.</p>
                    </Col>
                </Row>
            </Container>


            <FooterBeparking />

        </>
     );
}
 
export default PrivacidadbePage;

