import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as ROUTES from '../../constans/rutas';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import CabezeraComponent from '../../components/header/cabezera';

import iconUser from '../../asses/img/icon-user.png';
import iconUser2x from '../../asses/img/icon-user@2x.png';
import iconUser3x from '../../asses/img/icon-user@3x.png';
import iconUserAzul from '../../asses/img/icon-user-azul.png';
import iconUserAzul2x from '../../asses/img/icon-user-azul@2x.png';
import iconUserAzul3x from '../../asses/img/icon-user-azul@3x.png';
import iconReloj from '../../asses/img/icon-reloj.png';
import iconReloj2x from '../../asses/img/icon-reloj@2x.png';
import iconReloj3x from '../../asses/img/icon-reloj@3x.png';
import iconRelojAzul from '../../asses/img/icon-reloj-azul.png';
import iconRelojAzul2x from '../../asses/img/icon-reloj-azul@2x.png';
import iconRelojAzul3x from '../../asses/img/icon-reloj-azul@3x.png';
import iconVehiculo from '../../asses/img/icon-vehiculo.png';
import iconVehiculo2x from '../../asses/img/icon-vehiculo@2x.png';
import iconVehiculo3x from '../../asses/img/icon-vehiculo@3x.png';
import iconVehiculoAzul from '../../asses/img/icon-vehiculo-azul.png';
import iconVehiculoAzul2x from '../../asses/img/icon-vehiculo-azul@2x.png';
import iconVehiculoAzul3x from '../../asses/img/icon-vehiculo-azul@3x.png';
import iconArraw from '../../asses/img/icon-arraw.png';
import iconArraw2x from '../../asses/img/icon-arraw@2x.png';
import iconArraw3x from '../../asses/img/icon-arraw@3x.png';
import iconArrawAzul from '../../asses/img/icon-arraw-azul.png';
import iconArrawAzul2x from '../../asses/img/icon-arraw-azul@2x.png';
import iconArrawAzul3x from '../../asses/img/icon-arraw-azul@3x.png';
import CompleteData from './completeData';
import ModalDatos from '../../components/modal/modalDatos';

const BePerfilPage = () => {
  const authContex = useContext(AuthContex);
  let { usuario } = authContex;
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(0);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [typeModal, setTypeModal] = useState('acumulativePoints');

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (usuario) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [usuario]);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Perfil Beparking` });

    console.log('usuario:', usuario);
    const isValidEmail = /^[^\s@]+@[^\s@]+\w+(\.[^\s@]+)+$/;
    // Verificar condiciones para abrir el modal automáticamente
    const shouldShowModal =
      usuario &&
      (!usuario.genero ||
        usuario.genero.trim() === '' ||
        usuario.direccion === null ||
        usuario.direccion === undefined ||
        (typeof usuario.direccion === 'string' &&
          usuario.direccion.trim() === '') ||
        usuario.direccion === 'null' ||
        !usuario.email ||
        usuario.email.trim() === '' ||
        !isValidEmail.test(usuario.email) ||
        !usuario.ciudad ||
        usuario.ciudad.trim() === '' ||
        !usuario.celular ||
        usuario.celular.trim() === '' ||
        !/^\d{10}$/.test(usuario.celular) ||
        !usuario.celular.startsWith('3') ||
        !usuario.vehiculo ||
        !usuario.vehiculo.length ||
        usuario.vehiculo.some(
          (vehiculo) =>
            !vehiculo.marca ||
            vehiculo.marca.trim() === '' ||
            vehiculo.marca.toUpperCase() === 'NULL' || // Verifica si la marca es "NUL" en mayúsculas
            !vehiculo.modelo ||
            vehiculo.modelo.trim() === '' ||
            !vehiculo.linea ||
            vehiculo.linea.trim() === ''
        ));

    console.log('shouldShowModal:', shouldShowModal);

    if (shouldShowModal) {
      setShow(true);
    }
  }, [usuario]);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <ModalDatos
        show={modalShow}
        handleClose={handleModalClose}
        modalType={typeModal}
      />
      <Container>
        <CabezeraComponent />
        <div
          className={
            usuario?.extracto?.categoria === 'GREEN'
              ? 'be-info mtop-50'
              : 'be-info mtop-50 azul'
          }
        >
          <Row>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <h3 className="texto-titulo">Tus datos.</h3>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ul className="nav-page">
                <li>
                  <NavLink
                    activeClassName="nav-borde nombre"
                    to={ROUTES.BEINFORMACION}
                    className="nav-borde"
                  >
                    <p>Tu información</p>
                    {usuario?.extracto?.categoria === 'GREEN' ? (
                      <>
                        <div className="icon-content">
                          <img
                            src={iconUser}
                            className="icon-icon"
                            srcSet={`${iconUser2x} 2x, ${iconUser3x} 3x`}
                            alt="Informacion del usuario"
                          />
                        </div>
                        <img
                          src={iconArraw}
                          className="icon-nex"
                          srcSet={`${iconArraw2x} 2x, ${iconArraw3x} 3x`}
                          alt="ir"
                        />
                      </>
                    ) : (
                      <>
                        <div className="icon-content">
                          <img
                            src={iconUserAzul}
                            className="icon-icon"
                            srcSet={`${iconUserAzul2x} 2x, ${iconUserAzul3x} 3x`}
                            alt="Informacion del usuario"
                          />
                        </div>
                        <img
                          src={iconArrawAzul}
                          className="icon-nex"
                          srcSet={`${iconArrawAzul2x} 2x, ${iconArrawAzul3x} 3x`}
                          alt="ir"
                        />
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="nav-borde nombre"
                    to={ROUTES.BEHISTORIAL}
                    className="nav-borde"
                  >
                    <p>Tu historial de parqueo</p>
                    {usuario?.extracto?.categoria === 'GREEN' ? (
                      <>
                        <div className="icon-content">
                          <img
                            src={iconReloj}
                            className="icon-icon"
                            srcSet={`${iconReloj2x} 2x, ${iconReloj3x} 3x`}
                            alt="Informacion del usuario"
                          />
                        </div>
                        <img
                          src={iconArraw}
                          className="icon-nex"
                          srcSet={`${iconArraw2x} 2x, ${iconArraw3x} 3x`}
                          alt="ir"
                        />
                      </>
                    ) : (
                      <>
                        <div className="icon-content">
                          <img
                            src={iconRelojAzul}
                            className="icon-icon"
                            srcSet={`${iconRelojAzul2x} 2x, ${iconRelojAzul3x} 3x`}
                            alt="Informacion del usuario"
                          />
                        </div>
                        <img
                          src={iconArrawAzul}
                          className="icon-nex"
                          srcSet={`${iconArrawAzul2x} 2x, ${iconArrawAzul3x} 3x`}
                          alt="ir"
                        />
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="nav-borde nombre"
                    to={ROUTES.BEVEHICULOS}
                    className="nav-borde"
                  >
                    <p>Tus vehículos</p>
                    {usuario?.extracto?.categoria === 'GREEN' ? (
                      <>
                        <div className="icon-content">
                          <img
                            src={iconVehiculo}
                            className="icon-icon"
                            srcSet={`${iconVehiculo2x} 2x, ${iconVehiculo3x} 3x`}
                            alt="Informacion del usuario"
                          />
                        </div>
                        <img
                          src={iconArraw}
                          className="icon-nex"
                          srcSet={`${iconArraw} 2x, ${iconArraw} 3x`}
                          alt="ir"
                        />
                      </>
                    ) : (
                      <>
                        <div className="icon-content">
                          <img
                            src={iconVehiculoAzul}
                            className="icon-icon"
                            srcSet={`${iconVehiculoAzul2x} 2x, ${iconVehiculoAzul3x} 3x`}
                            alt="Informacion del usuario"
                          />
                        </div>
                        <img
                          src={iconArrawAzul}
                          className="icon-nex"
                          srcSet={`${iconArrawAzul2x} 2x, ${iconArrawAzul3x} 3x`}
                          alt="ir"
                        />
                      </>
                    )}
                  </NavLink>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
      <CompleteData open={show} onOpen={(estado) => setShow(estado)} />
      <FooterBeparking />
    </>
  );
};

export default BePerfilPage;
