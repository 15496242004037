import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useForm, Controller, Feedback } from 'react-hook-form';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import * as ROUTES from '../../constans/rutas';

import { URL_AWS, URL_SERVICIO } from '../../constans/servidor';
import PerfilUsuariosComponent from  '../../components/admin/perfil'
import BeparkingUsuariosComponent from  '../../components/admin/beparking'
import BonosUsuariosComponent from  '../../components/admin/bonos'

import CabezeraComponent from '../../components/header/cabezera';


const UsuariosBeparkingComponent = () => {
    const [opcion,setOpcion]=useState()
    const [estado,setEstado]=useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [resultados,setResultado]=useState()
    const onSubmit = async (data)=>{
        setLoading(true)
        try{
            const resultado = await axios.get(`${URL_AWS}/beParking/consultaInfoUsuario/${data.cedula}`)
            if(resultado.data['estado']==true){
                setEstado(true)
                setOpcion(1)
                setResultado(resultado.data['data'])
                setLoading(false)
                toast.success('Usuario encontrado con exito')


            }else{
                setLoading(false)

                toast.error('No se encontro el usuario')
            }

        }catch (error) {
            toast.error('Algo salio mal...');
        }
    }
    const {
        control,
        watch,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        setLoading(false)
    }, []);
    return loading ? (
        <div className="cargando">
            <div className="loader" id="loader">
                Loading...
            </div>
        </div>
    ) : (
        <>
            <Container className='mtop-40 mbottom-40 '>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="justify-content-center mtop-20 mbottom-40">
                        <Col className='justify-content-center' xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className="texto-titulo">Consultar Cliente:</p>

                        </Col>
                    </Row>
                    <Row className='justify-content-center '>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Form.Group >
                                <Form.Label className='texto-label'>Numero de Identificacion</Form.Label>
                                <Controller
                                    name="cedula"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'El campo es obligatorio',
                                        }
                                    }}
                                    defaultValue=""
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Form.Control
                                            type="number"
                                            onBlur={onBlur}
                                            onChange={(value) => onChange(value)}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="justify-content-end mbottom-40">
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Button type="submit" variant="primary" className="mtop-20"
                            >
                                Colsultar
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Container>
            { estado ?(

            <Container className='mtop-40 mbottom-40 '>
                <Navbar expand="lg" className={resultados['extracto']['categoria'] === "GREEN" ? "nav-perfil" : "nav-perfil azul"}>

                    <Nav className="mr-auto">
                        <button
                            className="nav-link"
                            onClick={() => setOpcion(1)} >
                            Prefil
                        </button>
                        <button
                            className="nav-link"
                            onClick={() => setOpcion(2)} >
                            BeParking
                        </button>
                        <button
                            className="nav-link"
                            onClick={() => setOpcion(3)} >
                            Bonos
                        </button>
                        
                    </Nav>
                    <p className="justify-content-end">Puntos acumulados en el año : {resultados['extracto']['puntosAcumulados']} <b></b></p>
                </Navbar>
            </Container>
            ):null}
            <Container>

                {opcion===1?(
                    <PerfilUsuariosComponent resultado={resultados}></PerfilUsuariosComponent>
                ):null}
                {opcion===2?(
                    <BeparkingUsuariosComponent resultado={resultados}></BeparkingUsuariosComponent>
                ):null}
                {opcion===3?(
                    <BonosUsuariosComponent resultado={resultados}></BonosUsuariosComponent>
                ):null}
            </Container>

        </>

    );

};
export default UsuariosBeparkingComponent;
