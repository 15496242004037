import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ScrollToTop from 'react-router-scroll-top';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';

import HomePage from './paginas/home';
import Empresa from './paginas/homemp';
import Aniversario from './paginas/landing/aniversario';
import NoticiaPage from './paginas/noticia';
import NosotrosPage from './paginas/nosotros';
import Noticas from './paginas/noticias';
import LoginPage from './paginas/auth/login';
import RegistroPage from './paginas/auth/registro';
import PerfilPage from './paginas/perfil';
import TerminosPage from './paginas/terminos';
import PoliticaPage from './paginas/PTEE';
import ContactoPage from './paginas/contacto';
import AyudaPage from './paginas/landing/ayudaPuntos';
import BePrkingPage from './paginas/beparking';
import AyudaBeparking from './paginas/beparking/ayudaActulizacionDatos';
import recuperarContrasena from './paginas/beparking/recuperarContrasena';
import ContactobePage from './paginas/beparking/contacto';
import RegistrobePage from './paginas/beparking/registro';
import PerfilbePage from './paginas/beparking/perfil';
import UsuariosbePage from './paginas/admin/index';
import InformacionbePage from './paginas/beparking/informacion';
import HistorialbePage from './paginas/beparking/historial';
import VehiculosbePage from './paginas/beparking/vehiculos';
import detallebePage from './paginas/beparking/detalle';
import BonosbePage from './paginas/beparking/bonos';
import tubeparkingbePage from './paginas/beparking/tubeparking';
import soportebePage from './paginas/beparking/soporte';
import TerminosbePage from './paginas/beparking/terminoscondiciones';
import PrivacidadbePage from './paginas/beparking/privacidad';
import TratamientosbePage from './paginas/beparking/tratamiento';
import PuntosbePage from './paginas/beparking/puntos';
import ContrasenabePage from './paginas/beparking/contrasena';
import PromocionesbePage from './paginas/beparking/promociones';
import MensualidadesPage from './paginas/landing/mensualidades';
import CreateClientInvoiceElectronic from './paginas/landing/facturacion';
import ValidacionClienteFacturaPage from './paginas/landing/validacionclientefactura';
import AyudaFacturaElectronica from './paginas/landing/ayudaFacturaElectronica';
import ProtalPage from './paginas/landing/portal';
import AlianzaRegistroPage from './paginas/beparking/registroalianza';
import AlianzaRegistroTerpelPage from './paginas/beparking/registroalianzaterpel';
import CanalEtica from './paginas/etica';
import * as ROUTES from './constans/rutas';
import AlertaState from './context/alertas/alertaState';
import AuthState from './context/autenticacion/authState';
import tokenAuth from './config/tokenAuth';
import RutaPrivada from './components/rutas/RutaPrivada';
import HeaderComponent from './components/header';
import LoginLegal from './paginas/auth/login_legal';
import PTEEID from './paginas/PTEES';
import LoginBeParking from './paginas/beparking/iniciar_sesion';
import CreateUser from './paginas/retentionCertificates/createUser';
import ProfileUser from './paginas/retentionCertificates/profile';
import LoginRetention from './paginas/retentionCertificates/admin_retention';
import UploadDoc from './paginas/retentionCertificates/upload_doc';
import ProofPaymentLandingPage from './paginas/landing/ProofPaymentLandingPage';

import { useEffect } from 'react';
import ParkingMedia from './paginas/parkingMedia';
// revisar si tenemos un token

const token = localStorage.getItem('token');
if (token) {
  tokenAuth(token);
}
function App() {
  return (
    <>
      <AlertaState>
        <Router>
          <ScrollToTop>
            <AuthState>
              <div className="top-header" />
              <HeaderComponent />
              <Switch>
                <Route exact path={ROUTES.HOME} component={HomePage} />
                <Route
                  exact
                  path={ROUTES.ANIVERSARIO}
                  component={Aniversario}
                />
                <Route exact path={ROUTES.CREATEUSER} component={CreateUser} />
                <Route
                  exact
                  path={ROUTES.PROFILEUSER}
                  component={ProfileUser}
                />
                <Route
                  exact
                  path={ROUTES.LOGINRETENTION}
                  component={LoginRetention}
                />
                <Route exact path={ROUTES.UPLOADDOCS} component={UploadDoc} />
                <Route exact path={ROUTES.CONTACTO} component={ContactoPage} />
                <Route exact path={ROUTES.AYUDAPUNTOS} component={AyudaPage} />
                <Route exact path={ROUTES.LOGIN_LEGAL} component={LoginLegal} />
                <Route exact path={ROUTES.NOTICIA} component={NoticiaPage} />
                <Route exact path={ROUTES.TERMINOS} component={TerminosPage} />
                <Route exact path={ROUTES.PTEE} component={PoliticaPage} />
                <Route exact path={ROUTES.PTEES} component={PTEEID} />
                <Route exact path={ROUTES.EMPRESA} component={Empresa} />
                <Route exact path={ROUTES.NOSOTROS} component={NosotrosPage} />
                <Route exact path={ROUTES.LOGIN} component={LoginPage} />
                <Route exact path={ROUTES.BEPARKING} component={BePrkingPage} />
                <Route exact path={ROUTES.CANALETICA} component={CanalEtica} />
                <Route exact path={ROUTES.ParkingMedia} component={ParkingMedia} />

                <Route
                  exact
                  path={ROUTES.BEINICIARSESION}
                  component={LoginBeParking}
                />
                <Route
                  exact
                  path={ROUTES.BEDETALLE}
                  component={detallebePage}
                />
                <Route exact path={ROUTES.NOTICIAS} component={Noticas} />

                {/* <Route exact path={ROUTES.BEREGISTRO}  render={(props) => <RegistrobePage {...props} queryString={queryString} /> }/> */}
                <Route
                  exact
                  path={ROUTES.BEREGISTRO}
                  component={RegistrobePage}
                />
                <Route
                  exact
                  path={ROUTES.BECONTACTO}
                  component={ContactobePage}
                />
                <Route
                  exact
                  path={ROUTES.BETERMINOS}
                  component={TerminosbePage}
                />
                <Route
                  exact
                  path={ROUTES.BEPPRIVACIDAD}
                  component={PrivacidadbePage}
                />
                <Route
                  exact
                  path={ROUTES.BEPTRATAMIENTO}
                  component={TratamientosbePage}
                />
                <Route
                  exact
                  path={ROUTES.BEPROMOCIONES}
                  component={PromocionesbePage}
                />
                <Route
                  exact
                  path={ROUTES.BEPUNTOSEX}
                  component={PuntosbePage}
                />
                <Route
                  exact
                  path={ROUTES.LANDINGMENSUALIDADES}
                  component={MensualidadesPage}
                />
                <Route
                  exact
                  path={ROUTES.LANDINGFACTURACIONVALIDACION}
                  component={ValidacionClienteFacturaPage}
                />
                <Route
                  exact
                  path={ROUTES.LANDINGAYUDAFACTURACIONELECTRONICA}
                  component={AyudaFacturaElectronica}
                />
                <Route
                  exact
                  path={ROUTES.ProofPaymentLandingPage}
                  component={ProofPaymentLandingPage}
                />
                <Route
                  exact
                  path={ROUTES.LANDINGFACTURACION}
                  component={CreateClientInvoiceElectronic}
                />
                <Route
                  exact
                  path={ROUTES.LANDINGPORTAL}
                  component={ProtalPage}
                />
                <Route
                  exact
                  path={ROUTES.ALIANZASREGISTRO}
                  component={AlianzaRegistroPage}
                />
                <Route
                  exact
                  path={ROUTES.ALIANZASREGISTROTERPEL}
                  component={AlianzaRegistroTerpelPage}
                />
                <Route exact path={ROUTES.BEAYUDA} component={AyudaBeparking} />
                <Route
                  exact
                  path={ROUTES.BECONTRASENAG}
                  component={recuperarContrasena}
                />

                <RutaPrivada
                  exact
                  path={ROUTES.BESOPORTE}
                  component={soportebePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BEPERFIL}
                  component={PerfilbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BEUSUARIOS}
                  component={UsuariosbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BEINFORMACION}
                  component={InformacionbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BECONTRASENA}
                  component={ContrasenabePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BEHISTORIAL}
                  component={HistorialbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BEVEHICULOS}
                  component={VehiculosbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BETUSBONOS}
                  component={BonosbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.BETUBEPARKING}
                  component={tubeparkingbePage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.REGISTRO}
                  component={RegistroPage}
                />
                <RutaPrivada
                  exact
                  path={ROUTES.PERFIL}
                  component={PerfilPage}
                />
              </Switch>
            </AuthState>
          </ScrollToTop>
        </Router>
        <ToastContainer />
      </AlertaState>
    </>
  );
}

export default App;
