import { jsPDF } from 'jspdf';

import parkingImage from '../asses/img/Mesa de trabajo 23.png';
import formatNumber from './formatNumber';

export const generatePDF = (invoiceInfo, duration, setShowLoading) => {
  setShowLoading(true);
  const doc = new jsPDF();
  const pdfWidth = doc.internal.pageSize.getWidth();

  const contentWidth = 100;

  const xContent = (pdfWidth - contentWidth) / 2;
  const yContent = 20;

  doc.addImage(parkingImage, 'JPEG', xContent + 20, yContent, 17, 8);

  doc.setTextColor('#90d400');
  doc.setFontSize(14);
  doc.text(xContent, yContent + 15, `Parking International S.A.S`);
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(xContent, yContent + 25, 'Nit 860.058.760-1');
  doc.text(
    xContent,
    yContent + 33,
    `pq.${invoiceInfo.cod_park} - ${invoiceInfo.infoPark?.nombre_comercial}`
  );
  doc.text(xContent, yContent + 41, `${invoiceInfo.infoPark?.direccion}`);
  doc.text(
    xContent,
    yContent + 49,
    `Comprobante de Pago ${invoiceInfo.cod_terminal}-${invoiceInfo.num_factura}`
  );
  doc.text(
    xContent,
    yContent + 57,
    `Placa:      ${
      invoiceInfo.placa === null ||
      invoiceInfo.placa === undefined ||
      invoiceInfo.placa === ''
        ? 0
        : invoiceInfo.placa
    }`
  );
  doc.text(
    xContent,
    yContent + 65,
    `Fecha de entrada: ${invoiceInfo.fecha_inicial
      .replace('T', ' ')
      .replace('.000Z', '')}`
  );
  doc.text(
    xContent,
    yContent + 73,
    `Fecha hora expedición: ${invoiceInfo.fecha_final
      .replace('T', ' ')
      .replace('.000Z', '')}`
  );
  doc.text(xContent, yContent + 81, `Duración: ${duration}`);
  doc.text(
    xContent,
    yContent + 89,
    `Valor servicio:            $           ${formatNumber(
      invoiceInfo.subtotal
    )}`
  );
  doc.text(
    xContent,
    yContent + 97,
    `Iva:                            $             ${formatNumber(
      invoiceInfo.iva
    )}`
  );
  doc.text(
    xContent,
    yContent + 105,
    `Total:                         $             ${formatNumber(
      invoiceInfo.total
    )}`
  );
  doc.text(xContent + 10, yContent + 113, '------Formas de Pago------');
  doc.text(
    xContent,
    yContent + 121,
    `${
      invoiceInfo.valor_datafono !== 0 &&
      invoiceInfo !== null &&
      invoiceInfo !== undefined
        ? 'Valor Datafono:'
        : 'Valor Efectivo:'
    }            $            ${
      invoiceInfo.valor_datafono !== 0 &&
      invoiceInfo.valor_datafono !== 0 &&
      invoiceInfo !== null &&
      invoiceInfo !== undefined
        ? formatNumber(invoiceInfo.valor_datafono)
        : formatNumber(invoiceInfo.valor_ef)
    }`
  );
  doc.text(
    xContent,
    yContent + 129,
    `Total efectivo:             $            ${
      invoiceInfo.valor_ef !== null &&
      invoiceInfo.valor_ef !== undefined &&
      invoiceInfo.valor_ef !== 0
        ? formatNumber(invoiceInfo.valor_ef)
        : 0.0
    }`
  );
  if (
    invoiceInfo.formaPago !== 'EFECTIVO' &&
    invoiceInfo.formaPago !== 'DATAFONO'
  ) {
    doc.text(
      xContent,
      yContent + 137,
      `Beneficio / descuento:      ${
        invoiceInfo.formaPago !== '' ? invoiceInfo.formaPago : 'No Aplica'
      } `
    );
  }

  if (invoiceInfo.customerInformation !== null) {
    doc.text(
      xContent + 3,
      yContent + 140,
      '----------------------------------------------'
    );
    doc.text(xContent + 20, yContent + 145, 'Be parking');
    doc.text(
      xContent,
      yContent + 150,
      `Estimado(a)                                     ${invoiceInfo.customerInformation.nombre}`
    );
    doc.text(
      xContent,
      yContent + 158,
      `Categoria                                         ${invoiceInfo.customerInformation.categoria}`
    );
    doc.text(
      xContent,
      yContent + 166,
      `Puntos acumulados en la compra         ${invoiceInfo.customerInformation.puntosTransaccion}`
    );
    doc.text(
      xContent,
      yContent + 174,
      `Puntos acumulados                               ${invoiceInfo.customerInformation.puntosAcumulados}`
    );

    doc.text(
      xContent,
      yContent + 182,
      `Puntos nesesarios para la hora              ${invoiceInfo.customerInformation.puntosFaltantesParaBono}`
    );

    doc.text(
      xContent,
      yContent + 190,
      `Su extracto se vera reflejado al dia siguiente.`
    );

    doc.text(
      xContent,
      yContent + 198,
      `Decreto 268/09 - parrafo 2do. la liquidación del valor final del servicio
      `
    );
    doc.text(
      xContent,
      yContent + 203,
      `se aproximara al multiplo de cincuenta pesos ($50) siguiente.`
    );
    doc.text(
      xContent,
      yContent + 208,
      `Este punto de servicio se encuentra con la poliza deresponsabilidad`
    );
    doc.text(
      xContent,
      yContent + 213,
      `Civil No. 2267 de AXA COLPATRIA SEGUROS S.A.`
    );

    doc.text(
      xContent,
      yContent + 218,
      `GRACIAS POR CONFIAR EN NUESTRO SERVICIO.`
    );
  } else {
    doc.text(
      xContent,
      yContent + 145,
      `Decreto 268/09 - parrafo 2do. la liquidación del valor final del servicio
      `
    );
    doc.text(
      xContent,
      yContent + 150,
      `se aproximara al multiplo de cincuenta pesos ($50) siguiente.`
    );
    doc.text(
      xContent,
      yContent + 155,
      `Este punto de servicio se encuentra con la poliza deresponsabilidad`
    );
    doc.text(
      xContent,
      yContent + 160,
      `Civil No. 2267 de AXA COLPATRIA SEGUROS S.A.`
    );

    doc.text(
      xContent,
      yContent + 175,
      `GRACIAS POR CONFIAR EN NUESTRO SERVICIO.`
    );
  }

  setTimeout(() => {
    doc.save(
      `Comprobante de pago ${invoiceInfo.cod_terminal}-${invoiceInfo.num_factura}`
    );
    setShowLoading(false);
  }, 1500);
};

export const generatePdfMonthly = (invoiceInfo, duration, setShowLoading) => {
  setShowLoading(true);
  const doc = new jsPDF();
  const pdfWidth = doc.internal.pageSize.getWidth();

  const contentWidth = 100;

  const xContent = (pdfWidth - contentWidth) / 2;
  const yContent = 20;

  doc.addImage(parkingImage, 'JPEG', xContent + 20, yContent, 17, 8);

  doc.setTextColor('#90d400');
  doc.setFontSize(14);
  doc.text(xContent, yContent + 15, `Parking International S.A.S`);
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(xContent, yContent + 25, 'Nit 860.058.760-1');
  doc.text(
    xContent,
    yContent + 33,
    `pq.${invoiceInfo.cod_park} - ${invoiceInfo.infoPark?.nombre_comercial}`
  );
  doc.text(xContent, yContent + 41, `${invoiceInfo.infoPark?.direccion}`);
  doc.text(
    xContent,
    yContent + 49,
    `Comprobante de Pago ${invoiceInfo.cod_terminal}-${invoiceInfo.num_factura}`
  );
  doc.text(
    xContent,
    yContent + 57,
    `Fecha hora expedición: ${invoiceInfo.fecha_final
      .replace('T', ' ')
      .replace('.000Z', '')}`
  );
  doc.text(
    xContent,
    yContent + 62,
    `Cliente:      ${
      invoiceInfo.monthlyData.client === null ||
      invoiceInfo.monthlyData.client === undefined ||
      invoiceInfo.monthlyData.client === ''
        ? 'No encontrado.'
        : invoiceInfo.monthlyData.client.nombre +
          ' ' +
          invoiceInfo.monthlyData.client.apellido
    }`
  );
  doc.text(
    xContent,
    yContent + 67,
    `Placa:      ${
      invoiceInfo.placa === null ||
      invoiceInfo.placa === undefined ||
      invoiceInfo.placa === ''
        ? 0
        : invoiceInfo.placa
    }`
  );
  doc.text(
    xContent,
    yContent + 72,
    `Vigencia hasta: ${invoiceInfo.monthlyData.fecha_final}`
  );
  doc.text(
    xContent,
    yContent + 85,
    `Forma de pago:               ${invoiceInfo.formaPago}`
  );
  doc.text(
    xContent,
    yContent + 90,
    `Subtotal:              $             ${formatNumber(invoiceInfo.subtotal)}`
  );
  doc.text(
    xContent,
    yContent + 95,
    `Iva:                       $            ${formatNumber(invoiceInfo.iva)}`
  );
  doc.text(
    xContent,
    yContent + 100,
    `TOTAL:                $            ${formatNumber(invoiceInfo.total)}`
  );
  doc.text(
    xContent,
    yContent + 115,
    `Importante esta mensualidad aplica solo en el horario establecio
    `
  );
  doc.text(
    xContent,
    yContent + 120,
    `a este parqueadero. Fuera del horario el tiempo sera liquidado`
  );
  doc.text(xContent, yContent + 125, `de acuerdo a la tarifa normal.`);

  doc.text(
    xContent,
    yContent + 140,
    `GRACIAS POR CONFIAR EN NUESTRO SERVICIO.`
  );

  setTimeout(() => {
    doc.save(
      `Comprobante de pago ${invoiceInfo.cod_terminal}-${invoiceInfo.num_factura}`
    );
    setShowLoading(false);
  }, 1500);
};

export const generatePdfMonthlyBici = (invoiceInfo, setShowLoading) => {
  setShowLoading(true);
  const doc = new jsPDF();
  const pdfWidth = doc.internal.pageSize.getWidth();

  const contentWidth = 100;

  const xContent = (pdfWidth - contentWidth) / 2;
  const yContent = 20;

  doc.addImage(parkingImage, 'JPEG', xContent + 20, yContent, 17, 8);

  doc.setTextColor('#90d400');
  doc.setFontSize(14);
  doc.text(xContent, yContent + 15, `Parking International S.A.S`);
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(xContent, yContent + 25, 'Nit 860.058.760-1');
  doc.text(
    xContent,
    yContent + 33,
    `pq.${invoiceInfo.cod_park} - ${invoiceInfo.infoPark?.nombre_comercial}`
  );
  doc.text(xContent, yContent + 41, `${invoiceInfo.infoPark?.direccion}`);
  doc.text(
    xContent,
    yContent + 49,
    `Comprobante de Pago ${invoiceInfo.cod_terminal}-${invoiceInfo.num_factura}`
  );
  doc.text(
    xContent,
    yContent + 57,
    `Fecha hora expedición: ${invoiceInfo.fecha_final
      .replace('T', ' ')
      .replace('.000Z', '')}`
  );
  doc.text(xContent, yContent + 67, `Mensualidad de bicicleta (30d)`);
  doc.text(xContent, yContent + 77, `Vigencia de esta compra:`);
  doc.text(
    xContent,
    yContent + 82,
    `Desde ${invoiceInfo.monthlyData.fecha_inicio} hasta ${invoiceInfo.monthlyData.fecha_fin}`
  );
  doc.text(xContent, yContent + 90, `Vigencia total:`);
  doc.text(
    xContent,
    yContent + 95,
    `Desde ${invoiceInfo.monthlyData.fecha_inicio_total} hasta ${invoiceInfo.monthlyData.fecha_fin_total}`
  );
  doc.text(
    xContent,
    yContent + 105,
    `Nombre: ${invoiceInfo.monthlyData.nombres}`
  );
  doc.text(
    xContent,
    yContent + 110,
    `Cedula: ${invoiceInfo.monthlyData.documento_numero}`
  );
  doc.text(xContent, yContent + 118, `Datos de la bicicleta`);
  doc.text(
    xContent,
    yContent + 123,
    `-Color: ${invoiceInfo.monthlyData.bicicleta_color}`
  );
  doc.text(
    xContent,
    yContent + 128,
    `-Marca: ${invoiceInfo.monthlyData.bicicleta_marca}`
  );
  doc.text(
    xContent,
    yContent + 133,
    `-Serial: ${invoiceInfo.monthlyData.bicicleta_serial}`
  );
  doc.text(
    xContent,
    yContent + 140,
    `Valor Servicio:               $             ${formatNumber(
      invoiceInfo.subtotal
    )}`
  );
  doc.text(
    xContent,
    yContent + 145,
    `Iva:                                 $            ${formatNumber(
      invoiceInfo.iva
    )}`
  );
  doc.text(
    xContent,
    yContent + 150,
    `TOTAL:                          $            ${formatNumber(
      invoiceInfo.total
    )}`
  );
  doc.text(
    xContent,
    yContent + 155,
    `Total ${invoiceInfo.formaPago.toLowerCase()}:                $            ${formatNumber(
      invoiceInfo.total
    )}`
  );
  doc.text(
    xContent,
    yContent + 165,
    `Importante esta mensualidad aplica solo en el horario establecio
    `
  );
  doc.text(
    xContent,
    yContent + 170,
    `a este parqueadero. Fuera del horario el tiempo sera liquidado`
  );
  doc.text(xContent, yContent + 175, `de acuerdo a la tarifa normal.`);

  doc.text(
    xContent,
    yContent + 185,
    `GRACIAS POR CONFIAR EN NUESTRO SERVICIO.`
  );

  setTimeout(() => {
    doc.save(
      `Comprobante de pago ${invoiceInfo.cod_terminal}-${invoiceInfo.num_factura}`
    );
    setShowLoading(false);
  }, 1500);
};
