import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
 
import { analytic } from '../../server/firebase';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
import AuthContex from '../../context/autenticacion/authContex';
import FooterBeparking from '../../components/footer/beparking';
import { URL_AWS } from '../../constans/servidor';
import registro from '../../asses/img/logoRegistro.png';
import LoginRetention from '../retentionCertificates';
import ProfileUser from './profile';
const CreateUser = (props) => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.get('id');
  const authContex = useContext(AuthContex);
  const [imagen, setImagen] = useState(registro);
  const [loading] = useState(false);
  const [tab, setTab] = useState(1);
  const [loadingData] = useState(false);
  const [urls] = useState(null);
  const [evento] = useState();

  const [nombre, setNombre] = useState('');
  const [nit, setNit] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
 
  const onSubmit = async (event) => {
    event.preventDefault();
    console.log(nombre, nit, email, password, acepto);
    if (nombre && nit && email && password && acepto) {
      if(password.length < 6){
        return toast.error("La contraseña debe ser mayor a 6 caracteres!!","danger")
      }
      const userNormal = "Normal"
      const body = {
        Nit: nit,
        name: nombre,
        email: email,
        password: password,
        typeUser: userNormal
      };
      try {
        const result = await axios.post(
          URL_AWS + `/Suppliers/registerUser`,
          body
        );
        console.log("resutl",result)
        if (result.data.status === true) {
          toast.success('Registro Exitoso.');
         
          reset({
            setNit:'',
            nombre: '',
            nit: '',
            email: '',
            password: '',
            cpassword: '',
            acepto:'',
          });
        } else {
          toast.error('Hubo un error al registrase', 'danger');
        }
      } catch (error) {
        toast.error('Algo salio mal ...');
      }
    } else {
      toast.error('se requieren todos los campos', 'danger');
      setError(true);
    }
  };
  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="pbottom-40">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center ">
            <img
              src={imagen}
              className="img-fluid mtop-30"
              alt="Registro BeParking"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="be-registro">
            <h3>Regístrate </h3>
            <Form onSubmit={onSubmit}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={1}
                onSelect={(k) => setTab(k)}
                activeKey={tab}
              >
                <Tab.Content className="be-tab-content">
                  <Tab.Pane eventKey={1}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Nombre</Form.Label>
                      <Controller
                        name="nombre"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(e) =>
                              onChange(e.target.value) &&
                              setNombre(e.target.value)
                            }
                            value={value}
                            placeholder="Ingresa el nombre de tu empresa"
                          />
                        )}
                      />
                      {error && !nombre ? (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El nombre es obligatorio
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>NIT</Form.Label>
                      <Controller
                        name="nit"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(e) =>
                              onChange(e.target.value) &&
                              setNit(e.target.value)
                            }
                            value={value}
                            placeholder="Ingresa el NIT con número de verificación y guión"
                          />
                        )}
                      />
                      {error && !nit ? (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El nit es obligatorio
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Correo electrónico</Form.Label>
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(e) =>
                              onChange(e.target.value) &&
                              setEmail(e.target.value)
                            }
                            value={value}
                            placeholder="Ingresa el correo electrónico"
                          />
                        )}
                      />
                      {error && !email ? (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El email es obligatorio
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Contraseña</Form.Label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <div style={{ position: 'relative' }}>
                            <Form.Control
                              type={showPassword ? 'text' : 'password'}
                              onBlur={onBlur}
                              onChange={(e) =>
                                onChange(e.target.value) &&
                                setPassword(e.target.value)
                              }
                              value={value}
                              placeholder="Ingresa tu contraseña"
                            />
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setShowPassword(
                                  (prevShowPassword) => !prevShowPassword
                                )
                              }
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                          </div>
                        )}
                      />
                      {errors.password && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La contraseña es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Confirmar contraseña</Form.Label>
                      <Controller
                        name="cpassword"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <div style={{ position: 'relative' }}>
                            <Form.Control
                              type={showConfirmPassword ? 'text' : 'password'}
                              onBlur={onBlur}
                              onChange={(e) => onChange(e.target.value)}
                              value={value}
                              placeholder="Confirma tu contraseña"
                            />
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setShowConfirmPassword(
                                  (prevShowConfirmPassword) =>
                                    !prevShowConfirmPassword
                                )
                              }
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                          </div>
                        )}
                      />
                      {watch('password') !== watch('cpassword') && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La contraseña deben coincidir
                        </Form.Text>
                      )}
                      {errors.cpassword && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          Confirmar contraseña es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Acepta nuestras"
                        onChange={(evt) => setAcepto(evt.target.checked)}
                      />
                      <a
                        className="enlace-check"
                        href="/#/terminosycondiciones"
                      >
                        politicas de tratamiento de datos
                      </a>
                      {error && !acepto ? (
                        <Form.Text id="passwordHelpBlock" className="error">
                          Es necesario aceptar nuestras politicas de
                          tratamientos de datos.
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                    <Col className="text-right p-0">
                      <Button variant="primary" size="sm" type="submit">
                        Registrarme
                      </Button>
                    </Col>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Form>
            <hr />
            <Col className="text-center p-0">
              <span className="text-gris">¿Ya tienes una cuenta?</span>
              <LoginRetention
                open={show}
                onOpen={(estado) => setShow(estado)}
              />
              <Button
                variant="primary"
                className="btn-borde-verde ml-3"
                size="sm"
                type="submit"
                onClick={() => setShow(true)}
              >
                Inicia sesión
              </Button>
            </Col>
          </Col>
        </Row>
      </Container>
      <FooterBeparking />
    </>
  );
};
 
export default CreateUser;