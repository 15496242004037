import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import CabezeraComponent from '../../components/header/cabezera';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import contactoAzul from '../../asses/img/contacto-azul.png';
import contactoAzul2x from '../../asses/img/contacto-azul@2x.png';
import contactoAzul3x from '../../asses/img/contacto-azul@3x.png';

const TerminosbePage = () => {
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;
    const [loading, setLoading] = useState(false);
    const [editBanner, setEditBanner] = useState(false);
    const [editServivios, setEditServicios] = useState(false);
    const [modalNoticia, setModalNoticia] = useState(false);
    const [editAction, setEditAction] = useState('');
    const [dataBaner, setDataBaner] = useState({id: null, url: null, nombre: '', nombre_original: ''});
    const [servicios, setServicios] = useState([]);
    const [alianzas, setAlianzas] = useState([]);
    const [noticias, setNoticias] = useState([]);
    const [parqueaderos, setParqueaderos] = useState([]);
    const [zonas, setZonas] = useState([]);
    const [updateBanner, setUpdateBanner] = useState(false);
    const [updateServicio, setUpdateservicios] = useState(false);
    const [updateAlianzas, setUpdateAlianzas] = useState(false);
    const [updateNoticias, setUpdateNoticias] = useState(false);
    const [noticia, setNoticia] = useState();
    const [nombre, setNombre] = useState();
    const [telefono, setTelefono] = useState();
    const [email, setEmail] = useState();
    const [mensaje, setMensaje] = useState();
    const [acepto, setAcepto] = useState(false);
    const [error, setError] = useState(false);
    const [key, setKey] = useState('home');
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };


    const enviarEmail = async (event) => {
        event.preventDefault();
        if(nombre && telefono && email && mensaje && acepto){
            try {
                const resultado = await axios.get(`https://us-central1-parkingpaginawebproduccion.cloudfunctions.net/sendMail?nombre=${nombre}&telefono=${telefono}&correo=${email}&mensaje=${mensaje}`,);
                setNombre();
                setTelefono();
                setEmail();
                setMensaje();
                setAcepto(false);
                toast.success("Contacto exitoso, pronto nos contactaremos con usted...")
            } catch (error) {
                toast.error("Algo salio mal contactactando a parking...")
            }
        } else {
            setError(true)
        }  
    }
    
    useEffect(() => {
        analytic.logEvent('page_view', {page: `Terminos y Condiciones Beparking`});
    }, []);


    return ( 
        loading ? 
            <div className="cargando">
                <div class="loader" id="loader">Loading...</div>
            </div>
        :
        <>
            
            <Container>
                    
                <Row className="justify-content-center mtop-50 mbottom-50" id="contacto">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className="texto-titulo">Términos y condiciones</h3>
                    </Col>
                </Row>
                    
                <Row className="justify-content-center mtop-50 mbottom-50" id="contacto">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h4>TÉRMINOS Y CONDICIONES DEL PROGRAMA DE LEALTAD BE PARKING</h4>
                        <p>El programa de fidelización Be Parking (el “Programa”) es un programa de lealtad entre Parking International S.A.S. (la “Compañía”), sociedad colombiana, propietaria y operadora del Programa y el miembro del Programa (el “Afiliado”), quién estará sujeto a los términos y condiciones a continuación descritos (los “Términos”) en lo relacionado con el otorgamiento de beneficios y las reglas de operación del Programa, así como a la Política de Tratamiento de Datos Personales de Parking International SAS a la que remite el Aviso de Privacidad, que se encuentra publicada e la página web. Sin la aceptación de los Términos no se podrá hacer parte del Programa.</p>
                        <p><strong>Primera.- OBJETIVO.</strong> El Programa ha sido diseñado para gratificar la lealtad de los clientes, otorgando beneficios por estacionar en los parqueaderos de la Compañía.<br/><br/>Los clientes de Parking Internacional S.A.S. se afiliarán al Programa mediante la suscripción del presente documento y conforme al Aviso de Privacidad del mismo.</p>
                        <p><strong>Segunda.- DEFINICIONES.</strong></p>
                        <ul>
                            <li>Afiliado (s): Los clientes de la Compañía que se suscriban al Programa.</li>
                            <li>Afiliado blue: Los afiliados que han logrado acumular 1.100 puntos o más en u año calendario.</li>
                            <li>Punto de servicio: Parqueaderos de la red de la Compañía.</li>
                            <li>Información: Son los datos personales del Afiliado.</li>
                            <li>Punto (s): Puntos que se otorgan al Afiliado por la compra de cualquiera de los productos del portafolio, exceptuando servicios y recaudos, los cuales incluyen aquellos que se otorgan al Afiliado por las horas de parqueadero que vayan acumulando.</li>
                            <li>Bono (s): Medio para la redención de puntos por parte del Afiliado en los parqueaderos de la Compañía.</li>
                            <li>Política de Tratamiento: Reglas para el tratamiento de datos personales en los términos del artículo 13 del decreto 1377 de 2013.</li>
                        </ul>
                        <p><strong>Tercera.- CONDICIONES GENERALES.</strong></p>
                        <ol>
                            <li>El Afiliado debe ser persona natural, mayor de edad.</li>
                            <li>Con la inscripción al Programa, el Afiliado acepta íntegramente los Términos de ésta y se obliga a cumplirlos, así como las futuras modificaciones. La membresía al Programa es personal e intransferible y la inscripción no tiene costo alguno.</li>
                            <li>No se podrán transferir beneficios entre cada Afiliado del Programa.</li>
                            <li>El Afiliado al Programa al suscribir el correspondiente Aviso de Privacidad autoriza de modo expreso a la Compañía para recolectar, compendiar, adaptar, procesar, reproducir y, en general, realizar tratamiento de datos de conformidad con el ordenamiento legal colombiano y en especial con la Ley 1581 del 2012 correspondiente a la protección de datos personales.</li>
                            <li>A la Compañía le asiste la facultad para disponer de la Información en virtud del Programa e las condiciones incluidas e la Política de Tratamiento, lo que es aceptado por el Afiliado, a partir de su vinculación.</li>
                            <li>El Afiliado del Programa, debe garantizar la exactitud de la información suministrada en la Base de Datos. Es responsabilidad del Afiliado suministrar información veraz sobre sus datos personales e informar los cambios de dicha información a la Compañía a través de la Página Web www.parking.net.co o a través de la línea de servicio al cliente en Bogotá 300 910 9787 de Lunes a Viernes de ocho de la mañana (8:00 A.M. a cinco y media de la tarde (5:30 P.M).</li>
                            <li>El Afiliado que haya suministrado datos personales falsos y/o adquiera bonos para reventa, será retirado del Programa.</li>
                            <li>El Afiliado al Programa, acepta que la Compañía lo contacte y le envíe información comercial y relacionada con el programa de manera periódica por cualquier medio de comunicación (boletines físicos y electrónicos, teléfono, celular, otros).</li>
                            <li>Los beneficios del Programa no son acumulables con otras promociones o acuerdos como la tarjeta prepark, stickers, tarjeta VIP, entre otros.</li>
                            <li>El Afiliado podrá retirarse del Programa en cualquier momento, mediante comunicación a través de la Página Web www.parking.net.co o de la línea de servicio al cliente 300 910 9787 en Bogotá de Lunes a Viernes de ocho de la mañana (8:00 A.M.) a cinco y media de la tarde (5:30 P.M.)</li>
                            <li>El Afiliado que se retire del Programa podrá redimir los Bonos ya emitidos que se encuentren en su poder hasta el término de los mismos.</li>
                            <li>En caso de fallecimiento del Afiliado, se perderá la totalidad de los beneficios acumulados que se encontraren a su nombre.</li>
                            <li>El programa aplicará solo para la ciudad de Bogotá.</li>
                            <li>El afiliado no podrá hacer uso de los beneficios del programa para lucrarse y obtener beneficios para fines comerciales. Si se comprueba lo anterior, será desactivado de forma inmediata del programa.</li>
                        </ol>
                        <p><strong>Cuarta.- VIGENCIA DEL PROGRAMA.</strong> El Programa tendrá vigencia indefinida. La Compañía se reserva el derecho a darlo por terminado cuando lo considere conveniente, dando aviso al Afiliado con un mes de anterioridad mediante los diferentes medios de comunicación asignados por la Compañía para este fin.</p>
                        <p><strong>Quinta.- BENEFICIOS.</strong> Los beneficios del Programa serán aquellos que determine la Compañía para ser utilizados en los propios Puntos de Servicio o en establecimientos de terceros Aliados. La descripción de los beneficios se mantendrá en lugar visible de los Puntos de Servicio, en la página web www.parking.net.co, a través de material publicitario digital o impreso, correo electrónico o cualquier otro medio de notificación que brinde suficiente información a los Afiliados. Los beneficios se establecerán en dos niveles, unos para todos los Afiliados y otros para los Afiliados Blue.<br/><br/>La Compañía cuenta con autonomía para prescindir, recalcular, reclasificar y en general realizar modificaciones en cualquier momento, de los beneficios ofrecidos a las diferentes categorías de Afiliados, cumpliendo para el efecto con las condiciones de publicidad antes mencionadas.</p>
                        <p><strong>Sexta.- REGLAMENTO DE ACUMULACION Y REDENCION DE PUNTOS EN TIEMPO DE PARQUEO.</strong></p>
                        <ol>
                            <li>Por cada Cien Pesos Moneda Corriente ($100) pagados, el Afiliado acumulará (1) punto. Al acumular ochocientos (800) puntos, el Afiliado podrá redimir su beneficio correspondiente a una (1) hora de parqueadero gratis en los parqueaderos habilitados para ello.</li>
                            <li>El Afiliado empezará a acumular Puntos a partir de la fecha de inscripción al Programa. Los Puntos acumulados por el tiempo pagado se podrán ver reflejados en el tiquete de compra, la Página Web www.parking.net.co, o en la línea de servicio al cliente 300 910 9787 en Bogotá de Lunes a Viernes de ocho de la mañana (8:00 A.M.) a cinco y media de la tarde (5:30 P.M.)</li>
                            <li>Los Puntos acumulados por el Afiliado son redimibles en un (1) Bono de una hora válidos únicamente en Puntos de Servicio habilitados para tal fin. Los Afiliados podrán acumular Puntos adicionales de acuerdo con las promociones realizadas dentro del Programa a través de campañas publicitarias que se informarán oportunamente previamente y durante su ejecución.</li>
                            <li>La acumulación y redención de puntos no estará habilitada en todos los Puntos de Servicio de la Compañía. En todo momento en la página web www.parking.net.co se indicará el detalle de aquellos parqueaderos excluidos. La misma información se pondrá a disposición de los Afiliados en cada Punto de Servicio habilitado para el programa.</li>
                            <li>Solamente los Afiliados que hayan acumulado ochocientos (800) Puntos, tendrán derecho a redimir el tiempo acumulado en los parqueaderos previstos para ello.</li>
                            <li>Una vez el Afiliado ha acumulado un bloque de 800 puntos, en la misma factura se indicará este hecho con el propósito de que a partir de ese momento pueda redimir un (1) bono de una (1) hora. En todo caso, a través de la página web www.parking.net.co o la línea de servicio al cliente 300 910 9787 en Bogotá de Lunes a Viernes de ocho de la mañana (8:00 A.M). a cinco y media de la tarde (5:30 P.M.) el mismo Afiliado puede consultar el estado de sus puntos</li>
                            <li>Los Bonos tendrán un período de vigencia de un (1) mes calendario contados a partir de la fecha en que el Afiliado ha acumulado los 800 puntos.</li>
                            <li>Para efectos de redimir su bono, el Afiliado deberá indicar al cajero del Punto de Atención su número de cédula.</li>
                            <li>Cada Bono de una (1) hora debe hacerse efectivo por su valor total, sin perjuicio de que pueda utilizarse para redimir minutos inferiores a los estipulados en el Bono y estos no son acumulables con otras promociones, así como tampoco generarán nuevos Puntos ni darán lugar a reembolso de efectivo.</li>
                            <li>El Afiliado acepta que hay parqueaderos que pueden cerrarse por cese de operación definitiva.</li>
                            <li>Sin perjuicio de lo anterior, la compañía se reserva el derecho de cambiar la equivalencia de puntos por horas, cuartos de horas, minutos o cualquier otra unidad de tiempo sin que en ningún caso el beneficio se disminuya.</li>
                            <li>Los Afiliados aceptan que la Compañía podrá a su criterio resarcir los daños causados por inconvenientes de conexión, adecuaciones que afecten el sistema, desconocimiento del personal o problemas técnicos, con el obsequio de tiempo gratuito, renunciando desde ya a iniciar cualquier acción jurídica que dé lugar al pago de indemnización.</li>
                            <li>La Compañía hará su mayor esfuerzo para consolidar un portafolio de beneficios atractivo para ofrecer a sus Afiliados teniendo en cuenta su categoría.</li>
                            <li>La acumulación de puntos se realizará con la factura expedida del punto de servicio, en los casos que al afiliado no se le haya podido acumular en el momento, del mismo modo, la facturación manual queda excluida del programa Be Parking.</li>
                        </ol>
                        <p><strong>Séptima.- VIGENCIA DE PUNTOS.</strong></p>
                        <ol>
                            <li>Cada vez que el Afiliado acumule ochocientos puntos, se hará acreedor a una hora gratis de parqueo. Siempre que los Puntos acumulados no superen el umbral de (800), éstos tendrán una vigencia de doce (12) meses a partir de su compra</li>
                            <li>Los Bonos emitidos, tendrán una vigencia de un (1) mes a partir de su emisión, para ser utilizados.</li>
                            <li>Los Bonos emitidos y la redención de Puntos, no utilizadas en el tiempo vigente, se perderán en forma definitiva, sin derecho a reclamaciones posteriores por parte de los Afiliados.</li>
                            <li>La acumulación y redención de Puntos y Beneficios del Programa estarán sujetos a las condiciones particulares de cada parqueadero, tales como horarios, disponibilidad de espacio, etc.</li>
                            <li>La Compañía se reserva el derecho de modificar y/o terminar el Programa cuando lo considere conveniente, lo que será informado al Afiliado, a través de la Página Web www.parking.net.co y de los diferentes medios de comunicación a disposición de la Compañía para este fin, con un mes de anterioridad al cambio.</li>
                        </ol>
                        <p><strong>Octava.- Afiliados Blue.<br/></strong> Aquellos Afiliados que en un año calendario hayan acumulado un total de 1.100 puntos, por este sólo hecho acceden, en adición a los beneficios derivados de la simple condición de Afiliado.<br/><br/>El cliente blue desciende de categoría si en el periodo de enero 1 al diciembre 31 del año en curso no ha acumulado 1.100 puntos o superior, de nuevo pasará a Green.<br/><br/>Las condiciones detalladas de estos servicios y eventuales beneficios adicionales se pueden encontrar en la página web www.parking.net.co</p>
                        <p><strong>Novena.- BASES DE DATOS Y CONFIDENCIALIDAD DE LA INFORMACIÓN.<br/></strong> Las reglas sobre tratamiento de datos personales de los Afiliados se rigen conforme lo dispone el Aviso de Privacidad y la Política de Tratamiento de Información descrita en www.parking.net.co</p>
                        <ol>
                            <li>La Compañía se reserva el derecho de modificar, suspender, terminar y/o cancelar de manera parcial o total, individual o colectiva, en cualquier momento el Programa. Para este efecto, se informará a los Afiliados o clientes de la Compañía por los medios de comunicación que considere idóneos con un mes de anterioridad. La terminación, bajo las circunstancias indicadas, en ningún caso generará responsabilidades a cargo de la Compañía, ni dará lugar al pago de indemnización.</li>
                            <li>En caso de modificación, suspensión, terminación y/o cancelación parcial o total, individual o colectiva del Programa, el Afiliado que tenga los Puntos suficientes para reclamar Bonos o beneficios, contará con un (1) mes calendario para hacerlo contados a partir de la fecha en que la Compañía haya informado la modificación, suspensión, terminación o cancelación del Programa.</li>
                            <li>El Afiliado podrá solicitar información sobre el Programa a través de la línea de servicio al cliente en Bogotá al número 300 910 9787 de Lunes a Viernes de ocho de la mañana (8:00 A.M.) a cinco y media de la tarde (5:30 P.M.)</li>
                            <li>Una versión actualizada de los Términos podrá ser consultada en www.parking.net.co. Es responsabilidad del Afiliado conocer, cumplir y actualizarse de todas las reglas y políticas del Programa por medio de la Página Web de la Compañía, en aras de entender los derechos, responsabilidades y su estatus bajo el Programa.</li>
                        </ol>
                        <p><strong>Para Santafé Urgencias:<br/></strong></p>
                        <ul>
                            <li>A partir del 1 de Noviembre las acumulaciones que no se realicen en el punto de servicio, no se podrán efectuar debido a la complementación del nuevo sistema de automatización.</li>
                        </ul>
                        <p><strong>Para Nuevo Mederi, Mederi mayor y Mederi Barrios Unidos:<br/></strong></p>
                        <ul>
                            <li>A partir de la fecha la acumulación de puntos y redención de bonos no se podrán realizar por motivos de automatización en el punto de estacionamiento.</li>
                            <li>A partir del 1 de junio en Mederi Mayor, 5 de junio Mederi Barrios Unidos y 15 de junio de 2018 en Nuevo Mederi, la acumulación de puntos y redención de bonos no se podrán realizar por motivos de automatización en el punto de estacionamiento.</li>
                        </ul>
                        <p><strong>Para Valet Parking:<br/></strong></p>
                        <ul>
                            <li>Para Valet Parking A partir de la fecha y por tiempo indefinido queda excluido del programa (acumulación de puntos y redención de bonos).</li>
                        </ul>
                    </Col>
                </Row>
            </Container>


            <FooterBeparking />

        </>
     );
}
 
export default TerminosbePage;