import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { AiFillEdit, AiOutlineConsoleSql } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
 
import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';
import ciudades from '../../constans/ciudades.json';
import marcas from '../../constans/marcas.json';
import motosMarcas from '../../constans/motosmarcas.json';
import modelos from '../../constans/modelos.json';
import { URL_AWS } from '../../constans/servidor';
import * as ROUTES from '../../constans/rutas';
 
import registro from '../../asses/img/img-registro.png';
import registroEvento from '../../asses/img/pajara_race.png';
import registro2x from '../../asses/img/img-registro@2x.png';
import registro3x from '../../asses/img/img-registro@3x.png';
import tab1 from '../../asses/img/icon-tab1.png';
import tab12x from '../../asses/img/icon-tab1@2x.png';
import tab13x from '../../asses/img/icon-tab1@3x.png';
import tab2 from '../../asses/img/icon-tab2.png';
import tab22x from '../../asses/img/icon-tab2@2x.png';
import tab23x from '../../asses/img/icon-tab2@3x.png';
import tab3 from '../../asses/img/icon-tab3.png';
import tab32x from '../../asses/img/icon-tab3@2x.png';
import tab33x from '../../asses/img/icon-tab3@3x.png';
import imgAlfred from '../../asses/img/alfredApp.png';
import imgDirectivos from '../../asses/img/imgGerentes.png';
import imgACH from '../../asses/img/imgAch.png';
import imgbeWash from '../../asses/img/beWash.png';
 
const RegistrobePage = (props) => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.get('id');
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const [marcaSelect, setMarcaSelect] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [mensaje, setMensaje] = useState();
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [urls, setUrls] = useState(null);
  const [evento, setEvento] = useState();
  const [codigo, setCodigo] = useState();
  const [imagen, setImagen] = useState(registro);
  const [direccion, setDireccion] = useState();
  const [identfication, setidentfication] = useState();
  const [celular, setCelular] = useState();
  const [correo, setCorreo] = useState();
 
  const [countdown, setCountdown] = useState(300); // 5 minutos en segundos
  const [showStartBtn, setShowStartBtn] = useState(true);
 
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
 
  useEffect(() => {
    let intervalId;
 
    // Inicia el temporizador al hacer clic en el botón
    if (!showStartBtn) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
 
    // Detiene el temporizador cuando el contador llega a cero
    if (countdown === 0) {
      clearInterval(intervalId);
      setShowStartBtn(true);
    }
 
    // Limpia el temporizador cuando se desmonta el componente
    return () => clearInterval(intervalId);
  }, [countdown, showStartBtn]);
 
  const handleStart = () => {
    setShowStartBtn(false);
    setCountdown(300);
  };
 
  const formatTime = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };
 
  const handleSelectChangeIdentification = (value) => {
    setidentfication(value.target.value);
  };
  const handleSelectChange = (value) => {
    setCelular(value.target.value);
  };
  const handleSelectChangeC = (value) => {
    setCorreo(value.target.value);
  };
  const envCodigo = async () => {
    try {
      console.log('el celular');
      console.log(celular);
      const datos = {
        celular: celular,
        email: correo,
        cedula: identfication,
      };
      const response = await axios.post(
        // `http://localhost:3000/dev/messageAuthent`,
        // datos
        `${URL_AWS}/beParking/messageAuthent`,
        datos
      );
      if (response.data.status === true) {
        setTab(4);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error('Algo salio mal');
    }
  };
 
  const onSubmit = async (data) => {
    const messageError = 'Algo salio mal con el registro';
 
    const f = data.fechaNacimiento;
 
    const mes = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
 
    const dia = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ];
 
    const fecha = `${f.getFullYear()}-${mes[f.getMonth()]}-${
      dia[f.getDate()]
    } 00:00:00`;
 
    const datos = {
      cedula: data.cedula,
      nombre: data.nombre,
      apellido: data.apellido,
      fechaNacimiento: fecha,
      email: data.email,
      celular: data.celular,
      genero: data.genero,
      direccion: data.direccion,
      password: data.password,
      ciudad: data.ciudad,
      origen: evento,
      nivel: evento === 'Directivos' ? 'BLUE' : 'GREEN',
      vehiculo: [
        {
          cedula: data.cedula,
          marca: data.marca,
          modelo: data.modelo,
          placa: data.placa.toUpperCase(),
          tipoVehiculo: data.tipoVehiculo,
        },
      ],
    };
    const dato = {
      code: data.codigo,
    };
 
    setLoadingData(true);
 
    try {
      const respuesta = await axios.post(
        `${URL_AWS}/beParking/validationCode`,
        dato
      );
      if (respuesta.data.status) {
        toast.success(respuesta.data.message);
        console.log(datos);
        const response = await axios.post(
          //`http://localhost:3000/dev/clienteWEB`,
           `${URL_AWS}/beParking/clienteWEB`,
          datos
        );
 
        if (response.data.estado) {
          if (
            evento === 'Alfred' ||
            evento === 'Directivos' ||
            evento === 'ACH' ||
            evento === 'Be Wash'
          ) {
            const dataBonus = { cedula: data.cedula };
            await axios.post(`${URL_AWS}/beParking/createBonus`, dataBonus);
          }
 
          const dataClient = [];
          const date = new Date();
          const dateFormated = date.toISOString();
          const dateCompleted = dateFormated.replace('T', ' ').slice(0, -5);
 
          const { cedula, celular, email, nombre, apellido, nivel } = data;
 
          const dataClientDE = {
            Cedula: cedula,
            Telefono: `57${celular}`,
            Email: email,
            Nombres: nombre,
            Apellidos: apellido,
            Fecha_Creacion_DE: `${dateCompleted}.000`,
            Fecha_Finalizacion_Registro: `${dateCompleted}.000`,
            Categoria: evento === 'Directivos' ? 'BLUE' : 'GREEN',
            Puntos_Acumulados: 0,
            Puntos_Faltantes_Categoria: 800,
            Volvio_Parking: false,
            Estado_Bono: "S",
            Tipo_Bono: "Bienvenida"
          };
 
          dataClient.push(dataClientDE);
          const responseDE = await axios.post(
            `${URL_AWS}/beParking/insertData`,
            dataClient
          );
 
          console.log("RESPONSE SALESFORCE", responseDE)
          if (responseDE.data.statusCode === 200) {
            if (responseDE.data.responseSalesforce[0].resultMessages.length < 1) {
              toast.success('Felicidades...');
            }
          }
 
          toast.success('Registro exitoso...');
          analytic.logEvent('registrar_usuario', {
            page: `Registro de clientes Beparking`,
          });
 
          setTab(1);
 
          reset({
            apellido: '',
            cedula: '',
            celular: '',
            cpassword: '',
            direccion: '',
            documento: '',
            email: '',
            genero: '',
            marca: '',
            modelo: '',
            nombre: '',
            password: '',
            placa: '',
            tipoVehiculo: '',
          });
        } else {
          toast.error(response.data.mensaje || messageError);
        }
      } else {
        toast.error(respuesta.data.message);
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.mensaje : messageError);
    }
 
    setLoadingData(false);
  };
 
  // console.log(props.queryString);
  useEffect(() => {
    analytic.logEvent('page_view', { page: `Registro de clientes Beparking` });
    if (queryString !== null) {
      if (queryString == 'Combuscol') {
        setEvento(queryString);
        setImagen(registroEvento);
      } else if (queryString == 'Alfred') {
        setEvento(queryString);
        setImagen(imgAlfred);
      } else if (queryString == 'Directivos') {
        setEvento(queryString);
        setImagen(imgDirectivos);
      } else if (queryString == 'ACH') {
        setEvento(queryString);
        setImagen(imgACH);
      }else if (queryString == 'Be Wash') {
        setEvento(queryString);
        setImagen(imgbeWash);
      }
    } else {
      setEvento('APP');
      setImagen(registro);
    }
  }, []);
  TagManager.initialize({
    gtmId: 'GTM-PZ3RKWZ',
  });
 
  TagManager.dataLayer({
    event: 'evento-ejemplo',
    category: 'categoria-ejemplo',
    action: 'accion-ejemplo',
    label: 'etiqueta-ejemplo',
  });
 
  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="pbottom-40">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center ">
            <img
              src={imagen}
              className="img-fluid mtop-30"
              alt="Registro BeParking"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="new-register">
            <p className='new-reg'>Para continuar con tu registro, visita nuestro nuevo sitio: </p>
            <a className="link-be" href='https://www.beparking.com.co/Register'>https://www.beparking.com.co/Register</a>
          </Col>
        </Row>
      </Container>
 
      <FooterBeparking />
    </>
  );
};
 
export default RegistrobePage;