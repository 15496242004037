import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import logo from '../../asses/img/logo.png';
import logo2x from '../../asses/img/logo@2x.png';
import logo3x from '../../asses/img/logo@3x.png';
import iconLocation from '../../asses/img/icon-location.svg';
import iconphone from '../../asses/img/icon-phone.svg';
import iconmail from '../../asses/img/icon-email.svg';
import iconDoc from '../../asses/img/icon-doc.svg';
import facebook from '../../asses/img/facebook.png';
import instagram from '../../asses/img/instagram.png';
import playstore from '../../asses/img/playstore.png';
import appstore from '../../asses/img/appstore.png';
import politica from '../../asses/img/PTEE.jpg';

import * as ROUTES from '../../constans/rutas';

const footerComponent = (props) => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <img
                  src={logo}
                  srcSet={`${logo2x} 2x, ${logo3x} 3x`}
                  alt="Logo parking"
                />
              </Col>
            </Row>
            <Row className="mtop-30">
              <Col xs={12} sm={4} md={4} lg={4} xl={4} className="lineadiv">
                <h4>Ubicación</h4>
                <ul>
                  <li>
                    <img src={iconLocation} alt="icono location" />
                    <p>
                      Cra 14 No. 89 - 48 (Of. 202)
                      <br />
                      Bogotá - Colombia
                    </p>
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} xl={4} className="lineadiv">
                <h4>Contacto</h4>
                <ul>
                  <li>
                    <img src={iconphone} alt="icono location" />
                    <p>
                      PBX
                      <br />
                      <a href="tel:+5715190077">+ 57 (1) 519 00 77</a>
                    </p>
                  </li>
                  <li>
                    <img src={iconphone} alt="icono location" />
                    <p>
                      Servicio al cliente
                      <br />
                      <a href="tel:+573009109787">+ 57 300 910 9787</a>
                    </p>
                  </li>
                  <li>
                    <img src={iconmail} alt="icono location" />
                    <p>
                      <a href="mailto:servicioalcliente@parking.net.co">
                        servicioalcliente@parking.net.co
                      </a>
                    </p>
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                <h4>Legal</h4>
                <ul>
                  <li>
                    <img src={iconDoc} alt="icono location" />
                    <p>
                      <NavLink to={ROUTES.TERMINOS}>
                        Términos y condiciones
                        <br />
                        Políticas de privacidad
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <img src={iconDoc} alt="icono location" />
                    <p>
                      <a href="https://www.sic.gov.co/" target="_blank">
                        Superintendencia de Industria y Comercio
                      </a>
                    </p>
                  </li>
                  <li>
                    <img src={iconDoc} alt="icono location" />
                    <p>
                      <NavLink to={ROUTES.PTEE}>
                        Cumplimiento
                      </NavLink>
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="newsleter d-flex align-items-end flex-column"
          >
            {/* <p className="bold mtop-20 mbottom-5">Suscríbete para nuevas noticias y ofertas especiales.</p>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Escribe tu corre electrónico"
                            />
                            <InputGroup.Append>
                                <Button variant="secondary">Suscribir</Button>
                            </InputGroup.Append>
                        </InputGroup> */}

            <div className="d-flex flex-row align-items-end">
              <div className="descargas d-flex flex-column flex-grow-1">
                <p className="bold text-left">Descarga la app</p>
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.parking.net"
                    target="_blank"
                  >
                    <img src={playstore} alt="play store" />
                  </a>
                  <a
                    href="https://apps.apple.com/co/app/parking-app/id694896866?ign-mpt=uo%3D4"
                    target="_blank"
                  >
                    <img src={appstore} alt="appstore" />
                  </a>
                </div>
              </div>

              <div className="redes">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Parking-110519723977682/"
                >
                  <img src={facebook} alt="facebok parking" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/parking_oficial/?hl=es-la"
                >
                  <img src={instagram} alt="instagram parking" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="dividir-footer"></div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="copi">
              ©2020 Parking - Todos los derechos reservados
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default footerComponent;
