import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { db, stg, fun, analytic } from '../../server/firebase';

import { toast } from 'react-toastify';
import axios from 'axios';
import { URL_AWS } from '../../constans/servidor';

import AuthContex from '../../context/autenticacion/authContex';
import { useForm, Controller, Feedback } from 'react-hook-form';

import factura from '../../asses/img/fctura.png';
import factura1 from '../../asses/img/tusPuntos.png';
import factura2x from '../../asses/img/fctura@2x.png';
import factura3x from '../../asses/img/fctura@3x.png';

const AcumularComponent = ({ openAcomular, onOpenAcomular }) => {
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [showf, setShowf] = useState(false);
  const [identificacion, setIdentificacion] = useState();
  const [motivo, setMotivo] = useState('');
  const [num_factura, setNumFactura] = useState('');
  const [placa, setPlaca] = useState('');
  const [cod_terminal, setCodTerminal] = useState('');
  const [fullscreen, setFullscreen] = useState(false);

  const onSubmitP = async (e, data) => {
    e.preventDefault();
    if (
      identificacion.trim() === '' ||
      num_factura.trim() === '' ||
      cod_terminal.trim() === ''
    ) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      const dto = {
        cod_terminal,
        num_factura,
        placa,
        identificacion: identificacion,
        motivo,
        identificacion_acumulador: identificacion,
      };
      const respuesta = await axios.post(
        `${URL_AWS}/serviciosBeparking/rest/beparking/acumulacionPuntos`,
        dto
      );
      toast.success(respuesta.data.message);
      analytic.logEvent('acumular_puntos', { page: 'Beparking' });
      reset({
        placa: '',
      });
      setCodTerminal('');
      setIdentificacion('');
      setMotivo('');
      setNumFactura('');
      onOpenAcomular(false);
    } catch (error) {
      console.log(error);
      toast.error('Algo salio mal agregando los puntos');
    }
  };
  const regExpCarro = /^[a-z A-Z]{3}[0-9]{3}$/;
  const regExpCarroR = /^[a-z A-Z]{1}[0-9]{4}$/;
  const regExpCarroA = /^[a-z A-Z]{2}[0-9]{4}$/;
  const regExpCarroV = /^[a-z A-Z]{1}[0-9]{5}$/;
  const regExpMoto = /^[a-z A-Z]{3}[0-9]{2}[a-z A-Z]{1}$/;
  const regExpMotoAntigua = /^[a-z A-Z]{3}[0-9]{2}$/;
  const regExpBici = /^BICI[0-9]{2}$/;

  const verificarPlaca = (placa) => {
    console.log(placa);
    if (
      regExpCarro.test(placa) ||
      regExpCarroR.test(placa) ||
      regExpCarroA.test(placa) ||
      regExpCarroV.test(placa) ||
      regExpMoto.test(placa) ||
      regExpMotoAntigua.test(placa) ||
      regExpBici.test(placa)
    ) {
      setPlaca(placa);
    } else {
      setPlaca(false);
    }
  };

  useEffect(() => {
    // history.push(ROUTES.BEPARKING);
    setIdentificacion(usuario?.cedula);
  }, [usuario]);

  return (
    <>
      <Modal
        size="md"
        show={openAcomular}
        onHide={() => onOpenAcomular(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Quiero acumular mis puntos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="conten-login" onSubmit={onSubmitP}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Número de identificación</Form.Label>
              <Form.Control
                type="number"
                value={identificacion}
                disabled
                placeholder="Ingresa tu número de identificación"
                onChange={(e) => setIdentificacion(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="inputplaca">
              <Form.Label>
                Placa<span>Ingrese solo numeros y letras</span>
              </Form.Label>
              <Controller
                name="placa"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'El campo es obligatorio',
                  },
                }}
                defaultValue=""
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Control
                    type="text"
                    onBlur={onBlur}
                    onChange={(value) => {
                      onChange(value);
                      verificarPlaca(value.target.value);
                    }}
                    value={value}
                    maxLength={6}
                  />
                )}
              />
              {errors.placa && (
                <Form.Text id="passwordHelpBlock" className="error">
                  {errors.placa.message}
                </Form.Text>
              )}
              {!placa && (
                <Form.Text id="passwordHelpBlock" className="error">
                  Ingrese una placa valida
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Terminal</Form.Label>
              <Form.Control
                type="text"
                value={cod_terminal}
                placeholder="Ingresa el terminal de tu factura"
                onChange={(e) => setCodTerminal(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Número de factura</Form.Label>
              <Form.Control
                type="text"
                value={num_factura}
                placeholder="Ingresa el número de tu factura"
                onChange={(e) => setNumFactura(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Motivo</Form.Label>
              <Form.Control
                as="select"
                value={motivo}
                onChange={(e) => setMotivo(e.target.value)}
              >
                <option>El operario no pregunto mi cedula</option>
                <option>Falla tecnologia</option>
                <option>Olvide pedir acumulación</option>
              </Form.Control>
            </Form.Group>

            <div className="text-center">
              <Button className="btn-borde-verde" size="sm" type="submit">
                Acumular puntos
              </Button>
            </div>
            <div className="text-center">
              <Button
                onClick={() => {
                  setShowf(true);
                }}
                variant="link"
                className="btn-enlace-azul"
              >
                ¿Necesitas ayuda?
              </Button>
            </div>
            <div className="text-center">
              <Button
                onClick={() => onOpenAcomular(false)}
                variant="link"
                className="btn-enlace-gris"
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showf}
        onHide={() => setShowf(false)}
        dialogClassName="modal-120w"
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>¿Cómo acumular tus puntos?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="contnedor-factura"
            style={{
              position: 'relative',
              maxWidth: '100%',
              maxHeight: '100%',
              overflow: 'hidden',
            }}
          >
            <p>
              Necesitas tener a la mano la factura de tu servicio de parqueo
            </p>
            <img
              className="img_factura"
              src={factura1}
              onClick={() => setFullscreen(!fullscreen)} 
              style={{
                width: '100%',
                height: 'auto',
                cursor: 'pointer',
                transition: 'transform 0.3s ease-in-out',
                transform: fullscreen ? 'scale(1.5)' : 'scale(1)',
              }}
            ></img>
          </div>

          <div className="text-center">
            <Button
              style={{ marginTop: '30px' }}
              onClick={() => setShowf(false)}
              variant="link"
              className="btn-borde-verde"
            >
              Volver
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AcumularComponent;
