import React, { useState, useContext, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import FooterComponen from '../../components/footer';

import Denuncia from './denuncia'
import ConsultarDenuncia from './consultarDenuncia'

const CanalEtica = () => {
  const [denuncia, setDenuncia]   = useState(true);
  const  [consultar, setConsultar]  = useState(false);
 
  return (
    <>
      <Container
        style={{ background: "white", padding: "10px ", borderRadius: "20px", marginBottom: "10px", boxShadow: "box-shadow: 15px 10px 10px black" }}
        className="justify-content-center  mtop-40 mbotton-40 etica"
      >
        <h1 className="text-center mbottom-40">Canal de Ética</h1>
        <p>
          En este canal podrás reportar sospechas de conductas delictivas tales
          como robo, soborno, conflictos de interés, estafa, fraude, acoso
          laboral, entre otros, que atenten contra el Reglamento Interno de
          Parking International S.A.S., el Código Ético y las normas legales
          vigentes.
        </p>
        <p>IMPORTANTE</p>
        <ol>
          <li>
            Para solicitudes que no estén relacionadas con el Código Ético como
            quejas, siniestros, por favor dirígete a los canales habilitados de
            atención al cliente o al empleado.
          </li>
          <li>
            Los informes realizados a través de esta línea serán tratados con la
            máxima confidencialidad y se tomarán las medidas apropiadas para
            abordar cada caso.
          </li>
          <li>
            Es importante destacar que la línea ética está disponible las 24
            horas del día, los 7 días de la semana.
          </li>
        </ol>
      </Container>
      <Container
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {denuncia ?(


          <>

            <button
              className="mtop-20 mbottom-20 botonEtica_uno"
              style={{ width: "400px", marginRight: "20px" }}
              onClick={() => setDenuncia(true)}
            >
              Enviar nueva solicitud 

            </button>
            <button
              className="mtop-20 mbottom-20 botonEtica_dos"
              style={{ width: "400px", backgroundColor: "white !important" }}
              onClick={() => setDenuncia(false)}
            >
              Consultar denuncia existente
            </button>
          </> 
        ):(

          <>

            <button
              className="mtop-20 mbottom-20 botonEtica_dos"
              style={{ width: "400px", marginRight: "20px" }}
              onClick={() => setDenuncia(true)}
            >
              Enviar nueva solicitud

            </button>
            <button
              className="mtop-20 mbottom-20 botonEtica_uno"
              style={{ width: "400px" }}
              onClick={() => setDenuncia(false)}
            >
              Consultar denuncia existente
            </button>
          </>
        )}
        
      </Container>
        <Container>

        {denuncia ? (
          <>
          <Denuncia></Denuncia>
          </>
        ):<>
        <ConsultarDenuncia></ConsultarDenuncia>
        </>
      }
        </Container>
        <FooterComponen />
    </>
  );
};

export default CanalEtica;
