import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CiWarning } from 'react-icons/ci';
import { URL_AWS } from '../../constans/servidor';
import FooterComponen from '../../components/footer';
import { Container, Row, Col, Button } from 'react-bootstrap';
import parkingImage from '../../asses/img/Mesa de trabajo 23.png';

import {
  generatePDF,
  generatePdfMonthly,
  generatePdfMonthlyBici,
} from '../../utils/generatePdfReceipt';

import formatNumber from '../../utils/formatNumber';

const ProofPaymentLandingPage = () => {
  const [invoiceInfo, setInvoiceInfo] = useState(null);
  const [invoiceError, setInvoiceError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [duration, setDuration] = useState('');

  const { dataSend } = useParams();
  const parameter = dataSend.substring(1);

  useEffect(() => {
    axios
      .post(`${URL_AWS}/ElectronicInvoicing/getProofPayment`, { id: parameter })
      // .post(`http://localhost:3000/dev/getProofPayment`, { id: parameter })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setInvoiceInfo(res.data.data);
          calculateDuration(
            res.data.data.fecha_inicial,
            res.data.data.fecha_final
          );
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        } else {
          setInvoiceError(true);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      })
      .catch((err) => {
        console.error(err);
        setInvoiceError(true);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  }, [dataSend]);

  const calculateDuration = (dateStart, dateEnd) => {
    const inicio = new Date(dateStart);
    const fin = new Date(dateEnd);
    const duracion_ms = fin - inicio;

    const duracion_segundos = duracion_ms / 1000;
    const duracion_minutos = duracion_segundos / 60;
    const duracion_horas = duracion_minutos / 60;

    const horus = Math.floor(duracion_horas);
    const minutes = Math.floor(duracion_minutos % 60);
    const seconds = Math.floor(duracion_segundos % 60);

    const durationFormated = `${horus}:${minutes}:${seconds}`;

    setDuration(durationFormated);
  };

  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      {invoiceInfo && !invoiceError ? (
        <>
          <Container id="invoice-container" className="info-invoice">
            <Row
              className="justify-content-center mtop-20 mbottom-40"
              id="contacto"
            >
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '12px',
                  }}
                >
                  <img src={parkingImage} style={{ width: '30%' }} />
                </div>
                <h1 className="title-invoice">Parking International S.A.S</h1>
                <p
                  style={{
                    marginBottom: '7px',
                    textAlign: 'center',
                  }}
                >
                  Nit 860.058.760-1
                </p>
                <p
                  style={{
                    marginBottom: '7px',
                    textAlign: 'center',
                  }}
                >
                  pq.{invoiceInfo.cod_park} -{' '}
                  {invoiceInfo.infoPark?.nombre_comercial}
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '7px',
                  }}
                >
                  {invoiceInfo.infoPark?.direccion}
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '7px',
                  }}
                >
                  Comprobante de Pago {invoiceInfo.cod_terminal}-
                  {invoiceInfo.num_factura}
                </p>
                {/* <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '7px',
                  }}
                >
                  Cufe:{' '}
                  {invoiceInfo.cufe === null ? 'Pendiente' : invoiceInfo.cufe}
                </p> */}
                <div className="content-cointainer">
                  {!invoiceInfo.monthlyData.nombres ||
                  !invoiceInfo.monthlyData.bicicleta_serial ? (
                    <div className="content-styles">
                      <p style={{ marginRight: '30px' }}>Placa: </p>
                      <p>
                        {' '}
                        {invoiceInfo.placa === null ||
                        invoiceInfo.placa === undefined ||
                        invoiceInfo.placa === ''
                          ? 0
                          : invoiceInfo.placa}
                      </p>
                    </div>
                  ) : null}
                  <div className="content-date">
                    {invoiceInfo.tipo_trans === 2 ? (
                      <></>
                    ) : (
                      <p>
                        Entrada:{' '}
                        {invoiceInfo.fecha_inicial
                          .replace('T', ' ')
                          .replace('.000Z', '')}
                      </p>
                    )}
                    <p>
                      Fecha hora expedición:{' '}
                      {invoiceInfo.fecha_final
                        .replace('T', ' ')
                        .replace('.000Z', '')}
                    </p>
                    {invoiceInfo.tipo_trans === 2 &&
                    invoiceInfo.monthlyData.client &&
                    !invoiceInfo.monthlyData.nombres &&
                    !invoiceInfo.monthlyData.bicicleta_serial ? (
                      <p>
                        Cliente: {invoiceInfo.monthlyData.client.nombre}{' '}
                        {invoiceInfo.monthlyData.client.apellido}
                      </p>
                    ) : null}
                  </div>
                  {invoiceInfo.monthlyData.nombres ||
                  invoiceInfo.monthlyData.bicicleta_serial ? (
                    <div className="content-date content-date-start">
                      <p>Mensualidad de bicicleta (30d)</p>
                    </div>
                  ) : null}

                  {invoiceInfo.tipo_trans === 2 ? (
                    <div className="content-date">
                      {invoiceInfo.monthlyData.nombres ||
                      invoiceInfo.monthlyData.bicicleta_serial ? (
                        <>
                          <div className="content-date content-date-start">
                            <p>Vigencia de la compra:</p>
                            <p>
                              Desde {invoiceInfo.monthlyData.fecha_inicio} hasta{' '}
                              {invoiceInfo.monthlyData.fecha_fin}
                            </p>
                          </div>
                          <div className="content-date content-date-start">
                            <p>Vigencia total:</p>
                            <p>
                              Desde {invoiceInfo.monthlyData.fecha_inicio_total}{' '}
                              hasta {invoiceInfo.monthlyData.fecha_fin_total}
                            </p>
                          </div>
                          <div className="content-date content-date-start">
                            <p>Nombre: {invoiceInfo.monthlyData.nombres}</p>
                            <p>
                              Cedula: {invoiceInfo.monthlyData.documento_numero}
                            </p>
                          </div>
                          <div className="content-date content-date-start">
                            <p>Datos de la bicicleta:</p>
                            <p>
                              -Color: {invoiceInfo.monthlyData.bicicleta_color}
                            </p>
                            <p>
                              -Marca: {invoiceInfo.monthlyData.bicicleta_marca}
                            </p>
                            <p>
                              -Serial:{' '}
                              {invoiceInfo.monthlyData.bicicleta_serial}
                            </p>
                          </div>
                        </>
                      ) : (
                        <p>
                          Vigencia hasta:{'  '}{' '}
                          {invoiceInfo.monthlyData.fecha_final}
                        </p>
                      )}
                    </div>
                  ) : (
                    <p>Duración: {duration}</p>
                  )}
                </div>
                {invoiceInfo.tipo_trans === 2 ? (
                  <div className="data-valor total-servicio">
                    {!invoiceInfo.monthlyData.nombres ||
                    !invoiceInfo.monthlyData.bicicleta_serial ? (
                      <>
                        <p>Forma de pago</p>
                        <p>
                          {'  '}
                          {invoiceInfo.formaPago !== ''
                            ? invoiceInfo.formaPago
                            : 'No Aplica'}
                        </p>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}
                <div className="data-valor valor-servicio">
                  {invoiceInfo.tipo_trans === 2 &&
                  !invoiceInfo.monthlyData.nombres ? (
                    <p>Subtotal</p>
                  ) : (
                    <p>Valor Servicio</p>
                  )}
                  <b>$</b>
                  <p>{formatNumber(invoiceInfo.subtotal)}</p>
                </div>
                <div className="data-valor iva-servicio">
                  <p>Iva</p>
                  <b>$</b>
                  <p>{formatNumber(invoiceInfo.iva)}</p>
                </div>
                <div className="data-valor total-servicio">
                  <p>Total</p>
                  <b>$</b>
                  <p>{formatNumber(invoiceInfo.total)}</p>
                </div>
                {invoiceInfo.tipo_trans === 2 &&
                invoiceInfo.monthlyData.nombres ? (
                  <div className="data-valor valor-servicio">
                    <p>Total {invoiceInfo.formaPago.toLowerCase()}</p>
                    <b>$</b>
                    <p>{formatNumber(invoiceInfo.total)}</p>
                  </div>
                ) : null}
                {invoiceInfo.tipo_trans === 2 ? (
                  <p
                    style={{
                      marginBottom: '7px',
                      marginTop: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Importante esta mensualidad aplica solo en el horario
                    establecio a este parqueadero. fuera del horario el tiempo
                    sera liquidado de acuerdo a la tarifa normal.
                    <br />
                    GRACIAS POR CONFIAR EN NUESTRO SERVICIO.
                    <br />
                    <br />
                    <a href="/">www.parking.net.co</a>{' '}
                    <a href="mailto:servicioalcliente@parking.net.co">
                      servicioalcliente@parking.net.co
                    </a>
                  </p>
                ) : (
                  <>
                    <b style={{ display: 'flex', justifyContent: 'center' }}>
                      -----Formas de Pago------
                    </b>
                    <div className="data-valor valor-D-servicio">
                      <p>
                        {' '}
                        {invoiceInfo.valor_datafono !== 0 &&
                        invoiceInfo !== null &&
                        invoiceInfo !== undefined
                          ? 'Valor Datafono:'
                          : 'Valor Efectivo:'}
                      </p>
                      <b>$</b>
                      <p>
                        {invoiceInfo.valor_datafono !== 0 &&
                        invoiceInfo.valor_datafono !== 0 &&
                        invoiceInfo !== null &&
                        invoiceInfo !== undefined
                          ? formatNumber(invoiceInfo.valor_datafono)
                          : formatNumber(invoiceInfo.valor_ef)}
                      </p>
                    </div>
                    <div className="data-valor total-E-servicio">
                      <p>Total Efectivo</p>
                      <b>$</b>
                      <p>
                        {invoiceInfo.valor_ef !== null &&
                        invoiceInfo.valor_ef !== undefined &&
                        invoiceInfo.valor_ef !== 0
                          ? formatNumber(invoiceInfo.valor_ef)
                          : 0.0}
                      </p>
                    </div>
                    {invoiceInfo.formaPago !== 'EFECTIVO' &&
                    invoiceInfo.formaPago !== 'DATAFONO' ? (
                      <div className="data-valor">
                        <p>Beneficio / Descuento:</p>
                        <p>
                          {'  '}
                          {invoiceInfo.formaPago !== ''
                            ? invoiceInfo.formaPago
                            : 'No Aplica'}
                        </p>
                      </div>
                    ) : null}
                    {invoiceInfo.customerInformation !== null ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <p style={{ marginBottom: '0px' }}>
                            -----------------------------------------
                          </p>
                          <b>Be parking</b>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>Estimado(a)</p>
                            <p style={{ marginBottom: '0px' }}>
                              {invoiceInfo.customerInformation.nombre}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>Categoría</p>
                            <p style={{ marginBottom: '0px' }}>
                              {invoiceInfo.customerInformation.categoria}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>
                              Puntos acumulados por la compra
                            </p>
                            <p style={{ marginBottom: '0px' }}>
                              {
                                invoiceInfo.customerInformation
                                  .puntosTransaccion
                              }
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>
                              Puntos acumulados
                            </p>
                            <p style={{ marginBottom: '0px' }}>
                              {invoiceInfo.customerInformation.puntosAcumulados}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{ marginBottom: '0px' }}>
                              Puntos nesesarios para la hora
                            </p>
                            <p style={{ marginBottom: '0px' }}>
                              {
                                invoiceInfo.customerInformation
                                  .puntosFaltantesParaBono
                              }
                            </p>
                          </div>
                          <p style={{ marginTop: '1rem', marginBottom: '0px' }}>Su extracto se vera reflejado al dia siguiente.</p>
                        </div>
                      </>
                    ) : null}
                    <p
                      style={{
                        marginBottom: '7px',
                        marginTop: '15px',
                        textAlign: 'center',
                      }}
                    >
                      Decreto 268/09 - parrafo 2do. la liquidación del valor
                      final del servicio se aproximara al multiplo de cincuenta
                      pesos ($50) siguiente. Este punto de servicio se encuentra
                      con la poliza de responsabilidad Civil No. 2267 de AXA
                      COLPATRIA SEGUROS S.A.
                      <br />
                      GRACIAS POR CONFIAR EN NUESTRO SERVICIO.
                      <br />
                      <br />
                      <a href="/">www.parking.net.co</a>{' '}
                      <a href="mailto:servicioalcliente@parking.net.co">
                        servicioalcliente@parking.net.co
                      </a>
                    </p>
                  </>
                )}
              </Col>
            </Row>
            {showLoading ? (
              <div className="container-sniper"></div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    if (invoiceInfo.tipo_trans === 1) {
                      generatePDF(invoiceInfo, duration, setShowLoading);
                    } else if (
                      invoiceInfo.tipo_trans === 2 &&
                      !invoiceInfo.monthlyData.bicicleta_serial
                    ) {
                      generatePdfMonthly(invoiceInfo, duration, setShowLoading);
                    } else {
                      generatePdfMonthlyBici(invoiceInfo, setShowLoading);
                    }
                  }}
                >
                  Descargar Comprobante
                </Button>
              </div>
            )}
          </Container>
        </>
      ) : (
        <>
          <Container>
            <Row
              className="justify-content-center mtop-40 mbottom-40"
              id="contacto"
            >
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <CiWarning size="7rem" color="red" />
                  <h2>No se pudo encontrar.</h2>
                  <p>
                    Lo sentimos, no pudimos encontrar el comprobante de pago que
                    estás buscando, intentalo mas tarde.
                    <br />
                    Si necesitas ayuda, no dudes en{' '}
                    <a href="#/contacto">contactarnos</a>.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
      <FooterComponen />
    </>
  );
};

export default ProofPaymentLandingPage;
