import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import iconUser from '../../asses/img/papeles.PNG';

import AlertaContext from '../../context/alertas/alertaContext';
import AuthContex from '../../context/autenticacion/authContex';
import * as ROUTES from '../../constans/rutas';

const LoginLegal = (props) => {
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;
  const authContext = useContext(AuthContex);
  const { mensaje, autenticado, iniciarSesion } = authContext;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (autenticado) {
      props.history.push('/politica-PTEE');
    }
    if (mensaje) {
      console.log(mensaje);
      mostrarAlerta(mensaje.msg, 'danger');
    }
  }, [mensaje, autenticado, props.history]);

  const onSubmit = (e) => {
    e.preventDefault();

    // validar que no hayan campos vacios
    if (email.trim() === '' || password.trim() === '') {
      mostrarAlerta('Todos los campos son obligatorios', 'danger');
      return;
    }

    //paswor minimo 6 caracteres
    if (password.length < 6) {
      mostrarAlerta('La contraseña debe tener minimo 6 caracteres', 'danger');
      return;
    }

    iniciarSesion({ email, password });
  };

  return (
    <Container>
      <h3 className="texto-titulo-legal">ACTUALIZAR COMUNICADOS</h3>
      {alerta ? (
        <Alert variant={alerta.categoria} transition={true}>
          {alerta.msg}
        </Alert>
      ) : null}
      <Row className="justify-content-center mtop-40">
        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
          <Jumbotron>
            <div className="form-usuario">
              <h1 className="texto-titulo-legal  mbottom-20">INICIAR SESIÓN</h1>

              <Form onSubmit={onSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    placeholder="Correo Electrónico"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Iniciar Sesión
                </Button>
              </Form>
            </div>
          </Jumbotron>
        </Col>
        <Col
          className="justify-content-center mtop-40"
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          <img className="imageLegal" src={iconUser}></img>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginLegal;
