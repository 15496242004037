import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import PDFViewer from '../../utils/pdfView';

const PTEEID = (props) => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const { id, nombre } = useParams();
  const [item, setItem] = useState();
  const { state } = useLocation();
  const item_prop = props.location.item;
  const f = new Date(item?.creado);
  const mes = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ];
  const fecha = `${mes[f.getMonth()]}, ${f.getDate()} ${f.getFullYear()}`;

  const consultComunicados = () => {
    db.collection('Comunicados')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setItem(doc.data());
        }
      })
      .catch((error) => {
        toast.error('Algo salio mal cargando la noticia...');
      });
  };

  console.log(item)

  useEffect(() => {
    consultComunicados();
    if (props.location.item) {
      setItem(props.location.item);
    } else {
      consultComunicados();
    }
  }, [props]);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Programa de transparencia y ética empresarial` });
  }, []);

  return (
    <>
      {item ? (
        <>
          <Container className="banner_noticias_titulo">
            <Row className="container-Noticias">
              <h3 className="texto-titulo">{item.nombre}</h3>
            </Row>
          </Container>
          <Container className="container-Noticias">
            <PDFViewer imagen={item.imagen} title={item.nombre} />
          </Container>
        </>
      ) : null}
      <FooterComponen />
    </>
  );
};

export default PTEEID;
